import axios from "axios" 
import {TERMS_REQUEST,TERMS_SUCCESS,TERMS_FAIL} from "../constant/privacyPolicyConst"

export const termsAction=()=>async(dispatch)=>{
    try {
        dispatch({type:TERMS_REQUEST})
        const {data}=await axios.get("/app/terms/")
    dispatch({type:TERMS_SUCCESS,payload:data.data.terms})

    } catch (error) {
        dispatch({type:TERMS_FAIL, payload:error})
    }
}
