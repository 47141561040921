import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import footer_logo from "../images/footer_logo.png";
import downArrow from "../images/downArrow.png";
import signup_1 from "../images/signup-1.png";
import signup_2 from "../images/signup-2.png";
import signup_3 from "../images/signup-3.png";
import Loginstudent from "../images/studentlogin.png";
import Clicktocall from "../images/clicktocall.png";
import exploreCoursesIcon from "../images/exploreCoursesIcon.png";
import flag from "../images/flag.png";
import video from "../images/video.png";
import pluse from "../images/pluse.png";
import rightClick from "../images/rightClick.png";
import googlePlay from "../images/googlePlay.png";
import welcomeImg from "../images/welcome-modal-img.jpeg";
import "../style/navbar.css";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { catagoryAction } from "../Action/catagoryAction";
import "../style/navbar.css";
import { logInAction } from "../Action/loginAction";
import { verifyOTPAction } from "../Action/verifyOTP";
import { createSignUpAction } from "../Action/createSignUpAction";
import { SignUpVerifyAction } from "../Action/signUpVerifyOtp";
import { getTokenAction } from "../Action/getTokenAction";
//import { personalInfoAction } from "../Action/personalInfoAction";
import { resendOtpAction } from "../Action/resendOtpAction";
import { Modal } from "react-bootstrap";
import FlagSelect from "./FlagSelect";
import { personalInfoAction } from "../Action/personalInfoAction";
import { storage } from "../Firebase/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
// material ui
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { ExploreCoursesAction } from "../Action/exploreCoursesAction";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import CoupenCodeNavbar from "./CoupenCodeNavbar";
// lazy loading
import { LazyLoadImage } from "react-lazy-load-image-component";
import NavAllCourses from "./navAllCourses/NavAllCourses";
import NavAllCourses1 from "./navAllCourses/NavAllCourses1";
// import SkillIndia from "../images/skill-India.png";
// import SkillIndiaColor from "../images/skill-India2.png";

export default function Navbar() {
  // console.log("userType");
  const [displayUser, setdisplayUser] = useState(true);
  const dispatch = useDispatch();
  const [catagoryBg, setcatagoryBg] = useState(false);
  const [studentBg, setstudentBg] = useState();
  const [educatorBg, seteducatorBg] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [otp, setOtp] = useState();
  const [userName, setUserName] = useState();
  const [EmailId, setEmailId] = useState();
  const [DateOfBirth, setDateOfBirth] = useState();
  // const [open, setopen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [whatsAppCheckBox, setWhatsAppCheckBox] = useState(true);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [interestSelect, setInterestSelect] = useState([]);
  const [countryCode, setCountryCode] = useState("+91");
  const [flagImg, setflagImg] = useState();
  const [professionSelect, setProfessionSelect] = useState("S");
  const { category } = useSelector((state) => state.catagories);
  const { OTPVerification } = useSelector((state) => state.verifyOtp);
  const { signUp } = useSelector((state) => state.signUp);
  const { Login } = useSelector((state) => state.userLogin);
  const { signUpVerify } = useSelector((state) => state.signUpVerify);
  const { token } = useSelector((state) => state.token);
  const { newOtp } = useSelector((state) => state.NewOtp);

  //Modal

  const [showLogin, setShowLogin] = useState(false);
  const [showLoginOtp, setShowLoginOtp] = useState(false);
  const [showLogInVerification, setshowLogInVerification] = useState(false);
  const [showSignUp, setshowSignUp] = useState(false);
  const [showSignUpVerification, setshowSignUpVerification] = useState(false);
  const [showPersonalInfo, setshowPersonalInfo] = useState(false);
  const [showSelectInterest, setshowSelectInterest] = useState(false);
  const [showWelcome, setshowWelcome] = useState(false);
  const [localData, setlocalData] = useState({});
  const [isSignUp, setisSignUp] = useState();
  const [educatorPopUp, seteducatorPopUp] = useState(false);

  //file upload
  const [profileImage, setProfileImage] = useState("");
  const [url, setUrl] = useState("");
  const [progress, setProgress] = useState(0);

  //resend opt
  const [resendOTP, setresendOTP] = React.useState(false);

  useEffect(() => {
    setErrorMessage("");
  }, [
    showLogin,
    showLoginOtp,
    showSignUp,
    showSignUpVerification,
    showPersonalInfo,
    showSelectInterest,
    showWelcome,
  ]);
  useEffect(() => {
    if (token?.access) {
      localStorage.setItem("UserData", JSON.stringify(token));
    }
  }, [token]);

  useEffect(() => {
    if (localStorage.getItem("UserData")) {
      setlocalData(JSON.parse(localStorage.getItem("UserData")));
    }
  }, []);

  // logout logic

  useEffect(() => {
    if (localStorage.getItem("UserData")) {
      setdisplayUser(false);
    } else {
      setdisplayUser(true);
    }
  }, [token]);

  const handleLogOut = () => {
    localStorage.removeItem("UserData");
    setdisplayUser(true);
  };

  const handleCategory = (interest) => {
    let tempInterestList = JSON.parse(JSON.stringify(interestSelect));
    if (tempInterestList.indexOf(interest) > -1) {
      tempInterestList.splice(tempInterestList.indexOf(interest), 1);
    } else {
      tempInterestList.push(interest);
    }
    setInterestSelect(tempInterestList);
  };

  const loginUser = () => {
    setdisplayUser(false);
    window.location.href = window.location.origin + "/allCourses";
    setshowWelcome(false);
  };
  const selsectEduBg = () => {
    setstudentBg("");
    seteducatorBg("#F2FCFF");
  };
  const selsectStuBg = () => {
    setstudentBg("#F2FCFF");
    seteducatorBg("");
  };

  const clearVariableData = () => {
    setMobileNumber("");
    setOtp("");
    setUserName("");
    setErrorMessage("");
    setProfileImage("");
    setUrl("");
  };

  const handleLoginOnChange = (moNumer) => {
    setMobileNumber(moNumer);
  };
  const [userType, setuserType] = useState("Educator");
  const handleSendOTP = () => {
    userType == "Student"
      ? dispatch(
          logInAction({
            username: countryCode + mobileNumber,
            password: "4654654",
          })
        )
      : dispatch(
          logInAction({
            username: countryCode + mobileNumber,
            password: "4654654",
          })
        );
  };

  // const handleLoginEducator = () => {};

  useEffect(() => {
    if (OTPVerification) {
      if (OTPVerification.data?.result?.is_otp_verified) {
        dispatch(
          getTokenAction({
            username: countryCode + mobileNumber,
            password: otp,
          })
        );
        setShowLoginOtp(false);
        setShowLogin(false);
      } else {
        setErrorMessage(OTPVerification.data?.user_message);
      }
    }
  }, [OTPVerification]);

  const handleVerifyOtp = () => {
    dispatch(
      verifyOTPAction({
        username: countryCode + mobileNumber,
        password: otp,
        is_signup: false,
      })
    );
  };
  const handleSignUpOtp = () => {
    dispatch(
      createSignUpAction({
        first_name: userName,
        username: countryCode + mobileNumber,
        password: "ksdjfs",
        user_type: userType == "Student" ? "S" : "E",
        is_whatsapp_notifications: whatsAppCheckBox,
      })
    );
  };

  useEffect(() => {
    if (signUp?.data?.result?.is_otp_sent_successfully) {
      setshowSignUpVerification(true);
      setshowSignUp(false);
    } else {
      setErrorMessage(signUp?.data?.user_message);
    }
  }, [signUp]);

  useEffect(() => {
    if (Login?.data?.result?.is_otp_sent_successfully) {
      setShowLoginOtp(true);
      setShowLogin(false);
    } else {
      setErrorMessage(Login?.data?.user_message);
    }
  }, [Login]);

  const redirectToEducatorProfile = (id) => {
    window.location.href = window.location.origin + "/educatorDashboardEditProfile";
  };

  useEffect(() => {
    if (userType == "Student") {
      if (signUpVerify?.data?.result?.is_otp_verified) {
        dispatch(
          getTokenAction({
            username: countryCode + mobileNumber,
            password: otp,
          })
        );
        setshowSignUpVerification(false);
        setshowPersonalInfo(false);
      } else {
        setErrorMessage(signUpVerify?.data?.user_message);
      }
    } else {
      if (signUpVerify?.data?.result?.is_otp_verified) {
        dispatch(
          getTokenAction({
            username: countryCode + mobileNumber,
            password: otp,
          })
        );
        setshowSignUpVerification(false);
        seteducatorPopUp(true);
        // redirectToEducatorProfile()
      } else {
        setErrorMessage(signUpVerify?.data?.user_message);
      }
    }
  }, [signUpVerify]);

  const handleSignUpVerifyOtp = () => {
    dispatch(
      SignUpVerifyAction({
        username: countryCode + mobileNumber,
        password: otp,
        is_signup: true,
      })
    );
  };

  const handlePersonalInfo = () => {
    setshowSelectInterest(true);
    setshowPersonalInfo(false);
    setAcceptTerms(false);
  };

  const handleSelectInterestBack = () => {
    setshowSelectInterest(false);
    setshowPersonalInfo(true);
  };
  const handleConfirmInterest = () => {
    dispatch(
      personalInfoAction(
        {
          user_info: {
            first_name: userName,
            date_of_birth: DateOfBirth,
            user_type: "s",
            id: "",
          },
          profile_info: {
            enroled_courses: 0,
            profile_pic: profileImage,
            work_status: professionSelect,
            interests: interestSelect,
          },
        },
        token
      )
    );
    setshowSelectInterest(false);
    setshowWelcome(true);
  };

  useEffect(() => {
    showLoginOtp && setisSignUp(false);
    showSignUpVerification && setisSignUp(true);
  }, [showLoginOtp, showSignUpVerification]);

  const handelEnterKeyPressLogIn = (e) => {
    if (e.key == "Enter") {
      handleSendOTP();
    }
  };

  const handleEducatorClick = () => {
    seteducatorPopUp(true);
    setshowSignUp(false);
  };

  const handleStudentClick = () => {
    seteducatorPopUp(false);
    setshowSignUp(true);
  };

  //file Upload
  const handleUpload = (e) => {
    if (e.target.files[0]) {
      var profileImage = e.target.files[0];
      const storageRef = ref(storage, `/u/s/${localData.id}/pp`);
      const uploadTask = uploadBytesResumable(storageRef, profileImage);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setUrl(downloadURL);
            setProfileImage(`/u/s/${localData.id}/pp`);
          });
        }
      );
    }
  };

  // resend otp successfully
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal, open } = state;

  const handleResendOtp = () => {
    dispatch(
      resendOtpAction({
        username: countryCode + mobileNumber,
        password: "4654654",
        is_signup: isSignUp,
      })
    );
    setState({ ...state, open: true });
  };

  useEffect(() => {
    setTimeout(() => {
      setState({ ...state, open: false });
    }, 10000);
  }, [state]);

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  // explore courses
  useEffect(() => {
    dispatch(ExploreCoursesAction());
  }, []);
  const { exploreCourses } = useSelector((state) => state.exploreCatagory);
  // redirect to all courses
  const redirectToCourseDetails = (id, title) => {
    // window.open("/courseDetail?course_info=" + id, "_blank");
    window.open(`/live?${title.replaceAll(" ", "-")}=` + id, "_blank");
  };
  //window size
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  // search
  const [SearchCourse, setSearchCourse] = useState("");
  const history = useHistory();

  const redirectToAllCoursePage = () => {
    // window.location.href = window.location.origin + "/allCourses?searchKey=" + SearchCourse;
    history.push("/allCourses?searchKey=" + SearchCourse);
  };
  const handleKeyDownSearch = (e) => {
    if (e.key == "Enter") {
      redirectToAllCoursePage();
    }
  };
  const searchCourse = () => {
    redirectToAllCoursePage();
  };
  ////
  const handleStuDash = () => {
    history.push("/studentDashboard?isDashboard=true");
  };
  const [Navbar, setNavbar] = useState();
  ////
  const search = useLocation().search;
  useEffect(() => {
    const isDashboard = new URLSearchParams(search).get("isDashboard");
    setNavbar(isDashboard);
  }, [search]);
  // click to call logic
  const handleCallClick = () => {
    const myno = "+91 7378757330";
    const telLink = `tel:${myno}`;
    console.log("calling...");
    window.location.href = `tel:${telLink}`;
  };

  return (
    <div className={Navbar ? "d-none" : "d-block"}>
      <div className="navbar-wrapper">
        <nav
          className="navbar navbar-expand-lg d-flex text-dark
         navbar-dark"
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              <LazyLoadImage src={footer_logo} className="nav_logo" alt="footer_logo" />
              {/* <p className="initiative">IIT Mumbai Alumni Initiative</p> */}
            </Link>
            {/* <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button> */}
            <div className="collapse exploreDropdwn navbar-collapse" id="navbarScroll">
              {/* dropdown  */}
              <li className="nav-item dropdown">
                <button
                  id="dropdownMenuButton1"
                  // href="#"
                  className="exploreCourseBtn px-2 py-1 btn nav-link mt-4"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src={exploreCoursesIcon}
                    alt="exploreCoursesIcon"
                    className="dot"
                    width={15}
                  />
                  All Courses
                </button>

                <ul className="dropdown-menu bg-dark" aria-labelledby="dropdownMenuButton1">
                  {exploreCourses?.data &&
                    exploreCourses?.data.map((tech, i) => (
                      <>
                        <li className="dropdown-submenu bg-dark" key={i}>
                          <a className="dropdown-item dropdown-toggle bg-dark" href="#">
                            {tech?.category}
                          </a>
                          <ul className="dropdown-menu dropdown-menu2 bg-dark">
                            {tech?.courses.map((course, i) => (
                              <li
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                key={i}
                                onClick={() => redirectToCourseDetails(course.id, course.title)}
                              >
                                {width < 545 ? (
                                  <a className="dropdown-item bg-dark" href="#">
                                    {course?.title.slice(0, 20)}...
                                  </a>
                                ) : (
                                  <a className="dropdown-item bg-dark" href="#">
                                    {course?.title}
                                  </a>
                                )}
                              </li>
                            ))}
                          </ul>
                        </li>
                      </>
                    ))}
                </ul>
              </li>

              <div className="collapse navbar navbar-collapse" id="navbarScroll">
                <ul className="navbar-nav ms-auto  d-flex align-items-center justify-content-center text-align-center  my-lg-0">
                  <li
                    className="d-none nav-item search-nav-item me-4 mb-0"
                    data-bs-toggle="collapse"
                    // data-bs-target=" navbar-collapse.show"
                  >
                    <Paper className="nav-paper">
                      <InputBase
                        sx={{ ml: 2, flex: 1 }}
                        inputProps={{ "aria-label": "search google maps" }}
                        value={SearchCourse}
                        placeholder={"Search Courses"}
                        onChange={(e) => setSearchCourse(e.target.value)}
                        onKeyPress={handleKeyDownSearch}
                      />
                      {/* {SearchCourse && ( */}
                      <IconButton
                        type="button"
                        className="searchIcon-btn"
                        onClick={redirectToAllCoursePage}
                      >
                        <ClearIcon
                          onClick={() => setSearchCourse("")}
                          sx={{
                            width: "15px",
                          }}
                        />
                      </IconButton>
                      {/* )} */}
                      <IconButton
                        type="button"
                        className="searchIcon-btn"
                        aria-label="search"
                        onClick={searchCourse}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </li>

                  {displayUser ? (
                    ""
                  ) : (
                    <li
                      className="nav-item dropdown"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      {/* <button className=" login-btn dashboard me-2" onClick={handleStuDash}>
                          My Dashboard
                        </button> */}
                      <a
                        href="https://coachxsession.web.app/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button className=" login-btn dashboard me-2">My Dashboard</button>
                      </a>
                    </li>
                  )}

                  {/* <li>
                      <a href="https://wa.me/message/YIUPH6AA6FGUF1" target="blank" className="">
                        <button
                          className=" login-btn d-none d-lg-block ms-2"
                        >
                          <i className="me-1 bi bi-whatsapp"></i> whatsApp Us
                        </button>
                      </a>
                    </li> 
                    <li>
                     <a href="https://tawk.to/chat/630c875337898912e965ea57/1gbkf18jg" 
                     target="blank" 
                    >
                      <button
                        className="login-btn d-none d-lg-block nav-item ms-2 mt-sm-0 "
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                      >
                       <i className="me-1 bi bi-chat-right-text"></i> Chat Us
                      </button>
                      </a>
                    </li> 
                    <li>
                      <button
                        className=" login-btn d-none d-lg-block nav-item ms-2 mt-sm-0"
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                      >
                       <i className="me-1 bi bi-telephone"></i> Call us
                      </button>
                  </li>  */}
                  {/* <li className="nav-right-items">
                    <div className="login-btn">
                      <a href="{`tel:7378757330`}>">
                        <img src={Clicktocall} width={130} />
                      </a>
                    </div>
                  </li> */}

                  <li className="nav-right-items me-4">
                    <a href={`tel:+91 7378757330`}>
                      <button
                        className="exploreCourseBtn shine-effect login-btn d-none d-lg-block nav-item  mt-sm-0"
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                        onClick={handleCallClick}
                      >
                        <img
                          src={Clicktocall}
                          width={25}
                          className="student-login me-1 animate__animated animate__pulse animate__infinite	infinite"
                        />
                        Click To Call
                      </button>
                    </a>
                  </li>

                  <li className="nav-right-items me-4">
                    <a href="https://cxadmin.coachx.live/" target="blank">
                      <button
                        className="exploreCourseBtn login-btn d-none d-lg-block nav-item ms-2 mt-sm-0"
                        data-bs-toggle="collapse"
                        data-bs-target=".navbar-collapse.show"
                      >
                        <img src={Loginstudent} width={15} className="student-login me-1" />
                        Student Login
                      </button>
                    </a>
                  </li>

                  {/* <li className="nav-right-items">
                    <img src={SkillIndia} width={50} />
                  </li> */}
                  {/* <li className="nav-right-items">
                    <img src={SkillIndiaColor} width={50} />
                  </li> */}

                  {/* <li
                    className="nav-item dropdown"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    {displayUser ? (
                      <button
                        className=" login-btn me-4"
                        id="login-modal"
                        onClick={() => {
                          clearVariableData();
                          setShowLogin(true);
                        }}
                      >
                        Log in
                      </button>
                    ) : (
                      <div>
                        <button
                          className=" login-btn mb-1"
                          id="login-modal"
                          onClick={handleLogOut}
                        >
                          Logout
                        </button>
                      </div>
                      // <a
                      //   href="https://coachxsession.web.app/sessionList"
                      //   target="_blank"
                      //   rel="noopener noreferrer"
                      // >
                      //   <div className="d-flex align-items-center">
                      //     <img src={video} className="profileImg" alt="" />
                      //     <div className="d-flex flex-column text-light ms-2">
                      //       <span>Rahul Sharma</span>
                      //     </div>
                      //   </div>
                      // </a>
                    // )}
                  </li> */}
                </ul>
              </div>
            </div>

            {/* ======= All Courses on mobile ======= */}
            <span className="AllCoursesOnMobile">
              <NavAllCourses />
            </span>

            {/* dropdown  */}

            {/* <li className="nav-item dropdown AllCoursesOnMobile">
                <button
                  id="dropdownMenuButton1"
                  // href="#"
                  className="exploreCourseBtn px-2 py-1 btn nav-link mt-4"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src={exploreCoursesIcon}
                    alt="exploreCoursesIcon"
                    className="dot"
                    width={15}
                  />
                  All Courses
                </button>
                
                <ul
                  className="dropdown-menu bg-dark"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {exploreCourses?.data &&
                    exploreCourses?.data.map((tech, i) => (
                      <>
                        <li className="dropdown-submenu bg-dark" key={i}>
                          <a
                            className="dropdown-item dropdown-toggle bg-dark"
                            href="#"
                          >
                            {tech?.category}
                          </a>
                          <ul className="dropdown-menu dropdown-menu2 bg-dark">
                            {tech?.courses.map((course, i) => (
                              <li
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                                key={i}
                                onClick={() =>
                                  redirectToCourseDetails(
                                    course.id,
                                    course.title
                                  )
                                }
                              >
                                {width < 545 ? (
                                  <a className="dropdown-item bg-dark" href="#">
                                    {course?.title.slice(0, 20)}...
                                  </a>
                                ) : (
                                  <a className="dropdown-item bg-dark" href="#">
                                    {course?.title}
                                  </a>
                                )}
                              </li>
                            ))}
                          </ul>
                        </li>
                      </>
                    ))}
                </ul>

              </li> */}
          </div>
        </nav>
      </div>

      <Modal show={showLogin} onHide={() => setShowLogin(false)} backdrop="static">
        <button
          type="button"
          className="close-btn ms-auto mt-2 me-2"
          onClick={() => setShowLogin(false)}
          aria-label="Close"
        ></button>
        <Modal.Body closeButton>
          <h3 className="login-modal-heading">Log In</h3>

          <div className="row">
            <div className="col-md-6">
              <button
                className={userType === "Educator" ? "educator selectedUser" : "educator"}
                data-bs-toggle="modal"
                data-bs-target="#educatorModal"
                onClick={() => setuserType("Educator")}
              >
                <LazyLoadImage src={signup_1} alt="signup_1" /> <span>Educator</span>{" "}
              </button>
            </div>
            <div className="col-md-6">
              <button
                className={userType === "Student" ? "student selectedUser" : "student"}
                onClick={() => setuserType("Student")}
              >
                <LazyLoadImage src={signup_3} alt="signup_3" /> <span>Student</span>
              </button>
            </div>

            <div className="col-12">
              <div>
                <label htmlFor="">Mobile Number</label>
                <div className="mobile-input">
                  <FlagSelect
                    setCountryCode={setCountryCode}
                    countryCode={countryCode}
                    setflagImg={setflagImg}
                    flagImg={flagImg}
                  />

                  <input
                    type="number"
                    className="form-control form-control2 w-100"
                    placeholder="Mobile Number"
                    maxLength="10"
                    value={mobileNumber}
                    onChange={(e) => handleLoginOnChange(e.target.value)}
                    required
                  />
                </div>
                <span className="text-danger">{errorMessage} </span>
              </div>
            </div>
            <div className="col-12">
              <button
                className="login-otp-btn"
                data-bs-toggle="modal"
                data-bs-target="#loginWithOtpModal"
                type="button"
                onClick={handleSendOTP}
                // show={showLogin} onHide={() =>setShowLogin(false)}
              >
                Send OTP
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <LazyLoadImage src={signup_2} width={20} height={20} alt="signup_2" />
              <span className="ms-1"> New User?</span>
              <button
                className="form-login-btn"
                data-bs-toggle="modal"
                data-bs-target="#signupModal"
                onClick={() => {
                  clearVariableData();
                  setShowLoginOtp(false);
                  setShowLogin(false);
                  setshowSignUp(true);
                }}
                type="button"
              >
                Create Account
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* resent otp*/}

      <div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={handleClose}
          message="OTP send successfully !!!"
          key={vertical + horizontal}
        />
      </div>
      {/* verify logIn OTP */}
      <Modal show={showLoginOtp} backdrop="static">
        <button
          type="button"
          className="close-btn ms-auto mt-2 me-2"
          onClick={() => setShowLoginOtp(false)}
          aria-label="Close"
        ></button>
        <Modal.Body closeButton>
          <h3 className="login-modal-heading">Log In</h3>

          <div className="row">
            <div className="col-12">
              <div>
                <label htmlFor="">Mobile Number</label>
                <div className="mobile-input">
                  <LazyLoadImage src={flagImg} width={30} height={30} alt="flag" />
                  <input
                    type="number"
                    max={10}
                    className="form-control form-control2 w-100"
                    placeholder="Mobile Number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    disabled
                  />
                </div>
                <div className="d-flex">
                  <span
                    onClick={() => {
                      handleResendOtp({
                        vertical: "top",
                        horizontal: "center",
                      });
                    }}
                    className="sendOtp mt-2 ms-auto"
                  >
                    Resend OTP
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12">
              <div>
                <label htmlFor="">Enter Otp</label>
                <input
                  type="text"
                  className="form-control form-control1"
                  placeholder="Enter OTP"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
              </div>
              <span className="text-danger">{errorMessage} </span>
            </div>
            <div className="col-12">
              <button
                className="login-otp-btn"
                data-bs-dismiss="modal"
                type="button"
                onClick={handleVerifyOtp}
              >
                Log In
              </button>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <LazyLoadImage src={signup_2} width={20} height={20} alt="signup_2" />
              <span className="ms-1"> New User?</span>
              <button
                className="form-login-btn mt-3"
                data-bs-toggle="modal"
                data-bs-target="#signupModal"
                type="button"
                show={showSignUp}
                onClick={() => {
                  clearVariableData();
                  setShowLoginOtp(false);
                  setShowLogin(false);
                  setshowSignUp(true);
                }}
              >
                Create Account
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/*   create signup modal  */}
      <Modal show={showSignUp} backdrop="static" size="lg">
        <button
          type="button"
          className="close-btn ms-auto mt-2 me-2"
          aria-label="Close"
          onClick={() => setshowSignUp(false)}
        ></button>
        <Modal.Body closeButton>
          <h3 className="login-modal-heading">Create Account</h3>
          <p>Choose your profile</p>
          <div className="row">
            <div className="col-md-6">
              <button
                className={userType === "Educator" ? "educator selectedUser" : "educator"}
                data-bs-toggle="modal"
                data-bs-target="#educatorModal"
                onClick={() => setuserType("Educator")}
                // onClick={handleEducatorClick}
              >
                <LazyLoadImage src={signup_1} alt="signup_1" /> <span>Educator</span>{" "}
              </button>
            </div>
            <div className="col-md-6">
              <button
                className={userType === "Student" ? "student selectedUser" : "student"}
                onClick={() => setuserType("Student")}
              >
                <LazyLoadImage src={signup_3} alt="signup_3" /> <span>Student</span>
              </button>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div>
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    className="form-control form-control1"
                    placeholder="Enter Name"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    required
                  />
                  <div className="form-check d-flex">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexCheckDefault"
                      checked={whatsAppCheckBox}
                      onChange={() => setWhatsAppCheckBox(!whatsAppCheckBox)}
                    />
                    <label
                      className="form-check-label wp-notification mt-1 ms-1"
                      for="flexCheckDefault"
                    >
                      Receive notification via Whatsapp
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <label htmlFor="">Mobile Number</label>
                  <div className="mobile-input">
                    <FlagSelect
                      setCountryCode={setCountryCode}
                      countryCode={countryCode}
                      setflagImg={setflagImg}
                      flagImg={flagImg}
                    />
                    <input
                      type="number"
                      max={10}
                      className="form-control form-control2"
                      placeholder="Mobile Number"
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                      required
                    />
                  </div>
                  <span className="text-danger">{errorMessage} </span>
                </div>
              </div>
              <div className="col-12">
                <button
                  className="otp-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#verifyOtpModal"
                  type="button"
                  onClick={handleSignUpOtp}
                >
                  Send OTP
                </button>
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <span className="ms-1"> Already have a account?</span>
                <button
                  className="form-login-btn"
                  type="button"
                  onClick={() => {
                    clearVariableData();
                    setshowSignUp(false);
                    setShowLogin(true);
                  }}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* verify signup otp modal */}
      <Modal show={showSignUpVerification} backdrop="static" size="lg">
        <button
          type="button"
          className="close-btn ms-auto mt-2 me-2"
          aria-label="Close"
          onClick={() => setshowSignUpVerification(false)}
        ></button>
        <Modal.Body closeButton>
          <h3 className="login-modal-heading">Create Account</h3>
          <p>Choose your profile</p>
          <div className="row">
            <div className="col-md-6">
              <button className="educator">
                <LazyLoadImage src={signup_1} alt="signup_1" /> <span>Educator</span>
              </button>
            </div>
            <div className="col-md-6">
              <button className="student" style={{ background: "#F2FCFF" }}>
                <LazyLoadImage src={signup_3} alt="signup_3" /> <span>Student</span>
              </button>
            </div>

            <div className="row">
              <div className="col-12">
                <div>
                  <label htmlFor="">Enter Name</label>
                  <input
                    type="text"
                    className="form-control form-control3"
                    placeholder="Enter Name"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div>
                  <label htmlFor="">Mobile Number</label>
                  <div className="mobile-input">
                    <LazyLoadImage src={flagImg} width={30} height={30} alt="flag" />
                    <input
                      type="number"
                      max={10}
                      className="form-control form-control2"
                      placeholder="Mobile Number"
                      value={mobileNumber}
                      onChange={(e) => setMobileNumber(e.target.value)}
                      disabled
                    />
                  </div>
                </div>
                <div className="d-flex">
                  <span className="sendOtp mt-2 ms-auto" onClick={handleResendOtp}>
                    Resend OTP
                  </span>
                </div>
              </div>
              <div className="col-md-6">
                <div>
                  <label htmlFor="">Enter Otp</label>
                  <input
                    type="text"
                    className="form-control form-control1"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    required
                  />
                </div>
                <span className="text-danger">{errorMessage} </span>
              </div>
              <div className="col-12">
                <button className="otp-btn" type="button" onClick={handleSignUpVerifyOtp}>
                  Verify
                </button>
              </div>
              <div className="col-12 d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                  <LazyLoadImage src={signup_2} width={20} height={20} alt="signup_2" />
                  <span className="ms-1"> Already have a account?</span>
                </div>
                <button
                  className="form-login-btn"
                  onClick={() => {
                    clearVariableData();
                    setshowSignUpVerification(false);
                    setShowLogin(true);
                  }}
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* enter personal info   */}
      <Modal show={showPersonalInfo} backdrop="static" size="lg">
        <button
          type="button"
          className="close-btn ms-auto mt-2 me-2"
          aria-label="Close"
          onClick={() => {
            setshowPersonalInfo(false);
          }}
        ></button>
        <Modal.Body closeButton>
          <div className="row">
            <div className="col-12">
              <div className="verification-succesfull">
                <LazyLoadImage src={rightClick} width={20} alt="rightClick" />
                <span className="ms-1 verification-successfull-para">
                  Mobile number Verified Successfully !
                </span>
              </div>
            </div>
            <div className="status-bars">
              <div className="active"></div>
              <div className="non-active"></div>
              <div className="non-active"></div>
              <div></div>
            </div>
          </div>
          <h3 className="login-modal-heading">Enter Personal info</h3>
          <div className="profile">
            <LazyLoadImage
              src={url || "http://via.placeholder.com/300"}
              className="profile-img"
              alt="no img"
            />
            <div className="input-group mb-3">
              <input
                type="file"
                className="d-none"
                id="profilePic"
                onChange={(e) => handleUpload(e)}
              />
              <label className="input-group-text pluse" for="profilePic">
                <LazyLoadImage src={pluse} alt="pluse" className="pluseImg rounded-circle" />
              </label>
            </div>
            {/* <progress value={progress} max="100" />
             <div>{url}</div> */}
          </div>
          <div className="row">
            <div className="row">
              <div className="col-md-6 form-inner-wrpper">
                <div>
                  <label htmlFor="">Name</label>
                  <input
                    type="text"
                    className="form-control form-control1"
                    value={userName}
                    placeholder="Enter Name"
                    onChange={(e) => setUserName(e.target.value)}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6 form-inner-wrpper">
                <div>
                  <label htmlFor="">Email Id</label>
                  <input
                    type="email"
                    className="form-control form-control1"
                    placeholder="Enter Email Id"
                    onChange={(e) => setEmailId(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6 form-inner-wrpper">
                <div>
                  <label htmlFor="">Mobile Number</label>
                  <div className="mobile-input">
                    <LazyLoadImage src={flag} width={30} height={30} alt="flag" />
                    <input
                      type="number"
                      value={mobileNumber}
                      className="form-control form-control2"
                      placeholder="Mobile Number"
                      onChange={(e) => setMobileNumber(e.target.value)}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 form-inner-wrpper">
                <div>
                  <label htmlFor="">Date of birth</label>
                  <input
                    type="date"
                    className="form-control form-control1"
                    placeholder="Enter Email Id"
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    required
                  />
                </div>
              </div>
              <span className="mb-1">What's your profession?</span>
              <div className=" profession-wrapper d-flex justify-content-between text-center">
                <div
                  className={
                    professionSelect === "S"
                      ? "profession-boxes-select profession-boxes"
                      : "profession-boxes"
                  }
                  onClick={() => setProfessionSelect("S")}
                >
                  Student
                </div>
                <div
                  className={
                    professionSelect === "W"
                      ? "profession-boxes-select profession-boxes"
                      : "profession-boxes"
                  }
                  onClick={() => setProfessionSelect("W")}
                >
                  Working Professional
                </div>
                <div
                  className={
                    professionSelect === "H"
                      ? "profession-boxes-select profession-boxes"
                      : "profession-boxes"
                  }
                  onClick={() => setProfessionSelect("H")}
                >
                  Home Maker
                </div>
                <div
                  className={
                    professionSelect === "U"
                      ? "profession-boxes-select profession-boxes"
                      : "profession-boxes"
                  }
                  onClick={() => setProfessionSelect("U")}
                >
                  Graduate
                </div>
              </div>
              <div className="col-12">
                <button
                  className="next"
                  data-bs-toggle="modal"
                  data-bs-target="#selectInterest"
                  type="button"
                  onClick={handlePersonalInfo}
                >
                  Next
                </button>
                <button
                  className="back"
                  data-bs-toggle="modal"
                  data-bs-target="#verifyOtpModal"
                  type="button"
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* select your interests modal */}
      <Modal show={showSelectInterest} backdrop="static">
        <button
          type="button"
          className="close-btn ms-auto mt-2 me-2"
          aria-label="Close"
          onClick={() => {
            setshowSelectInterest(false);
          }}
        ></button>
        <Modal.Body closeButton>
          <div className="modal-body">
            <div className="interest-status-bars">
              <div className="interest-active"></div>
              <div className="interest-active"></div>
              <div className="interest-active"></div>
              <div></div>
            </div>
          </div>
          <div className="selsect-wrapper">
            <h3 className="interest-modal-heading">Select your interests</h3>
            <span className="mb-4">Select atleast 1 interest</span>
            <div className="row mt-2">
              <div className="col-12">
                {category &&
                  category.map((course, index) => (
                    <button
                      key={index}
                      // className="select-btn"
                      className={
                        interestSelect.indexOf(course) > -1
                          ? "selected-btn select-btn"
                          : "select-btn"
                      }
                      onClick={() => handleCategory(course)}
                    >
                      {course}
                    </button>
                  ))}
              </div>
              <div className="col-12 mt-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={acceptTerms}
                    onChange={() => setAcceptTerms(!acceptTerms)}
                    id="flexCheckDefault"
                  />
                  <label className="form-check-label wp-notification mt-1" for="flexCheckDefault">
                    <span className="terms">
                      I agree with
                      <a href="#" className="terms mx-1">
                        Terms and Conditions
                      </a>
                      &
                      <a href="#" className="conditions ms-1">
                        Privacy Policy
                      </a>
                    </span>
                  </label>
                </div>
              </div>
              <div className="col-12">
                {acceptTerms ? (
                  <button
                    className="next "
                    data-bs-toggle="modal"
                    data-bs-target="#welcomeModal"
                    type="button"
                    onClick={handleConfirmInterest}
                  >
                    Confirm
                  </button>
                ) : (
                  <button
                    className="next disable"
                    data-bs-toggle="modal"
                    data-bs-target="#welcomeModal"
                    type="button"
                  >
                    Confirm
                  </button>
                )}

                <button
                  className="back"
                  data-bs-toggle="modal"
                  data-bs-target="#PersonalInfo"
                  type="button"
                  onClick={handleSelectInterestBack}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* welcome page  */}
      <Modal show={showWelcome} backdrop="static">
        <button
          type="button"
          className="close-btn ms-auto mt-2 me-2"
          aria-label="Close"
          onClick={() => setshowWelcome(false)}
        ></button>
        <Modal.Body closeButton>
          <div className="modal-body">
            <h3 className="welcome-name">Welcome, {userName}</h3>
            <span className="welcoome-para">
              Great ! You have successfully completed your account creation.
            </span>
            <div className="row">
              <div className="col-12">
                <a href="#">
                  <LazyLoadImage src={welcomeImg} className="welcomeImg" alt="welcomeImg" />
                </a>
              </div>
            </div>
            <Link to="/AllCourses">
              <button className="start-learning-btn" data-bs-dismiss="modal" onClick={loginUser}>
                Start Learning
              </button>
            </Link>
          </div>
        </Modal.Body>
      </Modal>

      {/* educator modal */}
      <Modal backdrop="static" show={educatorPopUp}>
        <button
          type="button"
          className="close-btn ms-auto mt-2 me-2"
          aria-label="Close"
          onClick={() => seteducatorPopUp(false)}
        ></button>
        <Modal.Body closeButton>
          <h3 className="login-modal-heading">Create Account</h3>
          <p>Choose your profile</p>
          <div className="row">
            <div className="col-md-6">
              <button
                className="educator"
                data-bs-toggle="modal"
                data-bs-target="#educatorModal"
                style={{ background: "#F2FCFF" }}
              >
                <LazyLoadImage
                  src={signup_1}
                  alt="signup_1"
                  onClick={selsectEduBg}
                  style={{ background: educatorBg }}
                />
                <span>Educator</span>
              </button>
            </div>
            <div className="col-md-6">
              <button
                className="student"
                style={{ background: studentBg }}
                onClick={handleStudentClick}
              >
                <LazyLoadImage src={signup_3} alt="signup_3" onClick={selsectStuBg} />
                <span>Student</span>
              </button>
            </div>
          </div>
          <span className="educator-hiring">
            Thank you for your interest in being CoachX parterened trainer.To create your complete
            profile and add courses download CoachX app
          </span>
          <br />
          <a
            href="https://play.google.com/store/apps/details?id=com.classboat.coachx"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LazyLoadImage src={googlePlay} alt="googlePlay" className="googlePlayModal" />
          </a>
        </Modal.Body>
      </Modal>
    </div>
  );
}
