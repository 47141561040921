import React, { useState } from "react";
import AdditionalReqi from "../components/AdditionalReqi";
import AddSessions from "../components/AddSessions";
import Batches from "../components/Batches";
import CourseFee from "../components/CourseFee";
import EditSession from "../components/EditSession";
import MyCourses from "../components/MyCourses";
import MySessions from "../components/MySessions";
// import SuccessfullPage from "../components/SuccessfullPage";
import EducatorAboutCourse from "../components/EducatorAboutCourse";
import ProfileApprovel from "../components/ProfileApprovel";
export default function EducatorPage(){
  const [pageNo, setpageNo] = useState(9)

  return (
    <div>
      {
        pageNo == 1 &&
          <EducatorAboutCourse />
        }
       {
        pageNo == 2 &&
          <AdditionalReqi />
        }
       {
        pageNo == 3 &&
        <CourseFee />
        }
       {
        pageNo == 4 &&
      <AddSessions /> 
        }
       {
        pageNo == 5 &&
      <EditSession />
        }
       {
        pageNo == 6 &&
       <Batches /> 
        }
       {
        pageNo == 7 &&
        <ProfileApprovel />
        }
       {
        pageNo == 8 &&
      <MyCourses />
        }
       {
        pageNo == 9 &&
      <MySessions />
        }
    </div>
  );
}
