import {SIGNUP_REQUEST,SIGNUP_SUCCESS,SIGNUP_FAIL} from "../constant/loginConstant"

export const CreateSignUpReducer=(state={signUp:{}},{type,payload})=>{
    switch (type) {
        case SIGNUP_REQUEST:
            return {...state,isLoading:true}
        case SIGNUP_SUCCESS:
            return {isLoading:false,signUp:payload}
        case SIGNUP_FAIL:
            return {isLoading:false,signUp:payload}
        default:return state
    }
    }