import React from 'react'

export default function CreateNewBatch() {
  return (
    <div>
        <div className="row mt-5">
            <h2 className="mt-4">Create New Batch</h2>
            <hr />
            <div className="col-6">
                <div>
                    <label htmlFor="">Batch Name </label>
                    <input type="text" className='form-control' placeholder="Enter Batch Name" />
                </div>
            </div>
            <div className="col-6">
                <div>
                    <label htmlFor="">Batch Code </label>
                    <input type="text" className='form-control' placeholder="Enter Batch Code" />
                </div>
            </div>
            <div className="col-6">
                <div>
                    <label htmlFor="">Batch Start Date </label>
                    <input type="date" className='form-control' placeholder="select Start Date" />
                </div>
            </div>
            <div className="col-6">
                <div>
                    <label htmlFor="">Trainer Name</label>
                    <input type="text" className='form-control' placeholder="Enter Trainer Name"/>
                </div>
            </div>
            <div className="col-12 mt-4">
                <button className='btn btn-info w-100'>Create Batch</button>
            </div>
        </div>
    </div>
  )
}
