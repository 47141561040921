import axios from "axios"
import {USER_SIGNIN_VERIFY_REQUEST,USER_SIGNIN_VERIFY_SUCCESS,USER_SIGNIN_VERIFY_FAIL} from "../constant/userSignInVerifyConst"

export const userSignInVerifyAction=(signInVerifyCredentials)=>async(dispatch)=>{
    console.log(signInVerifyCredentials);
    // var tokenDetails  = JSON.parse(localStorage.getItem("UserData")); 
    let deviceUDID = localStorage.getItem("DEVICE-UDID");   
    try {
        dispatch({type:USER_SIGNIN_VERIFY_REQUEST})
        axios.defaults.headers.common = {
            // 'Authorization': `Bearer ${tokenDetails.access}`,
            'DEVICE-UDID': deviceUDID
          }
    const {data}=await axios.post("/users/verify/",signInVerifyCredentials)    
    console.log(data);
    dispatch({type:USER_SIGNIN_VERIFY_SUCCESS,payload:data})
    } catch (error) {
        dispatch({type:USER_SIGNIN_VERIFY_FAIL, payload:error.response.data})
    }
}