import {LOGIN_REQUEST,LOGIN_SUCCESS,LOGIN_FAIL} from "../constant/loginConstant"

export const logInReducer=(state={Login:{}},{type,payload})=>{
    switch (type) {
        case LOGIN_REQUEST:
            return {...state,isLoading:true}
        case LOGIN_SUCCESS:
            return {isLoading:false,Login:payload}
        case LOGIN_FAIL:
            return {isLoading:false,Login:payload}
        default:return state
    }
    }