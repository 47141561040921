import React, { useState } from "react";
import TestGradeBatches from "./TestGradeBatches";
import stuCourse from "../../images/stuCourses.png";
import myCoursesCalendar from "../../images/myCoursesCalendar.png";
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function EduTestCourses() {
  const [comp, setcomp] = useState("");
  const handleBatches = (para) => {
    setcomp(para);
  };
  return (
    <div>
      {comp === "batch" ? (
        <TestGradeBatches/>
      ) : (
        <div>
          <h4>Courses</h4>
          <br/>
          <div className="row">
            <div className="col-md-6 col-lg-4 col-12 mb-3">
              <div className="card">
                <div className="">
                  <LazyLoadImage
                    src={stuCourse}
                    width="100%"
                    height="100%"
                    alt="card img"
                  />
                </div>
                <div className="">
                  <h5 className="mx-2">Data analytics</h5>
                  <p className="ms-2">
                    <LazyLoadImage src={myCoursesCalendar} alt="" />{" "}
                    <span>30-12-2022</span>
                  </p>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn eduCourseBtn my-3  w-75"
                      onClick={() => handleBatches("batch")}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-12 mb-3">
              <div className="card">
                <div className="">
                  <LazyLoadImage
                    src={stuCourse}
                    width="100%"
                    height="100%"
                    alt="card img"
                  />
                </div>
                <div className="">
                  <h5 className="mx-2">Data analytics</h5>
                  <p className="ms-2">
                    <LazyLoadImage src={myCoursesCalendar} alt="" />
                    <span>30-12-2022</span>
                  </p>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn eduCourseBtn my-3  w-75"
                      onClick={() => handleBatches("batch")}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 col-12 mb-3">
              <div className="card">
                <div className="">
                  <LazyLoadImage
                    src={stuCourse}
                    width="100%"
                    height="100%"
                    alt="card img"
                  />
                </div>
                <div className="">
                  <h5 className="mx-2">Data analytics</h5>
                  <p className="ms-2">
                    <LazyLoadImage src={myCoursesCalendar} alt="" />{" "}
                    <span>30-12-2022</span>
                  </p>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn eduCourseBtn my-3  w-75"
                      onClick={() => handleBatches("batch")}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
