import {
  REQUEST_CALL_BACK_REQUEST,
  REQUEST_CALL_BACK_SUCCESS,
  REQUEST_CALL_BACK_FAIL,
} from "../constant/requestCallBackConstant";
import axios from "axios";

export const requestCallAction = (enquiryData) => async (dispatch) => {
  try {
    dispatch({ type: REQUEST_CALL_BACK_REQUEST });
    const formData = new FormData();
    formData.append("name", enquiryData.name);
    formData.append("email", enquiryData.email);
    formData.append("phone_numbers", enquiryData.mobile);
    formData.append("query", enquiryData.query);
    const { data } = await axios.post("/users/contact_us/profile/", formData);
    console.log(data);
    dispatch({ type: REQUEST_CALL_BACK_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: REQUEST_CALL_BACK_FAIL, payload: error });
  }
};
