import {USERSIGNIN_REQUEST,USERSIGNIN_SUCCESS,USERSIGNIN_FAIL} from "../constant/userSignInConst"

export const userSignInReducer=(state={userSignIn:{}},{type,payload})=>{
    switch (type) {
        case USERSIGNIN_REQUEST:
            return {...state,isLoading:true}
        case USERSIGNIN_SUCCESS:
            return {isLoading:false,userSignIn:payload}
        case USERSIGNIN_FAIL:
            return {isLoading:false,userSignIn:payload}
        default:return state
    }
    }