import axios from "axios";
import {
  UPCOMING_COURSE_REQUEST,
  UPCOMING_COURSE_SUCCESS,
  UPCOMING_COURSE_FAIL,
  UPCOMING_WORKSHOP_REQUEST,
  UPCOMING_WORKSHOP_SUCCESS,
  UPCOMING_WORKSHOP_FAIL,
} from "../constant/allCourses";

export const upcomingworkshopAction = (type) => async (dispatch) => {
  try {
    dispatch({ type: UPCOMING_WORKSHOP_REQUEST });
    const { data } = await axios.get("/course/course/list?trend=new");
    console.log(data.data.course_list.results);

    dispatch({ type: UPCOMING_WORKSHOP_SUCCESS, payload: data.data.course_list.results });
  } catch (error) {
    dispatch({ type: UPCOMING_WORKSHOP_FAIL, payload: error });
  }
};

export const upcomingCoursesAction = (type) => async (dispatch) => {
  try {
    dispatch({ type: UPCOMING_COURSE_REQUEST });
    const { data } = await axios.get("/course/course/list?trend=new");
    console.log(data.data.course_list.results);
    dispatch({ type: UPCOMING_COURSE_SUCCESS, payload: data.data.course_list.results });
  } catch (error) {
    dispatch({ type: UPCOMING_COURSE_FAIL, payload: error });
  }
};
