import {USER_SIGNIN_VERIFY_REQUEST,USER_SIGNIN_VERIFY_SUCCESS,USER_SIGNIN_VERIFY_FAIL} from "../constant/userSignInVerifyConst"

export const userSignInVerifyReducer=(state={userSignInVerify:{}},{type,payload})=>{
    switch (type) {
        case USER_SIGNIN_VERIFY_REQUEST:
            return {...state,isLoading:true}
        case USER_SIGNIN_VERIFY_SUCCESS:
            return {isLoading:false,userSignInVerify:payload}
        case USER_SIGNIN_VERIFY_FAIL:
            return {isLoading:false,userSignInVerify:payload}
        default:return state
    }
    }