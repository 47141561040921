import React, { useState } from 'react'
import Notes from './Notes';

export default function NotesList() {
  // back btn logic
  const [back, setBack] = useState("");
  const handleBack = (para) => {
    setBack(para);
  };
  return (
    <div>
      {
      back === "Assignment"
        ?<Notes/>
        :<div>
        <h1>
            <i
              className="backbtn bi bi-arrow-left-circle-fill"
              onClick={() => handleBack("Assignment")}
            ></i>
        </h1>
     <h4>Courses Notes</h4>
     <br />
     <div className="table-responsive">
       <table className="table table-success table-striped table-bordered">
           <thead>
           <tr className='table-primary'>
               <th className='tableSrNo'>Sr.No.</th>
               <th>Notes</th>
               <th>Actions</th>
           </tr>
           </thead>
           <tbody>
           <tr>
               <td>1</td>
               <td>Note-1</td>
               <td><button className='btn btn-sm btn-success'>View</button></td>
           </tr>
           </tbody>
       </table>      
     </div>
   </div>
}
    </div>
  )
}
