import {
  REQUEST_CALL_BACK_REQUEST,
  REQUEST_CALL_BACK_SUCCESS,
  REQUEST_CALL_BACK_FAIL,
} from "../constant/requestCallBackConstant";

export const requestCallReducer = (state = { requestCall: {} }, { type, payload }) => {
  switch (type) {
    case REQUEST_CALL_BACK_REQUEST:
      return { ...state, isLoading: true };
    case REQUEST_CALL_BACK_SUCCESS:
      return { isLoading: false, requestCall: payload };
    case REQUEST_CALL_BACK_FAIL:
      return { isLoading: false, requestCall: payload };
    default:
      return state;
  }
};
