import {EDUCATOR_INFO_REQUEST,EDUCATOR_INFO_SUCCESS,EDUCATOR_INFO_FAIL} from "../constant/educatorInfo"
import axios from "axios"

export const EducatorInfoAction=(Info)=>async(dispatch)=>{
    try {
        dispatch({type:EDUCATOR_INFO_REQUEST})
        axios.defaults.headers.common = {
            'Authorization': '',
            'DEVICE-UDID': ''
          }
    // const {data}=await axios.post("course/edu/enquiry/",Info)    
    const {data}=await axios.post("/users/educator_lead/profile/",Info)    
    dispatch({type:EDUCATOR_INFO_SUCCESS,payload:data})
    } catch (error) {
        dispatch({type:EDUCATOR_INFO_FAIL, payload:error.response.data})
    }
}