import React from "react";
import Logo from "../../../../../images/logo png.png";
import "../Banner/banner.css";
import { ReactTyped } from "react-typed";
import Technology from "../../../../../images/train.png";

function Banner() {
  // const bookAnAppointment = () => {
  //   url: "https://calendly.com/bhanu-h4k/career-counselling?back=1&month=2024-03";
  // };
  return (
    <div className="banner">
      <div className="container d-flex">
        <div className="col-md-6 first-column">
          <span>
            <h3>
              <span>Get</span>
              <br />
              <span className="multitext">
                <ReactTyped
                  strings={[
                    "Trained Employees At Zero Cost",
                    "Employees Train With Latest Technology",
                  ]}
                  typeSpeed={100}
                  loop
                  backSpeed={20}
                  cursorChar="|"
                  showCursor={true}
                  backDelay={20}
                />
              </span>
              <br />
              <p className="para">
                know more about our services & get most of us know more about our services & get
                most of us know more about our services & get most of us know more about our
                services & get most of u.
              </p>
            </h3>
            <div className="d-flex">
              <button className="talktous">Talk to us</button>

              <a
                href="https://calendly.com/bhanu-h4k/career-counselling?back=1&month=2024-03"
                className="bookanappointment"
                target="blank"
              >
                Book an Appointment
              </a>
            </div>
          </span>
        </div>
        <div className="col-md-6 second-column">
          <h3>Our Students Get Trained In</h3>
          <div>
            <img src={Technology} width={500} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
