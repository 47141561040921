import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import "../style/EducatorAboutCourse.css"
import courseimg from "../images/courseImg.png"
import shortvideo from "../images/shortVideo.png"
import EduNavigationButtons from './EduNavigationButtons'
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function EducatorAboutCourse() {
  const [course, setcourse] = useState({})

  const redirectToAdditionalReqi=()=>{
    localStorage.setItem("eduData",JSON.stringify(course))
    window.location.href = window.location.origin + `/additionalReqi`;
  }
 
  const redirectToBack=()=>{
    window.location.href = window.location.origin + "/dashBoardHome";
  }

  return (
    <div>
        <Navbar/>
        <div className='container-fluid'>
            <div className='row'>
            <div className="col-2 sidebar-col text-light pb-0">
            <div className="d-flex flex-column align-items-end vh-100">
            <Link to="/dashBoardHome"><span className="mt-3 sidebar-item">Dashboard</span></Link>
              <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item">Edit Profile</span></Link>
              <Link to="/addSessions"><span className="mt-3 sidebar-item">Upload Courses</span></Link>
              <Link to="/myCourses"><span className="mt-3 sidebar-item">My Courses</span></Link>
              <Link to="/mySessions"><span className="mt-3 sidebar-item">My Sessions</span></Link>
            </div>
            <div className="footer-terms d-flex flex-column align-items-end">
              <span>
                <Link>Terms,</Link>
                <Link>Privacy Policy</Link>
              </span>
              <span>CoachX Rights Reserved</span>
            </div>
          </div>
          <div className="col-md-8 col-12 rightside-col ">
               {/* /////////////////offcanvas///////////////// */}
      <button className="btn btn-primary sidebar-btn" type="button" data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">sidebar</button>

      <div className="offcanvas offcanvas-start sidebar" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
        <div className="offcanvas-header">
          <button type="button" className="btn-close ms-auto text-light" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body ">
        <div className="d-flex flex-column align-items-end vh-100">
        <Link to="/dashBoardHome"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Dashboard</span></Link>
              <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Edit Profile</span></Link>
              <Link to="/addSessions" ><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Upload Courses</span></Link>
              <Link to="/myCourses"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">My Courses</span></Link>
              <Link to="/"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">My Sessions</span></Link>
        </div>
        </div>
      </div>
            <div className='row'>
              <div className='col-12 d-flex justify-content-center'>
                <div className='statusBar activeStatus'></div>
                <div className='statusBar'></div>
                <div className='statusBar'></div>
                <div className='statusBar'></div>
                <div className='statusBar'></div>
              </div>
            </div>
            <div className="row">
             <h1 className='dashboardAboardCourses'>About Course</h1>
             <div className='container'>
              <div className='d-flex justify-content-evenly align-items-center mt-5'>
                <div>Upload</div>
                <div className='uploadImg text-center'><span>Course Image</span> 
                <input type="file" className="d-none" id="profilePic" 
                    onChange={(e)=>setcourse({...course,thumbnail:e.target.value})}
                />
                    <label className="" for="profilePic">
                    <LazyLoadImage src={courseimg} alt="ui"  width="30" />
                    </label>
                </div>
                <p>Make sure File size should be less than 500 KB and the format needs to be either PNG or JPEG</p>
              </div>
              <div className='d-flex justify-content-evenly align-items-center ms-5 mt-5'>
                <div className='short-video  d-flex  align-items-center'><span>Short Video</span> 
                <span className="input-group">
                    <input type="file" className="d-none" id="profilePic1" 
                      onChange={(e)=>setcourse({...course,video:e.target.value})}/>
                    <label className="" for="profilePic1">
                       <LazyLoadImage src={shortvideo} alt="sv" width="30" height={30}/>
                    </label>
                </span>
                </div>
                <p>Make sure File size should be less than 200 MB and the format needs to be either MP4 or MOV</p>
              </div>
              <div className='d-flex justify-content-center align-items-center flex-column'>
                <input type="text"  placeholder="Title of the course" className='form-control form-select-lg aboutUs-input w-md-75 w-100 mt-3'
                 onChange={(e)=>setcourse({...course,title:e.target.value})}
                />
                <textarea name="" id="" cols="30" rows="5" className='form-control w-md-75 w-100 mt-3' placeholder='Description About Course'
                onChange={(e)=>setcourse({...course,description:e.target.value})}
                ></textarea>
                
                <textarea name="" id="" cols="30" rows="5" className='form-control w-md-75 w-100 mt-3' placeholder='Learning outcomes'
                onChange={(e)=>setcourse({...course,syllabus:e.target.value})}
                ></textarea>
                <select className="form-select form-select-lg w-md-75 w-100 mt-3" aria-label="Default select example"
                onChange={(e)=>setcourse({...course,category:e.target.value})}
                >
                  <option value="" selected disabled hidden>Domain</option>
                  <option value="1">Technical</option>
                  <option value="2">Language</option>
                  <option value="3">Career</option>
                </select>
               <EduNavigationButtons/>
              </div>
            </div>
            </div>
          </div>
            </div>
        </div>
    </div>
  )
}
