import axios from "axios"
import {CATAGORY_REQUEST,CATAGORY_SUCCESS,CATAGORY_FAIL} from "../constant/catagoryConstant"
export const catagoryAction=()=>async(dispatch)=>{
    try {
        dispatch({type:CATAGORY_REQUEST})
    const {data}=await axios.get("/course/category/list")    
    console.log(data);
    dispatch({type:CATAGORY_SUCCESS,payload:data.data.categories})
    } catch (error) {
        dispatch({type:CATAGORY_FAIL, payload:error})
    }
}
