import {SIGNUP_VERIFY_REQUEST,SIGNUP_VERIFY_SUCCESS,SIGNUP_VERIFY_FAIL} 
from "../constant/loginConstant"
import axios from "axios"
export const SignUpVerifyAction=(signUpVerifyCredentials)=>async(dispatch)=>{
    console.log(signUpVerifyCredentials);
    try {
        dispatch({type:SIGNUP_VERIFY_REQUEST})
    const {data}=await axios.post("users/verifyotp/",signUpVerifyCredentials)    
    console.log(data);
    dispatch({type:SIGNUP_VERIFY_SUCCESS,payload:data})
    } catch (error) {
        dispatch({type:SIGNUP_VERIFY_FAIL, payload:error})
    }
}