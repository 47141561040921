import React, { useEffect, useRef, useState } from "react";
import "../style/coupenCodeNavbar.css";
import CoupenCodeNavbar from "../components/CoupenCodeNavbar";
import coupenCode3 from "../images/coupenCode3.png";
import coupenCode4 from "../images/coupenCode4.png";
import coupenCode5 from "../images/coupenCode5.png";
import coupenCode6 from "../images/coupenCode6.png";
import coupenCode7 from "../images/coupenCode7.png";
import coupenCode8 from "../images/coupenCode8.png";
import coupenCode9 from "../images/coupenCode9.png";
import coupenCode10 from "../images/coupenCode10.png";
import coupenCode11 from "../images/coupenCode11.png";
import { userSignInAction } from "../Action/userSignInAction";
import { useDispatch, useSelector } from "react-redux";
import { userSignInVerifyAction } from "../Action/userSignInVerifyAction";
import { getTokenAction } from "../Action/getTokenAction";
import { UserCouponAction } from "../Action/userCouponAction";
import { useLocation } from "react-router-dom";
import axios from "axios";
import logo from "../images/BLC_ inverse.png";
import { resendOtpAction } from "../Action/resendOtpAction";
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function CoupenCodeForm() {
  const [firstName, setfirstName] = useState("");
  const [emailId, setemailId] = useState("");
  const [mobileNumber, setmobileNumber] = useState("");
  const [user, setuser] = useState("S");
  const [OTP, setOTP] = useState("");
  const [buttons, setbuttons] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [couponCode, setcouponCode] = useState("");
  const [varification, setvarification] = useState(false);
  const [userData, setUserData] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState([]);
  const [orderId, setOrderId] = useState(0);
  const [localData, setlocalData] = useState();
  const [userInfo, setuserInfo] = useState();
  const [batchId, setBatchId] = useState();
  const [amount, setAmount] = useState(0);
  // var userInfo;
  const dispatch = useDispatch();
  const { userSignIn } = useSelector((state) => state.userSigned);
  const { userSignInVerify } = useSelector((state) => state.userSignInVerified);
  const { token } = useSelector((state) => state.token);
  const { userCoupon } = useSelector((state) => state.coupon);
  // Apply coupon logic
  const search = useLocation().search;
  const course_info = new URLSearchParams(search).get("courseDetails");
  const { courseDetails, isLoading } = useSelector((state) => state.course);
  const [totalPrice, setTotalPrice] = useState(0);

  //signIn logic

  const signin = () => {
    dispatch(
      userSignInAction({
        username: "+91" + mobileNumber,
        first_name: firstName,
        email: emailId,
        user_type: user,
      })
    );
  };
  useEffect(() => {
    if (localStorage.getItem("UserData")) {
      setuserInfo(JSON.parse(localStorage.getItem("UserData")));
    }
    setBatchId(window.location.search.split("=")[1]);
    if(courseDetails?.discount > 0){
      setAmount(courseDetails?.discount)
    }else{
      setAmount(courseDetails?.fees)
    }
  }, [courseDetails]);

  useEffect(() => {
    setbuttons(userSignIn?.data?.result?.is_otp_sent_successfully);
  }, [userSignIn]);

  //Verify OTP logic

  const signInVerify = () => {
    setvarification(userSignInVerify?.data?.result?.is_otp_verified);
    dispatch(
      userSignInVerifyAction({
        username: "+91" + mobileNumber,
        password: OTP,
        first_name: firstName,
        email: emailId,
      })
    );
  };

  // get token logic
  useEffect(() => {
    if (userSignInVerify) {
      if (userSignInVerify?.data?.user_message) {
        alert(userSignInVerify?.data?.user_message);
      }
      setlocalData(userSignInVerify);
      if (userSignInVerify?.data?.result?.is_otp_verified) {
        console.log(userSignInVerify);
        localStorage.setItem(
          "UserData",
          JSON.stringify(userSignInVerify?.data?.result)
        );
      }
    }
  }, [userSignInVerify]);

  useEffect(() => {
    if (token?.access) {
      localStorage.setItem("UserData", JSON.stringify(token));
    }
  }, [token]);

  useEffect(() => {
    setTotalPrice(amount);
  }, [amount]);

  useEffect(() => {
    if (userCoupon?.data?.result?.new_price > 0) {
      setTotalPrice(userCoupon?.data?.result?.new_price);
    }
  }, [userCoupon?.data?.result?.new_price]);

  const applyCoupon = () => {
    dispatch(
      UserCouponAction({
        batch_info: batchId,
        coupon_code: couponCode,
      })
    );
  };

  //rayzorpay code
  const inputRef=useRef()
const autofocusOnInput=()=>{
  inputRef.current.focus()
}
  const GetOrderId = () => {
    let tempUserData = JSON.parse(localStorage.getItem("UserData"));
    if(!mobileNumber){
      alert("To Enroll this course login is required.")
    }
    setUserData(tempUserData);
    let deviceUDID = localStorage.getItem("DEVICE-UDID");
    axios.defaults.headers.common = {
      Authorization: `Bearer ${tempUserData.access}`,
      "DEVICE-UDID": deviceUDID,
    };

    var Provider = "NA";
    if (totalPrice > 0) {
      Provider = "R";
    }
    let data = {
      batch_info: batchId,
      stu_info: tempUserData.id,
      amount: totalPrice,
      currency: "INR",
      notes: "student",
      provider: Provider,
      source: "W",
    };

    axios
      .post("/course/batch/enrol/", data)
      .then((res) => {
        if (res.data.data.result.is_order_id_created) {
          setOrderId(res.data.data.result.order_id);
          if (totalPrice == 0) {
            window.location.href =
              window.location.origin +
              "/thankYouForEnrollment?course_info=" +
              courseDetails?.id;
          }
        } else {
          // window.location.href =
          //   window.location.origin +
          //   "/paymentFail?course_info=" +
          //   courseDetails?.id;
        }
      })
      .catch((error) => {
        // window.location.href =
        //   window.location.origin +
        //   "/paymentFail?course_info=" +
        //   courseDetails?.id;
      });
  };

  useEffect(() => {
    if (orderId != 0) {
      displayRazorpay();
    }
  }, [orderId]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    var razorpayKey = "";
    if (process.env.REACT_APP_ENV_key == "prod") {
      razorpayKey = process.env.REACT_APP_RAZORPAY_APP_ID_prod;
    }
    if (process.env.REACT_APP_ENV_key == "stage") {
      razorpayKey = process.env.REACT_APP_RAZORPAY_APP_ID_test;
    }
    if (process.env.REACT_APP_ENV_key == "dev") {
      razorpayKey = process.env.REACT_APP_RAZORPAY_APP_ID_test;
    }
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const options = {
      key: razorpayKey, // Enter the Key ID generated from the Dashboard
      amount: totalPrice,
      currency: "INR",
      name: "CoachX",
      description: "CoachX",
      image: { logo },
      order_id: orderId,
      handler: async function (response) {
        window.location.href =
          window.location.origin +
          "/thankYouForEnrollment?course_info=" +
          courseDetails?.id;
        const data = {
          orderCreationId: orderId,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        const result = await axios.post(
          "http://localhost:5000/payment/success",
          data
        );
        alert(result.data.msg);
      },
      prefill: {
        name: userData.first_name,
        contact: userData.username,
        email: userData.email,
      },
      notes: {
        source: "LandingPage",
        BatchId: batchId,
      },
      theme: {
        color: "#61dafb",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  //resend otp
  const handelResendOtp=()=>{
    dispatch(
      resendOtpAction({
        username: "+91" + mobileNumber,
        password: "4654654",
        is_signup: true,
      })
    );

  }

  return (
    <div>
      <CoupenCodeNavbar />
      <div className="coupenCode-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-lg-first order-last">
              <h3 className="coupenCodeHeading">What You'll Get?</h3>
              <div>
                <p className="d-flex ">
                  <LazyLoadImage src={coupenCode3} alt="coupenCode3" />
                  <span className="couperCodeText ms-3">
                    100% Placement Assistance
                  </span>
                </p>
                <p className="d-flex">
                  <LazyLoadImage src={coupenCode4} alt="coupenCode4" />
                  <span className="couperCodeText ms-3">
                    Career guidance from IIT Mumbai Technology expert
                  </span>
                </p>
                <p className="d-flex">
                  <LazyLoadImage src={coupenCode5} alt="coupenCode5" />
                  <span className="couperCodeText ms-2">
                    100% Live Training
                  </span>
                </p>
                <p className="d-flex mb-2">
                  <LazyLoadImage src={coupenCode6} alt="coupenCode6" />{" "}
                  <span className="couperCodeText ms-2">
                    Working Extensively On Real Time Projects
                  </span>
                </p>
                <p className="d-flex">
                  <LazyLoadImage src={coupenCode7} alt="coupenCode7" />
                  <span className="couperCodeText ms-4">
                    Advanced Curriculum
                  </span>
                </p>
                <p className="d-flex">
                  <LazyLoadImage src={coupenCode8} alt="coupenCode8" />
                  <span className="couperCodeText ms-3">
                    Resume Building And Mock Interviews
                  </span>
                </p>
                <p className="d-flex">
                  <LazyLoadImage src={coupenCode7} alt="coupenCode7" />
                  <span className="couper2CodeText ms-4">
                    Convenient Batch Timings With All Recordings
                  </span>
                </p>
                <p className="d-flex">
                  <LazyLoadImage src={coupenCode9} alt="coupenCode9" />
                  <span className="couperCodeText ms-2">
                    Quizzes, Assignments, and tests to evaluate your levels
                  </span>
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <LazyLoadImage src={coupenCode10} height="90px" alt="coupenCode10" />
                <span className="getCerttified">Get Certified!</span>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <LazyLoadImage
                  src={courseDetails?.dummy_certificate}
                  className="CoupenCodeCertificate"
                  alt="no image"
                />
              </div>
            </div>
            <div className="col-lg-6 order-lg-last order-first">
              <div className="coupenForm">
                <h4 className="formHeading">Start Your Application Today</h4>
                {userInfo ? (
                  <div>
                    <input
                    // ref={inputRef}
                      type="text"
                      className="form-control coupenInput1 my-4"
                      placeholder="Enter Name"
                      value={userInfo?.first_name}
                      // onChange={(e) => setfirstName(e.target.value)}
                      autofocus
                    />
                    {/* <div className="mobile-input my-4"> */}
                    <input
                      type="text"
                      max={10}
                      className="form-control coupenInput1"
                      placeholder="WhatsApp Number"
                      value={userInfo?.username}
                      // onChange={(e) => setmobileNumber(e.target.value)}
                    />
                    {/* </div> */}
                    <input
                      type="email"
                      className="form-control coupenInput1 my-4"
                      placeholder="Email ID"
                      value={userInfo?.email}
                      // onChange={(e) => setemailId(e.target.value)}
                    />
                  </div>
                ) : (
                  <div>
                    {buttons ? (
                      <div>
                        <input
                          type="text"
                          className="form-control coupenInput1 my-4"
                          placeholder="Enter Name"
                          value={firstName}
                          // onChange={(e) => setfirstName(e.target.value)}
                        />
                        
                        <input
                            type="number"
                            max={10}
                            className="form-control coupenInput1"
                            placeholder="WhatsApp Number"
                            value={mobileNumber}
                            // onChange={(e) => setmobileNumber(e.target.value)}
                        />
                        <div className={userSignInVerify?.data?.result?.is_otp_verified?"none":"block"}>
                        <div className="mobile-input my-4">
                        <input
                          type="number"
                          // max={6}
                          className="form-control coupenInput2"
                          placeholder="Enter OTP"
                          value={OTP}
                          onChange={(e) => setOTP(e.target.value)}
                        />
                          <button className="sendotp" onClick={signInVerify}>
                            Verify OTP
                          </button>
                        </div>
                        <p className="Coupon_resendOtp text-end" onClick={handelResendOtp}>Resend OTP</p>
                        </div>
                        <button className={userSignInVerify?.data?.result?.is_otp_verified?"block verified w-100 my-4":"none"} onClick={GetOrderId}>
                          Verified
                        </button>
                        <input
                          type="email"
                          className="form-control coupenInput1 mb-4"
                          placeholder="Email ID"
                          value={emailId}
                          // onChange={(e) => setemailId(e.target.value)}
                        />
                      </div>
                    ) : (
                      <div>
                        <input
                          type="text"
                          className="form-control coupenInput1 my-4"
                          placeholder="Enter Name"
                          value={firstName}
                          onChange={(e) => setfirstName(e.target.value)}
                        />
                        <div className="mobile-input my-4">
                          <input
                            type="number"
                            max={10}
                            className="form-control coupenInput2"
                            placeholder="WhatsApp Number"
                            value={mobileNumber}
                            onChange={(e) => setmobileNumber(e.target.value)}
                          />
                          <button className="sendotp" onClick={signin}>
                            Send OTP
                          </button>
                        </div>
                        <input
                          type="email"
                          className="form-control coupenInput1 my-4"
                          placeholder="Email ID"
                          value={emailId}
                          onChange={(e) => setemailId(e.target.value)}
                        />
                      </div>
                    )}
                  </div>
                )}
                <hr className="my-5" />
                <div className=" row d-flex justify-content-between align-items-center">
                  <div className="col-6">
                    <span className="courseName fs-6">
                      {courseDetails?.title} | {courseDetails?.language}
                    </span>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <span className="courseName">
                      &#8377;{courseDetails?.discount}/-
                    </span>
                  </div>
                </div>
                {userCoupon?.data?.result?.valid_coupon ? (
                  <div>
                    <div className="OTPcodeBox">
                      <div className="d-flex flex-column">
                        <span className="mb-2">
                          {" "}
                          Code{" "}
                          <span className="fs-6 fw-bold">
                            {" "}
                            {couponCode}{" "}
                          </span>{" "}
                          applied!
                        </span>
                        <span className="">15% OFF added successfully</span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="codeBox">
                      <p className="d-flex align-items-center">
                        <span className="percentage"> &#x25;</span>
                        <span className="ms-4 fs-5 ">Have Coupon Code?</span>
                      </p>
                      <div className=" d-flex">
                        <div className="mobile-input1">
                          <input
                            type="text"
                            max={10}
                            className="form-control coupenInput2"
                            placeholder="Enter Coupon Code"
                            value={couponCode}
                            onChange={(e) => setcouponCode(e.target.value)}
                          />
                        </div>
                        <button className="coupenSubmit" onClick={applyCoupon}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                <hr className="my-4" />
                <div className="amount">
                  <div className="d-flex">
                    <div className="w-100">
                      <p className="formHeading fs-5 d-flex justify-content-between">
                        <span>Amount</span>
                        <span>&#8377; {courseDetails?.discount}/-</span>
                      </p>
                      <p className="formHeading fs-5 d-flex justify-content-between">
                        <span>Applied Coupon</span>
                        {userCoupon?.data?.result?.new_price? (
                          <span>
                            &#8377; -{courseDetails?.discount-userCoupon?.data?.result?.new_price}/-
                          </span>
                        ) : (
                          <span>&#8377;-0/-</span>
                        )}
                      </p>
                      <p className="formHeading totalAmount fs-5 d-flex justify-content-between">
                        <span>Total Amount</span>
                        <span>&#8377;{totalPrice}/-</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-100">
                  <button className="book-now w-100" onClick={GetOrderId}>
                    Book Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
