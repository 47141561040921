import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { privacyPolicyAction } from '../Action/privacyPolicyAction'
import Footer from '../components/Footer'
import "../style/ourTerms.css"
export default function PrivacyPolicy() {
    const dispatch =useDispatch()
    const {privacy}=useSelector((state)=>state.privacyPolicy)
    
    useEffect(() => {
        dispatch(privacyPolicyAction())
        console.log(privacy);
    }, [])
    
  return (
    <div>
        <div className="main-wrapper">
            <div className="container">
                    <div className="inner-page">
                <div className="termsAndConditions-heading-wrapper">
                     <h3 className='termsAndConditions-heading'>
                     Privacy Policy
                     </h3>                  
                </div>
                <div className="termsAndConditions-texts-wrapper">
                    <div dangerouslySetInnerHTML={{ __html: privacy }}></div>
                </div>
                    </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}