import {USER_COUPON_REQUEST,USER_COUPON_SUCCESS,USER_COUPON_FAIL} from "../constant/userCouponConst"
import axios from "axios"
import { useSelector } from "react-redux";
import { refreshTokenAction } from "./refreshTokenAction";

export const UserCouponAction=(profileInfo)=>async(dispatch)=>{
    console.log(profileInfo);
    var tokenDetails  = JSON.parse(localStorage.getItem("UserData")); 
    console.log(tokenDetails.access);
    let deviceUDID = localStorage.getItem("DEVICE-UDID");
    console.log(deviceUDID);
    try {
        dispatch({type:USER_COUPON_REQUEST})
        axios.defaults.headers.common = {
            'Authorization': `Bearer ${tokenDetails.access}`,
            'DEVICE-UDID': deviceUDID
        }
    const {data}=await axios.post("/course/batch/coupon/",profileInfo)
    dispatch({type:USER_COUPON_SUCCESS,payload:data})
    } catch (error) {
        if(error?.response?.status == 401){
            dispatch(refreshTokenAction())
        }
        dispatch({type:USER_COUPON_FAIL, payload:error})
    }
}