import {USER_COUPON_REQUEST,USER_COUPON_SUCCESS,USER_COUPON_FAIL} from "../constant/userCouponConst"

export const UserCouponReducer=(state={userCoupon:{}},{type,payload})=>{
    switch (type) {
        case USER_COUPON_REQUEST:
            return {...state,isLoading:true}
        case USER_COUPON_SUCCESS:
            return {isLoading:false,userCoupon:payload}
        case USER_COUPON_FAIL:
            return {isLoading:false,userCoupon:payload}
        default:return state
    }
    }