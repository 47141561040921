import axios from "axios"
import {LOGIN_REQUEST,LOGIN_SUCCESS,LOGIN_FAIL} from "../constant/loginConstant"

export const logInAction=(loginCredentials)=>async(dispatch)=>{
    console.log(loginCredentials);
    try {
        dispatch({type:LOGIN_REQUEST})
        axios.defaults.headers.common = {
            'Authorization': '',
            'DEVICE-UDID': ''
          }
    const {data}=await axios.post("/users/signin_otp/",loginCredentials)
    dispatch({type:LOGIN_SUCCESS,payload:data})
    } catch (error) {
        dispatch({type:LOGIN_FAIL, payload:error.response.data})
    }
}