import React, { useEffect, useState } from 'react'
import Assignment from './Assignment';

export default function AssignmentList({anskey}) {
  const [keyComp, setkeyComp] = useState("")
  useEffect(() => {
    setkeyComp(anskey)
  }, [])
  // back btn logic
  const [back, setBack] = useState("");
  const handleBack = (para) => {
    setBack(para);
  };
  return (
    <div>
      {
        back === "Assignment"
        ?<Assignment/>
        : <div>
          <h1>
              <i
                className="backbtn bi bi-arrow-left-circle-fill"
                onClick={() => handleBack("Assignment")}
              ></i>
          </h1>
        <h4>Course Name</h4>
        <br />
        <div className="table-responsive">
          <table className="table table-success table-striped table-bordered">
              <thead>
              <tr className='table-primary'>
                  <th className='tableSrNo'>Sr.No.</th>
                  <th>Assignment</th>
                  <th>Actions</th>
                  {
                  keyComp !=="key"
                  &&<th>Grades</th>
                  }
              </tr>
              </thead>
              <tbody>
              <tr>
                  <td>1.</td>
                  <td>Assignment-1</td>
                  <td>
                  {
                  keyComp =="key"
                  ?<span>
                    <button className='btn btn-sm btn-success me-2 mt-2'>Download</button>
                      <button className='btn btn-sm btn-success mt-2'>View</button>
                      </span>
                  : <span>
                      <button className='btn btn-sm btn-success me-2 mt-2'>Upload</button>
                      <button className='btn btn-sm btn-success mt-2'>View</button>
                    </span>
                  }
                  </td>
                  {
                  keyComp !=="key"
                  &&<td>A</td>
                  }
              </tr>
              </tbody>
          </table>      
        </div>
        </div>
      }
        
    </div>
  )
}
