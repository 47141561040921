import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { termsAction } from '../Action/termsAction'
import Footer from '../components/Footer'
import "../style/ourTerms.css"
export default function OurTerms() {
  const dispatch =useDispatch()
  const {terms}=useSelector((state)=>state.terms)
  
  useEffect(() => {
      dispatch(termsAction())
    }, [])
    console.log(terms);
  return (
    <div>
        <div className="main-wrapper">
            <div className="container">
                    <div className="inner-page">
                <div className="termsAndConditions-heading-wrapper">
                     <h3 className='termsAndConditions-heading text-center'>
                       Terms and Conditions
                     </h3>
                </div>
                <div className="termsAndConditions-texts-wrapper">
                    <div dangerouslySetInnerHTML={{ __html: terms }}></div>
                </div>
                    </div>
            </div>
        </div>
        <Footer/>
    </div>
  )
}
