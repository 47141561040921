import React, { useEffect, useState } from 'react'
import EduAssignmentList from './EduAssignmentList'
import StuAssignmentOverview from './StuAssignmentOverview'

export default function EduAssignmentStuList() {
  const [comp, setcomp] = useState("")
  useEffect(() => {
    setcomp()
  }, [])

  // back btn logic
   const [back, setBack] = useState("")
   const handleBack=(para)=>{
     setcomp("assGrades")
     setBack(para)
   }
  return (
    <div>
      {comp === "assGrades" ? 
      <div>
        {
        back ==="EduAssignmentList"
        ?<EduAssignmentList/>
        :<StuAssignmentOverview/>
        }
      </div>
       : <div>
        <h1 className="backbtn2">
          <i className="backbtn bi bi-arrow-left-circle-fill" onClick={()=>handleBack("EduAssignmentList")}></i>
        </h1>
        <h2>Enter Grades</h2>
          <div className="table-responsive">
        <table className="table table-success table-striped table-bordered">
            <thead>
            <tr className='table-primary'>
                <th className="tableSrNo">Sr.No.</th>
                <th>Name</th>
                <th>Assignment</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>1.</td>
                <td>aaaa</td>
                <td>ass-1</td>
                <td>
                    <div className='d-flex'>
                    <button className='btn btn-success'>view</button>
                    <button className='btn btn-success mx-2'>Download</button>
                    <input type="number" className='form-control w-25' placeholder='Enter Grade '/>
                    <button className='btn btn-success mx-2'>Add</button>
                    <button className='btn btn-danger btn-sm'><i class="bi bi-trash"></i></button>
                    <button className='btn btn-success ms-2 btn-sm'><i class="bi bi-pencil-square"></i></button>
                    </div>

                </td>
            </tr>
            <tr>
                <td>1.</td>
                <td>aaaa</td>
                <td>ass-1</td>
                <td>
                    <div className='d-flex'>
                    <button className='btn btn-success'>view</button>
                    <button className='btn btn-success mx-2'>Download</button>
                    <input type="number" className='form-control w-25' placeholder='Enter Grade '/>
                    <button className='btn btn-success mx-2'>Add</button>
                    <button className='btn btn-danger btn-sm'><i class="bi bi-trash"></i></button>
                    <button className='btn btn-success ms-2 btn-sm'><i class="bi bi-pencil-square"></i></button>
                    </div>

                </td>
            </tr>
            </tbody>
        </table>
      </div>
      </div>
}
    </div>
  )
}
