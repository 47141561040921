import {UPCOMING_WORKSHOP_REQUEST,UPCOMING_WORKSHOP_SUCCESS,UPCOMING_WORKSHOP_FAIL} from "../constant/allCourses"

export const upcomingWorkshopReducer=(state={upcomingWorkshop:[]},{type,payload})=>{
    switch (type) {
        case UPCOMING_WORKSHOP_REQUEST:
            return {...state,isLoading:true}
        case UPCOMING_WORKSHOP_SUCCESS:
            return {isLoading:false,upcomingWorkshop:payload}
        case UPCOMING_WORKSHOP_FAIL:
            return {isLoading:false,upcomingWorkshoperror:payload}
        default:return state
    }
    }