import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import "../style/editSession.css"
import EduNavigationButtons from './EduNavigationButtons'
export default function EditSession() {
  const backToAddSessions=()=>{
    window.location.href = window.location.origin + "/addSessions";
  }
  return (
    <div>
    <Navbar/>
       <div className="container-fluid">
        <div className="row">
        <div className="col-2 sidebar-col text-light pb-0">
        <div className="d-flex flex-column align-items-end vh-100">
        <Link to="/dashBoardHome"><span className="mt-3 sidebar-item">Dashboard</span></Link>
              <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item">Edit Profile</span></Link>
              <Link to="/addSessions"><span className="mt-3 sidebar-item">Upload Courses</span></Link>
              <Link to="/myCourses"><span className="mt-3 sidebar-item">My Courses</span></Link>
              <Link to="/"><span className="mt-3 sidebar-item">My Sessions</span></Link>
        </div>
        <div className="footer-terms d-flex flex-column align-items-end">
          <span>
            <Link>Terms,</Link>
            <Link>Privacy Policy</Link>
          </span>
          <span>CoachX Rights Reserved</span>
        </div>
      </div>
      <div className="col-md-8 col-12 rightside-col ">

         {/* /////////////////offcanvas///////////////// */}
      <button className="btn btn-primary sidebar-btn" type="button" data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">sidebar</button>

      <div className="offcanvas offcanvas-start sidebar" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
        <div className="offcanvas-header">
          <button type="button" className="btn-close ms-auto text-light" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body ">
        <div className="d-flex flex-column align-items-end vh-100">
        <Link to="/dashBoardHome"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Dashboard</span></Link>
              <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Edit Profile</span></Link>
              <Link to="/addSessions" ><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Upload Courses</span></Link>
              <Link to="/myCourses"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">My Courses</span></Link>
              <Link to="/mySessions"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">My Sessions</span></Link>
        </div>
        </div>
      </div>

        <div className='row'>
        <div className='col-12 d-flex'>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
        </div>
        </div>
        <h1 className='additionalReqTitle'>Add Sessions</h1>
        <p className='addsession'>Add Sessions/ Edit</p>
        <p>Tell us more about Your Session</p>
        <div className='d-flex flex-column justify-content-center align-items-center w-100'>
          <div className='w-md-75 w-100 mt-3'>
            <input type="text" placeholder='Session 'className='form-control form-control-lg '/>
            <label htmlFor="" className='mt-2'>You are currently editing this session</label>
            </div>
            <div className='w-md-75 w-100 mt-3'>
            <input type="number" placeholder='0'className='form-control form-control-lg'/>
            <label htmlFor="">Duration it may take in minits</label>
            </div>
        <div className='mt-3 w-md-75 w-100'>
            <textarea className='form-control  w-100' name="" id="" cols="30" rows="3" placeholder='Session Take way: Key Points of the session (What will the outcome from this session'></textarea>
            <label htmlFor="" className='mt-2'>*Not Mandatory but will be helpful for students to understand more</label>
        </div>
        <EduNavigationButtons/>
        </div>
        </div>
        </div>
       </div>
</div>
  )
}
