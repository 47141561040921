import React, { useState } from 'react'
import EduAssignmentCourses from './EduAssignmentCourses'
import EduAssignmentList from './EduAssignmentList'
import EduAssignmentStuList from './EduAssignmentStuList'

export default function EduAssignmentGradeBatches({OverviewComp}) {
    const [comp, setcomp] = useState("")
    const handleTestGrades=(para)=>{
      setcomp(para)
    }
    // back btn logic
    const [back, setBack] = useState("")
    const handleBack=(para)=>{
      setcomp("testGrades")
      setBack(para)
      console.log(back);
    }
  return (
    <div>
    {comp === "testGrades" ?
     <div>
     {
       back === "EduAssignmentCourses"
       ?<EduAssignmentCourses/>
       :<EduAssignmentList OverviewComp={OverviewComp}/>
     }
     </div>
  : <div>
      <h1 className="backbtn2">
          <i className="backbtn bi bi-arrow-left-circle-fill" onClick={()=>handleBack("EduAssignmentCourses")}></i>
      </h1>
<h2>Batches</h2>
<div className="table-responsive">
<table className="table table-success table-striped table-bordered">
   <thead>
   <tr className='table-primary'>
       <th className="tableSrNo">Sr.No.</th>
       <th>Baches</th>
   </tr>
   </thead>
   <tbody>
   <tr onClick={()=>handleTestGrades("testGrades")}>
       <td>1.</td>
       <td>Batch-1</td>
   </tr>
   <tr onClick={()=>handleTestGrades("testGrades")}>
       <td>1.</td>
       <td>Batch-1</td>
   </tr>
   </tbody>
</table>
</div>
</div>
}
</div>
  )
}
