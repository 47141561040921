import {RESEND_REQUEST,RESEND_SUCCESS,RESEND_FAIL} from "../constant/loginConstant"
export const resendOtpReducer=(state={newOtp:{}},{type,payload})=>{
    switch (type) {
        case RESEND_REQUEST:
            return {...state,isLoading:true}
        case RESEND_SUCCESS:
            return {isLoading:false,newOtp:payload}
        case RESEND_FAIL:
            return {isLoading:false,newOtperror:payload}
        default:return state
    }
    }