import React, { useState } from "react";
import video from "../../images/video.png";
import pluse from "../../images/pluse.png";
import AdminPanelCourses from "./AdminPanelCourses";
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function CourseDetailsAdminPanel() {
  // back btn logic
  const [back, setBack] = useState("");
  const handleBack = (para) => {
    setBack(para);
  };
  return (
    <div>
      {
         back === "AdminEducator"
         ?<AdminPanelCourses/>
         :<div className="row mt-5 ">
         <h1 className="backbtn">
               <i
                 className="backbtn bi bi-arrow-left-circle-fill"
                 onClick={() => handleBack("AdminEducator")}
               ></i>
         </h1>
           <div className="col-sm-6 col-12 mt-5 mt-sm-0">
             <div className="row mb-5">
               <div className="col-6 offset-3 d-flex flex-column justify-content-center align-items-center">
                 <LazyLoadImage src={video} alt="video" className="EducatorProfile" />
                 <h5 className="mt-2">UserName</h5>
                 <span>+918888888888</span>
               </div>
             </div>
   
             <div className="row">
               <p className="Introvideo">
                 Introduction Video <LazyLoadImage src={pluse} alt="pluse" />
               </p>
               <div className="mb-2">
                 <h6>Course Description</h6>
                 <p>
                   Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad unde
                   autem incidunt.
                 </p>
               </div>
               <div className="mb-2">
                 <h6>Pre-requisite</h6>
                 <p>
                   Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad unde
                   autem perspiciatis{" "}
                 </p>
               </div>
               <p>
                 <span className="courseParaMeter">Course Domain: </span>
                 <span>Data Science</span>
               </p>
               <p>
                 <span className="courseParaMeter">Course Type: </span>
                 <span>Course</span>
               </p>
               <p>
                 <span className="courseParaMeter">Course Language: </span>
                 <span>English</span>
               </p>
               <p>
                 <span className="courseParaMeter">Course Level: </span>
                 <span>Beginner</span>
               </p>
               <p>
                 <span className="courseParaMeter">Course Target Age Group: </span>
                 <span>Graduate,Students,working Profesionals</span>
               </p>
               <p>
                 <span className="courseParaMeter">Number of Sessions: </span>
                 <span>32</span>
               </p>
               <p>
                 <span className="courseParaMeter">Course Fees: </span>
                 <span>30000</span>
               </p>
               <p>
                 <span className="courseParaMeter">Course Discount: </span>
                 <span>30000</span>
               </p>
             </div>
           </div>
           <div className="col-sm-6 col-12">
             <div className="batchInfo">
               <p>
                 <span className="courseParaMeter">Batch 1 </span>
               </p>
               <p>
                 <span className="courseParaMeter">Batch Time: </span>
                 <span>8:00pm</span>
               </p>
               <p>
                 <span className="courseParaMeter">Batch Size: </span>
                 <span>10</span>
               </p>
               <p>
                 <span className="adminBatches">22-10-22</span>
                 <span className="adminBatches">23-10-22</span>
                 <span className="adminBatches">24-10-22</span>
               </p>
               
             </div>
             <hr />
           <h5>Session 1</h5>
               <p>
                 <span className="courseParaMeter">Batch Time: </span>
                 <span>8:00pm</span>
               </p>
               <span>Pre-requisit</span>
               <p>Session Takeaway</p>
               <hr />
           <h5>Session 10</h5>
           <p>
                 <span className="courseParaMeter">Batch Time: </span>
                 <span>8:00pm</span>
               </p>
               <span>Pre-requisit</span>
               <p>Session Takeaway</p>
               <hr />
               <h5>Session 11</h5>
           <p>
                 <span className="courseParaMeter">Batch Time: </span>
                 <span>8:00pm</span>
               </p>
               <span>Pre-requisit</span>
               <p>Session Takeaway</p>
           </div>
         </div>
      }
    </div>
  );
}
