import React from 'react'
import Avatar from '@material-ui/core/Avatar';

export default function Announcement() {
  return (
    <div>
        <div className="card">
            <div className="card-body">
            <div className="row">
        <div className="col-2">
            <Avatar>N</Avatar>
        </div>
        <div className="col-10 p-0">
                <h4>Name</h4>
                <span>2 hrs ago</span>
                <h6>Todays class is cancelled</h6>
        </div>
            </div>
            </div>
        </div>
    </div>
  )
}
