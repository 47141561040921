import axios from "axios"
import {EXPLORE_COURSES_REQUEST,EXPLORE_COURSES_SUCCESS,EXPLORE_COURSES_FAIL} from "../constant/exploreCoursesConst"
export const ExploreCoursesAction=()=>async(dispatch)=>{
    try {
        dispatch({type:EXPLORE_COURSES_REQUEST})
    const {data}=await axios.get("/course/filter/category/list")
    console.log(data.data)
    dispatch({type:EXPLORE_COURSES_SUCCESS,payload:data.data})
    } catch (error) {
        dispatch({type:EXPLORE_COURSES_FAIL, payload:error})
    }
}
