import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import "../style/EducatorDashboardEditProfile.css";
import video from "../images/video.png";
import pluse from "../images/pluse.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { eduProfileAction } from "../Action/eduProAction";
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function EducatorDashboardEditProfile() {
  const [dis, setDis] = useState("");
  const [eduProCredentials, seteduProCredentials] = useState({});
  const dispatch = useDispatch();
  const [user_info, setuser_info] = useState({});
  const [fullName, setfullName] = useState("");
  const [dateOfBirth, setdateOfBirth] = useState();
  const [summary, setsummary] = useState();
  const [languages, setlanguages] = useState([]);
  const [teaching_domains, setteaching_domains] = useState([]);
  const [id_proof, setid_proof] = useState({});
  const [profile_video, setprofile_video] = useState("");
  const [highest_qualification, sethighest_qualification] = useState();
  const [certificates, setcertificates] = useState([]);
  const [profile_pic, setprofile_pic] = useState("");

  const redirectToSuccessPage = () => {
    window.location.href = window.location.origin + "/profileApprovel";
  };

  const handleSubmit = (e) => {
    var tokenDetails = JSON.parse(localStorage.getItem("UserData"));
    e.preventDefault();
    dispatch(
      eduProfileAction({
        user_info: {
          first_name: fullName,
          date_of_birth: dateOfBirth,
          user_type: "E",
          id: tokenDetails?.id,
        },
        profile_info: {
          certificates: [
            "u/e/f95f1763-0986-4811-aab4-b83e98bc45af/certificate0",
          ],
          highest_qualification: highest_qualification,
          id_proof: {
            file: "u/e/f95f1763-0986-4811-aab4-b83e98bc45af/Pancard",
            type: "Pancard",
          },
          languages: languages,
          summary: summary,
          profile_video: "u/e/f95f1763-0986-4811-aab4-b83e98bc45af/iv",
          teaching_domains: teaching_domains,
          profile_pic: "u/e/f95f1763-0986-4811-aab4-b83e98bc45af/pp",
        },
      })
    );
  };

  return (
    <div>
      <Navbar />
      <div className="container-fluid">
        <div className="row ">
          <div className="col-2 sidebar-col text-light pb-0">
            <div className="d-flex flex-column align-items-end vh-100">
              <Link to="/dashBoardHome">
                <span className="mt-3 sidebar-item">Dashboard</span>
              </Link>
              <Link to="/educatorDashboardEditProfile">
                <span className="mt-3 sidebar-item">Edit Profile</span>
              </Link>
              <Link to="/addSessions">
                <span className="mt-3 sidebar-item">Upload Courses</span>
              </Link>
              <Link to="/myCourses">
                <span className="mt-3 sidebar-item">My Courses</span>
              </Link>
              <Link to="/mySessions">
                <span className="mt-3 sidebar-item">My Sessions</span>
              </Link>
            </div>
            <div className="footer-terms d-flex flex-column align-items-end">
              <span>
                <Link>Terms,</Link>
                <Link>Privacy Policy</Link>
              </span>
              <span>CoachX Rights Reserved</span>
            </div>
          </div>
          <div className="col-md-8 col-12 d-flex rightside-col justify-content-center">
            <div className="row">
              <div className="col-md-4 offset-md-4 d-flex justify-content-center flex-column align-items-center">
                <div className="profilePicture-wrapper">
                  <LazyLoadImage src={video} alt="video" className="profilePicture" />
                  <div className="input-group mb-">
                    <input
                      type="file"
                      className="d-none"
                      id="profilePic"
                      onChange={(e) => setprofile_pic(e.target.value)}
                    />
                    <label className="input-group-text pluse" for="profilePic">
                      <LazyLoadImage src={pluse} alt="pluse" className="pluseImg" />
                    </label>
                  </div>
                </div>
                <span className="mt-2">Change Profile Picture</span>
              </div>
              <div className="col-md-10 offset-md-1 p-0">
                <form>
                  <div className="row profile-form">
                    <div className="col-md-12  my-2  px-5 ">
                      <input
                        type="text"
                        className="form-control sameWidthInput w-100 m-auto"
                        onChange={(e) => setfullName(e.target.value)}
                        placeholder="Full Name"
                      />
                    </div>
                    <div className="col-md-6 col-12 my-2 p-0">
                      <input
                        type="date"
                        className="form-control sameWidthInput m-auto w-75"
                        onChange={(e) => setdateOfBirth(e.target.value)}
                        placeholder="date of birth"
                      />
                    </div>
                    <div className="col-md-6 col-12 my-2 p-0 ">
                      <input
                        type="number"
                        className="form-control sameWidthInput w-75 m-auto"
                        placeholder="Phone Number"
                      />
                    </div>
                    <div className="col-md-12 my-2 px-5">
                      <input
                        type="email"
                        placeholder="Email ID"
                        className="form-control sameWidthInput w-100 m-auto"
                      />
                    </div>

                    <div className="col-12 my-2 px-5">
                      <textarea
                        className="form-control"
                        name=""
                        id=""
                        cols="30"
                        rows="3"
                        placeholder="About your self"
                        onChange={(e) => setsummary(e.target.value)}
                      ></textarea>
                      {/* <ReactQuill theme='snow' className='w-100 m-auto' placeholder="Enter Your Description"/> */}
                      <br />
                    </div>
                    <div className="col-md-6  my-2 p-0">
                      <select
                        className="form-select sameWidthInput m-auto w-75"
                        onChange={(e) =>
                          setlanguages([...languages, e.target.value])
                        }
                      >
                        <option selected>Language Known</option>
                        <option value="Hindi">Hindi</option>
                        <option value="English">English</option>
                        <option value="Marathi">Marathi</option>
                      </select>
                    </div>
                    <div className="col-md-6 my-2 p-0">
                      <select
                        className="form-select sameWidthInput m-auto w-75"
                        onChange={(e) =>
                          setteaching_domains([
                            ...teaching_domains,
                            e.target.value,
                          ])
                        }
                      >
                        <option selected>Teaching Domain</option>
                        <option value="Technical">Technical</option>
                        <option value="Career">Career</option>
                        <option value="music">music</option>
                      </select>
                    </div>
                    <div className="col-md-6 my-2 p-0">
                      <div className="uploadId-wrapper m-auto w-75">
                        <div className="uploadId-inner">
                          <span className="uploadId-heading">
                            Upload Your ID
                          </span>
                          <input
                            type="file"
                            id="uploadId"
                            className="form-control d-none m-auto w-75"
                            onChange={(e) =>
                              setid_proof({ ...id_proof, file: e.target.value })
                            }
                            placeholder="id"
                          />
                          <label For="uploadId">
                            <LazyLoadImage src={pluse} alt="pluse" />
                          </label>
                        </div>
                        <span className="accepted-documents">
                          (Accepted Documents:Adhar Cad, Pan Card)
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 my-2 p-0">
                      <div className="video-pitch-wrapper m-auto w-75">
                        <div className="video-pitch-inner">
                          <span className="videoPitch-heading">
                            Video Pitch
                          </span>
                          <input
                            type="file"
                            id="videopitch"
                            className="form-control d-none m-auto w-75"
                            onChange={(e) => setprofile_video(e.target.value)}
                            placeholder="Video Pitch"
                          />
                          <label For="videopitch">
                            <LazyLoadImage src={pluse} alt="pluse" />
                          </label>
                        </div>
                        <span className="limit-text">
                          (Up to 1min, Upto 200MB, Support Only MP4)
                        </span>
                        <p className="video-desc">
                          Lorem, ipsum dolor sit amet consectetur adipisicing
                          elit.Lorem, ipsum dolor sit amet consectetur
                          adipisicing elit.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 my-2 p-0">
                      <select
                        className="form-select sameWidthInput m-auto w-75"
                        placeholder="qualificatiion"
                        onChange={(e) =>
                          sethighest_qualification(e.target.value)
                        }
                      >
                        <option selected>Heighest Education</option>
                        <option value="B.E">B.E</option>
                        <option value="BCA">BCA</option>
                        <option value="BSC">BSC</option>
                      </select>
                    </div>
                    <div className="col-md-6 my-2 p-0">
                      <div className="sameWidthInput upload-certificate m-auto w-75">
                        <div className="certificate-inner">
                          <span className="certificate-heading">
                            Upload Your Certificates
                          </span>
                          <input
                            type="file"
                            // id="videopitch"
                            className="form-control d-none m-auto w-75"
                            onChange={(e) =>
                              setcertificates(...certificates, e.target.value)
                            }
                            placeholder="certificates"
                          />
                          <label For="videopitch">
                            <LazyLoadImage src={pluse} alt="pluse" />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 my-2 p-0">
                      <div className="m-auto w-100"></div>
                    </div>
                    <div className="col-md-6 col-12 my-md-5  btn-wrapper d-md-flex align-items-center justify-content-center">
                      <button className="submit-btn btn w-50" onClick={handleSubmit}>
                        UPDATE
                      </button>
                    </div>
                    <div className="col-md-6 col-12 btn-wrapper my-md-5  d-md-flex align-items-center">
                      <Link to="/">
                        <button className="submit-btn submit-btn-2 btn ">
                          CANCEL
                        </button>
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
