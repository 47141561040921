import React, { useState } from 'react'
import SettingComp1 from './SettingComp1'

export default function SettingComp2() {
    const [comp, setcomp] = useState("")
    const handleAttendence=(para)=>{
      setcomp(para)
    } 
  return (
    <div>
    {
        comp ==="SettingComp1"
        ?<SettingComp1/>
        :<div>
    <div className="row">
        <div className="col-6 offset-3 d-flex">
        <div className="stuStatus1 me-1"></div>
        <div className="stuStatus1"></div>
        </div>
    </div>
        <h4 className="backbtn2">Settings</h4>
        <br/>
        <form action="">
        <div className='row'>
        <div className="col-md-6 col-12">
        <div className="mb-2">
        <label htmlFor="">Enter Work Location Preferrences</label><br />
        <input type="text" placeholder="Enter Location Preferrences seperated by ','" className='form-control'/>
        </div>
        <div className='mb-2'>
            <label htmlFor="">Total Years of Exp.</label>
            <input type="number" placeholder="Enter Industry Name"   className='form-control'/>
        </div>
        <div className='mb-2'>
            <label htmlFor="">Current City</label>
            <input type="text" placeholder="Enter Current City"   className='form-control'/>
        </div>
        </div>
        <div className="col-md-6 col-12">
        <div className='mb-2'>
            <label htmlFor="">Current Organization</label>
            <input type="text" placeholder="Enter Organization Name" className='form-control '/>
        </div>
        <div className='mb-2'>
            <label htmlFor="">LinkedIn URL</label>
            <input type="text" placeholder="Enter Your LinkedIn URl" className='form-control'/>
        </div>
        <div className='mb-2'>
            <label htmlFor="">About Me</label>
            <textarea name="" id="" cols="30" rows="1" placeholder="Enter Something" className='form-control'></textarea>
        </div>
        </div>
        <div className="col-12 col-md-6 offset-md-3 my-3">
        <button className='btn btn-success  me-2' onClick={()=>handleAttendence("SettingComp1")}>Back</button>
        <button className='btn btn-success'>Update</button>
        </div>
        </div>
        </form>
    </div>
    }
</div>
  )
}
