import {React, useState} from "react";
import rayzorpay from "../../images/RazorpayImg.png";
import rupee from "../../images/rupee.png";
import { useSelector, useDispatch} from "react-redux";

function Paymentdetail() {
  const { courseDetails, isLoading } = useSelector((state) => state.course);
  const [ShowFeesStructure, setShowFeesStructure] = useState(false);
  const courseFeesStructure = [
    "Complete Data Analytics Course",
    "Professional Certificate Program in Data Science",
    "AWS Certified Cloud Practitioner - Complete Course",
    "Professional Certificate Program in  Business Analytics",
  ];
  const handleshowFeesStructure = () => {
    setShowFeesStructure((prev) => !prev);
  };
  return (
    <div className="paymentdetails">
      <div className="courseFee payent-option">
        <div className="row">
          {/* <div className="col-md-6 d-flex"> */}
          <div className="col-12 ms-lg-4 d-flex justify-content-between flex-column flex-lg-row">
            <div>
              <h2>Payment Option</h2>
              <p className="mt-3">For One-Time payment users we are having following Options</p>
              <img
                effect="blur"
                src={rayzorpay}
                width="200"
                className="razorpayImg"
                alt="rayzorpay"
              />
            </div>
            <div className="d-flex  flex-column">
              <div>
                <span className="course-heighlights-inner-headings fw-bold fs-md-3 fs-9 me-1">
                  <img effect="blur" src={rupee} alt="rupee" /> Course Price:
                </span>
                {courseDetails?.courseData?.discount == 0 ? (
                  <span className="discount-price ">Free</span>
                ) : (
                  <>
                    <span className="discount-price mt-3 fs-md-3 fs-9">
                      {courseDetails?.courseData?.discounted_fees}
                    </span>
                    <span className="original-price mt-1 fs-md-3 fs-9">
                      {courseDetails?.courseData?.fees}
                    </span>
                  </>
                )}
              </div>
              <div className="mt-3">
                <p>Internet Banking, Debit/Credit cards,Upi payments</p>
                <p>
                  <strong>Get upto 30% OFF</strong>, How? Contact us to know.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-evenly align-items-center flex-column">
            <div>
              {/* <span className="course-heighlights-inner-headings fw-bold fs-md-3 fs-9">
                          <img effect="blur" src={rupee} alt="rupee" /> Course
                          Price:
                        </span> */}
              {/* {courseDetails?.discount == 0 ? (
                          <span className="discount-price ">Free</span>
                        ) : (
                          <>
                            <span className="discount-price mt-3 fs-md-3 fs-9">
                              {courseDetails?.discount}
                            </span>
                            <span className="original-price mt-1 fs-md-3 fs-9">
                              {courseDetails?.fees}
                            </span>
                          </>
                        )} */}
            </div>
            {/* <a href="https://rzp.io/l/Uj7dsVRRPM" target="_blank">
                      <button
                        className="courseFee-btn"
                        // onClick={redirectToPaymentPage}
                      >
                        Book Now
                      </button>
                      </a> */}
          </div>
        </div>

        <div className="d-lg-flex reg5k justify-content-between">
          <div className="courseFee  Scholorship d-flex flex-column justify-content-center align-items-center">
            <a href="https://rzp.io/l/Uj7dsVRRPM" target="_blank">
              <button className="scholarshipBtn ">Register Now With 5k</button>
            </a>
          </div>

          <div className="courseFee Scholorship d-flex flex-column justify-content-center align-items-center">
            <a href="https://rzp.io/l/Uj7dsVRRPM" target="_blank">
              <button className="scholarshipBtn bookNowNavColor">Book Now</button>
            </a>
          </div>
          {courseFeesStructure.includes(courseDetails?.courseData?.title) && (
            <div className="courseFee Scholorship d-flex flex-column justify-content-center align-items-center">
              {/* <a href="https://rzp.io/l/Uj7dsVRRPM"> */}
              <button className="scholarshipBtn btn5k" onClick={handleshowFeesStructure}>
                Click Here To Know More
              </button>
              {/* </a> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Paymentdetail;
