import React from "react";
import "../../style/navAllCourses.css";
import { useSelector } from "react-redux";
import NavAllCourses1 from "./NavAllCourses1";
import exploreCoursesIcon from "../../images/exploreCoursesIcon.png";
export default function NavAllCourses() {
  const { exploreCourses } = useSelector((state) => state.exploreCatagory);

  //   Redirect to course details page

  const redirectToCourseDetails = (id, title) => {
    window.open(`/live?${title.replaceAll(" ", "-")}=` + id, "_blank");
  };

  return (
    <div>
      <button
        className="exploreCourseBtn"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasWithBothOptions"
        aria-controls="offcanvasWithBothOptions"
      >
                   <img
                    src={exploreCoursesIcon}
                    alt="exploreCoursesIcon"
                    className="dot"
                    width={15}
                  />All courses
      </button>

      <div
        className="offcanvas widthForAcc offcanvas-start"
        data-bs-scroll="true"
        tabindex="-1"
        id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel"
      >
        <div className="offcanvas-header text-light">
          <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">
            Explore Courses
          </h5>
          <button
            type="button"
            className="btn-close crossbtn"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          {/* Course List  */}
          <NavAllCourses1/>
          {/* {exploreCourses?.data &&
            exploreCourses?.data.map((tech, i) => (
              <div className="accordion " id="accordionExample">
                <div className="accordion-item ">
                  <h2 className="accordion-header ">
                    <button
                      className="accordion-button accCourse"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${i}`}
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {tech?.category}
                    </button>
                  </h2>

                  <div
                    id={`collapse${i}`}
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <ul>
                        {tech?.courses.map((course, i) => (
                          <li
                            onClick={() =>
                              redirectToCourseDetails(course.id, course.title)
                            }
                          >
                            {" "}
                            {course?.title}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}
        </div>
      </div>
    </div>
  );
}
