import {
  PLACEMENT_LIST_REQUEST,
  PLACEMENT_LIST_SUCCESS,
  PLACEMENT_LIST_FAIL,
} from "../constant/placementListConst";

export const placementListReducer = (state = { stuList: {} }, { type, payload }) => {
  switch (type) {
    case PLACEMENT_LIST_REQUEST:
      return { ...state, isLoading: true };
    case PLACEMENT_LIST_SUCCESS:
      return { isLoading: false, stuList: payload };
    case PLACEMENT_LIST_FAIL:
      return { isLoading: false, stuListerror: payload };
    default:
      return state;
  }
};
