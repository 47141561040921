import React from "react";
import Services from "./Services";
import servicesList from "../services/services.json";
import "../services/services.css";

function ServicesList() {
  return (
    <div className="services-list">
      <div className="header">
        <h4>Empower your employees with the Latest Skillset Trends!!</h4>
      </div>
      <Services data={servicesList} />
    </div>
  );
}

export default ServicesList;
