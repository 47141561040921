import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { getAllCoursesReducer } from "./reducers/allCoursesReducers";
import { courseDetailsReducer } from "./reducers/courseDetailsReducer";
import { upcomingCoursesReducer } from "./reducers/upcomingCourses";
import { upcomingWorkshopReducer } from "./reducers/upcomingWorkshop";
import { catagoryReducer } from "./reducers/catagoryReducer";
import { videoSliderReducer } from "./reducers/videoSliderReducer";
import { logInReducer } from "./reducers/loginReducer";
import { verifyOTPReducer } from "./reducers/verifyOTPReducer";
import { CreateSignUpReducer } from "./reducers/createSignUpReducer";
import { SignUpVerifyReducer } from "./reducers/signUpVerifyReducer";
import { getTokenReducer } from "./reducers/getTokenReducer";
import { personalInfoReducer } from "./reducers/personalInfo";
import { resendOtpReducer } from "./reducers/resendOtpReducer";
import { educatorInfoReducer } from "./reducers/educatorInfoReducer";
import { requestCallReducer } from "./reducers/requestCallReducer";
import { nextReducer } from "./reducers/nextReducer";
import { prevReducer } from "./reducers/prevReducer";
import { privacyPolicyReducer } from "./reducers/privacyPolicyReducer";
import { tersReducer } from "./reducers/termsReducer";
import { exploreCoursesReducer } from "./reducers/exploreCoursesReducer";
import { eduProfileReducer } from "./reducers/eduProReducer";
import { addCourseReducer } from "./reducers/addCourseReducer";
import { userSignInReducer } from "./reducers/userSignInReducers";
import { userSignInVerifyReducer } from "./reducers/userSignInVerifyReducer";
import { UserCouponReducer } from "./reducers/userCouponReducer";
import { placementListReducer } from "./reducers/placementListReducer";
import { feedbackReducer } from "./reducers/feedbackReducer";

const rootReducer = combineReducers({
  allcourses: getAllCoursesReducer,
  course: courseDetailsReducer,
  upcomingcourse: upcomingCoursesReducer,
  upcoingWorkshop: upcomingWorkshopReducer,
  catagories: catagoryReducer,
  sliederVideo: videoSliderReducer,
  userLogin: logInReducer,
  verifyOtp: verifyOTPReducer,
  signUp: CreateSignUpReducer,
  signUpVerify: SignUpVerifyReducer,
  getToken: getTokenReducer,
  profile: personalInfoReducer,
  NewOtp: resendOtpReducer,
  token: getTokenReducer,
  educatorInfo: educatorInfoReducer,
  reqCall: requestCallReducer,
  refreshToken: personalInfoReducer,
  nextData: nextReducer,
  prev: prevReducer,
  privacyPolicy: privacyPolicyReducer,
  terms: tersReducer,
  exploreCatagory: exploreCoursesReducer,
  eduPro: eduProfileReducer,
  newCourse: addCourseReducer,
  userSigned: userSignInReducer,
  userSignInVerified: userSignInVerifyReducer,
  coupon: UserCouponReducer,
  placementList: placementListReducer,
  feedback: feedbackReducer,
});

const store = createStore(rootReducer, {}, composeWithDevTools(applyMiddleware(thunk)));

export default store;
