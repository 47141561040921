import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import EduAssignment from './EduAssignment';
import TestGradeBatches from './TestGradeBatches';

export default function StuGrades() {
  const [createTest, setCreateTest] = useState(false)
  // back btn logic
  const [back, setBack] = useState("");
  const handleBack = (para) => {
    setBack(para);
  };
  return (
    <div>
      {
      back === "EduAssignment"
      // ?<EduAssignment/>
      ?<TestGradeBatches/>
      :<div> 
      <h1 className="backbtn2">
        <i
          className="backbtn bi bi-arrow-left-circle-fill"
          onClick={() => handleBack("EduAssignment")}
        ></i>
      </h1>
      <div className="d-flex justify-content-end align-items-end">
      <button className='btn btn-success' onClick={() => setCreateTest(true)}>Create New Test</button>
    </div>
    <h4>Test Grades</h4>
    <br/>
      <div className="table-responsive">
        <table className="table table-success table-striped table-bordered">
            <thead>
              <tr className='table-primary'>
                  <th>Sr.No.</th>
                  <th>Name</th>
                  <th>Test 1</th>
                  <th>Test 2</th>
                  <th>Test 3</th>
                  <th>Test 4</th>
                  <th>Grades</th>
                  <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td>1.</td>
                <td>aaaa</td>
                <td>10/9</td>
                <td>10/9</td>
                <td>10/9</td>
                <td>10/9</td>
                <td>40/36</td>
                <td>
                  <button className='btn btn-success me-2 btn-sm'><i class="bi bi-pencil-square"></i></button>
                  <button className='btn btn-danger btn-sm'><i class="bi bi-trash"></i></button>
                </td>
            </tr>
            <tr>
                <td>2.</td>
                <td>bbb</td>
                <td>10/9</td>
                <td>10/9</td>
                <td>10/9</td>
                <td>10/9</td>
                <td>40/36</td>
                <td>
                  <button className='btn btn-success me-2 btn-sm'><i class="bi bi-pencil-square"></i></button>
                  <button className='btn btn-danger btn-sm'><i class="bi bi-trash"></i></button>
                </td>
            </tr>
            </tbody>
        </table>
      </div>
    
      {/* Create test model */}
      <Modal backdrop="static" show={createTest}>
        <button
          type="button"
          className="close-btn ms-auto mt-2 me-2"
          aria-label="Close"
          onClick={() => setCreateTest(false)}
        ></button>
        <Modal.Body closeButton>
         <form action="">
         <div className='mb-3'>
            <label htmlFor="">Select Batch</label>
          <select class="form-select" aria-label="Default select example">
            <option selected>Open this select menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
          </div>
          <div className='mb-3'>
            <label htmlFor="">Test Name</label>
            <input type="text"  className="form-control" placeholder='Enter Test Name'/>
          </div>
          <div className='mb-3'>
            <label htmlFor="">Google form Link</label>
            <input type="text"  className="form-control" placeholder='Google form Link'/>
          </div>
          <button className='btn btn-info w-100'>Create</button>
         </form>
        </Modal.Body>
      </Modal>
      </div>
      }
  </div>
  )
}
