import {CATAGORY_REQUEST,CATAGORY_SUCCESS,CATAGORY_FAIL} from "../constant/catagoryConstant"

export const catagoryReducer=(state={category:[]},{type,payload})=>{
    switch (type) {
        case CATAGORY_REQUEST:
            return {...state,isLoading:true}
        case CATAGORY_SUCCESS:
            return {isLoading:false,category:payload}
        case CATAGORY_FAIL:
            return {isLoading:false,categoryError:payload}
        default:return state
    }
    }