export const ALL_DATA_REQUEST = "ALL_DATA_REQUEST";
export const ALL_DATA_SUCCESS = "ALL_DATA_SUCCESS";
export const ALL_DATA_FAIL = "ALL_DATA_FAIL";

export const COURSE_DETAILS_REQUEST = "COURSE_DETAILS_REQUEST";
export const COURSE_DETAILS_SUCCESS = "COURSE_DETAILS_SUCCESS";
export const COURSE_DETAILS_FAIL = "COURSE_DETAILS_FAIL";

export const UPCOMING_COURSE_REQUEST = "UPCOMING_COURSE_REQUEST";
export const UPCOMING_COURSE_SUCCESS = "UPCOMING_COURSE_SUCCESS";
export const UPCOMING_COURSE_FAIL = "UPCOMING_COURSE_FAIL";

export const UPCOMING_WORKSHOP_REQUEST = "UPCOMING_WORKSHOP_REQUEST";
export const UPCOMING_WORKSHOP_SUCCESS = "UPCOMING_WORKSHOP_SUCCESS";
export const UPCOMING_WORKSHOP_FAIL = "UPCOMING_WORKSHOP_FAIL";

