import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import "../style/additionalReqi.css"
import EduNavigationButtons from './EduNavigationButtons'

export default function AdditionalReqi() {
  const [course, setcourse] = useState({})
// console.log(location.course);
useEffect(() => {
  const localData= localStorage.getItem("eduData")
  setcourse(JSON.parse(localData))
  localStorage.removeItem("eduData")
}, [])
console.log(course);
  
  const redirectToCourseFee=()=>{
    localStorage.setItem("eduData",JSON.stringify(course))
    window.location.href = window.location.origin + "/courseFee";
  }
  const redirectToBack=()=>{
    window.location.href = window.location.origin + "/educatorAboutCourse";
  }
  return (
    <div>
           <Navbar/>
           <div className="container-fluid">
            <div className="row">
            <div className="col-2 sidebar-col text-light pb-0">
            <div className="d-flex flex-column align-items-end vh-100">
              <Link to="/dashBoardHome"><span className="mt-3 sidebar-item">Dashboard</span></Link>
              <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item">Edit Profile</span></Link>
              <Link to="/addSessions"><span className="mt-3 sidebar-item">Upload Courses</span></Link>
              <Link to="/myCourses"><span className="mt-3 sidebar-item">My Courses</span></Link>
              <Link to="/"><span className="mt-3 sidebar-item">My Sessions</span></Link>
            </div>
            <div className="footer-terms d-flex flex-column align-items-end">
              <span>
                <Link>Terms,</Link>
                <Link>Privacy Policy</Link>
              </span>
              <span>CoachX Rights Reserved</span>
            </div>
          </div>
          <div className="col-md-8 col-12 rightside-col ">

 {/* /////////////////offcanvas///////////////// */}
            <button className="btn btn-primary sidebar-btn" type="button" data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">sidebar</button>
                  <div className="offcanvas offcanvas-start sidebar" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
                    <div className="offcanvas-header">
                      <button type="button" className="btn-close ms-auto text-light" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body ">
                    <div className="d-flex flex-column align-items-end vh-100">
                    <Link to="/dashBoardHome"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Dashboard</span></Link>
                          <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Edit Profile</span></Link>
                          <Link to="/addSessions" ><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Upload Courses</span></Link>
                          <Link to="/myCourses"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">My Courses</span></Link>
                          <Link to="/mySessions"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">My Sessions</span></Link>
                    </div>
                    </div>
                  </div>

            <div className='row'>
            <div className='col-12 d-flex justify-content-center'>
                <div className='statusBar activeStatus'></div>
                <div className='statusBar activeStatus'></div>
                <div className='statusBar'></div>
                <div className='statusBar'></div>
                <div className='statusBar'></div>
            </div>
            </div> 
            <h1 className='additionalReqTitle'>Additional Requirement</h1>

            <div className='d-flex flex-column justify-content-center align-items-center'>

                <input type="text" placeholder='Course Prerequisite'className='form-control form-control-lg w-md-75 w-100'
                onChange={(e)=>setcourse({...course,prerequisite:e.target.value})}
                 />

            <select className="form-select form-select-lg w-md-75 w-100 mt-3" aria-label="Default select example"
            onChange={(e)=>setcourse({...course,language:e.target.value})}
            >
              <option value="" selected disabled hidden>Teaching Language</option>
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
              <option value="Marathi">Marathi</option>
            </select>

            <select className="form-select form-select-lg w-md-75 w-100 mt-3" aria-label="Default select example"
              onChange={(e)=>setcourse({...course,level:e.target.value})}
            >
              <option value="" selected disabled hidden>Level of Training</option>
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Advanced">Advanced</option>
            </select>

            <select className="form-select form-select-lg w-md-75 w-100 mt-3" aria-label="Default select example"
              onChange={(e)=>setcourse({...course,age_grp:[e.target.value]})}
            >
              <option value="" selected disabled hidden>Targate Audiance</option>
              <option value="All">All</option>
              <option value="Graduate">Graduate</option>
              <option value="Student">Student</option>
              <option value="Working Profesionals">Working Profesionals</option>
              <option value="Home Makers">Home Makers</option>
            </select>
            <select className="form-select form-select-lg w-md-75 w-100 mt-3" aria-label="Default select example"
              onChange={(e)=>setcourse({...course,type:e.target.value})}
            >
              <option value="" selected disabled hidden>Course Type</option>
              <option value="Course">Course</option>
              <option value="Workshop">Workshop</option>
            </select>
            <div className='w-md-75 w-100 mt-3'>
                <input type="number" placeholder='Total Number of Sessions'className='form-control form-select-lg '
                  onChange={(e)=>setcourse({...course,session_count:e.target.value})}
                />
                <label htmlFor="" className='mt-2'>Please mention the number of courses required to complete the course</label>
            </div>
            <EduNavigationButtons/>
            </div>
            </div>
            </div>
           </div>
    </div>
  )
}
