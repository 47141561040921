import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../components/Navbar'
import succesfullPgae from "../images/succesfullLogo.png"
import "../style/successfullPage.css"
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function SuccessfullPage({location}) {

  return (
    <div>
    <Navbar />
       <div className="container-fluid">
        <div className="row">
        <div className="col-2 sidebar-col text-light pb-0">
        <div className="d-flex flex-column align-items-end vh-100">
        <Link to="/dashBoardHome"><span className="mt-3 sidebar-item">Dashboard</span></Link>
              <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item">Edit Profile</span></Link>
              <Link to="/addSessions"><span className="mt-3 sidebar-item">Upload Courses</span></Link>
              <Link to="/myCourses"><span className="mt-3 sidebar-item">My Courses</span></Link>
              <Link to="/mySessions"><span className="mt-3 sidebar-item">My Sessions</span></Link>
        </div>
        <div className="footer-terms d-flex flex-column align-items-end">
          <span>
            <Link>Terms,</Link>
            <Link>Privacy Policy</Link>
          </span>
          <span>CoachX Rights Reserved</span>
        </div>
      </div>
      <div className="col-8 rightside-col ">
        <div className='row'>
        <div className='col-12 d-flex justify-content-center'>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
        </div>
        </div>
        <div className='d-flex justify-content-center mt-5'>
            <LazyLoadImage src={succesfullPgae} alt="sp" />
        </div>
        <div className='d-flex justify-content-center mt-5'>
            <p className='successfullpara'>You Have Successfully Uploaded Your course!</p>
        </div>       
        <div className='d-flex justify-content-center mt-5'>
            <p className='successfullpara'>Note: Your course is now under review, once verified we will notify you immediately. Thanks for Choosing us.</p>
        </div>       
        </div>
        </div>
       </div>
</div>
  )
}
