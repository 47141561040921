import {NEW_COURSE_REQUEST,NEW_COURSE_SUCCESS,NEW_COURSE_FAIL} from "../constant/newCourseConst"
import axios from "axios"

export const addCourseAction=(Info)=>async(dispatch)=>{
    // var tokenDetails  = JSON.parse(localStorage.getItem("UserData")); 
    try {
        dispatch({type:NEW_COURSE_REQUEST})
        // axios.defaults.headers.common = {
        //     'Authorization': `Bearer ${tokenDetails.access}`,
        //     'DEVICE-UDID': ''
        // }
    const {data}=await axios.post("course/add/",Info)    
    console.log(data);
    dispatch({type:NEW_COURSE_SUCCESS,payload:data})
    } catch (error) {
        dispatch({type:NEW_COURSE_FAIL, payload:error.response.data})
    }
}