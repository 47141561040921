import axios from "axios"

import {GET_REQUEST,GET_SUCCESS,GET_FAIL} from "../constant/getTokenConst"

export const getTokenAction =(token)=>async(dispatch)=>{   
    let deviceUDID = localStorage.getItem("DEVICE-UDID");
    axios.defaults.headers.common = {
      'DEVICE-UDID': deviceUDID
    }
    try {
        dispatch({type:GET_REQUEST})
        const {data}=await axios.post("users/token/",token)         
        dispatch({type:GET_SUCCESS,payload:data.data.result})
    } catch (error) {
        dispatch({type:GET_FAIL, payload:error})
    }
}