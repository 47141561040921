import React, { useEffect, useState } from "react";
import "../style/allCourses.css";
import Footer from "../components/Footer";
import clock from "../images/clock.png";
import status from "../images/status.png";
import certificate from "../images/certificate.png";
import share from "../images/share.png";
import StartRating from "../components/StartRating";
import fire from "../images/fire.png";
import starIcon from "../images/starIcon.png";
import filter from "../images/filter.png";
import HomeIcon from "../images/HomeIcon.png";
import rightArrow from "../images/rightArrow.png";
import loader from "../images/loader.gif";
import iso from "../images/9k.png";
import whatsapp from "../images/whatsapp.png";
// material ui
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ContactUs from "../components/ContactUs";
import { Link, useLocation } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useDispatch, useSelector } from "react-redux";
import { allCoursesAction } from "../Action/allCoursesActions";
import moment from "moment";
import { catagoryAction } from "../Action/catagoryAction";
import { Modal } from "react-bootstrap";
import ClearIcon from "@mui/icons-material/Clear";
import DoubleArrowSharpIcon from "@material-ui/icons/DoubleArrowSharp";
import HomeCard from "../components/home/HomeCard";
import HomeCardItem from "../components/home/HomeCardItem";

// lazy loading
import { LazyLoadImage } from "react-lazy-load-image-component";
import RequestCallBack from "../components/RequestCallBack";

export default function AllCourses() {
  const [filterMenuCopy, setFilterMenuCopy] = useState([]);
  const [filterMenuValue, setFilterMenuValue] = useState([
    {
      label: "Course",
      value: "C",
      type: "type",
      isActive: false,
    },
    {
      label: "Workshop",
      value: "W",
      type: "type",
      isActive: false,
    },
    {
      label: "Beginner",
      value: "B",
      type: "level",
      isActive: false,
    },
    {
      label: "Intermediate",
      value: "I",
      type: "level",
      isActive: false,
    },
    {
      label: "Advanced",
      value: "E",
      type: "level",
      isActive: false,
    },
  ]);

  const [SearchCourse, setSearchCourse] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minPrice, setMinPrice] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setFilterMenuCopy(filterMenuValue);
  }, []);

  const dispatch = useDispatch();
  const { courses, isLoading } = useSelector((state) => state.allcourses);
  console.log(courses);
  const { category } = useSelector((state) => state.catagories);
  const [DisplayData, setDisplayData] = useState(courses);
  const [showFilter, setShowFilter] = useState(false);
  const [homeFilter, setHomeFilter] = useState(false);
  const [isReset, setIsReset] = useState(false); // used this for reset all filter

  useEffect(() => {
    dispatch(allCoursesAction(""));
    dispatch(catagoryAction());
  }, []);

  const search = useLocation().search;
  const course_type = new URLSearchParams(search).get("course");
  const category_filter = new URLSearchParams(search).get("category");
  const price_filter = new URLSearchParams(search).get("price");
  const search_course = new URLSearchParams(search).get("searchKey");

  // home filter
  useEffect(() => {
    if (course_type !== "") {
      if (course_type == "C") {
        filterMenuHandleChange(0, true);
        setHomeFilter(true);
      }
      if (course_type == "W") {
        filterMenuHandleChange(1, true);
        setHomeFilter(true);
      }
    }
  }, [course_type]);

  useEffect(() => {
    if (homeFilter == true) {
      Filter();
    }
  }, [filterMenuValue, maxPrice]);

  useEffect(() => {
    if (category_filter != "" && category_filter != null) {
      setSelectedCategory(category_filter.replace("%20", " "));
    }
  }, [category_filter]);

  useEffect(() => {
    if (price_filter) {
      setMaxPrice(price_filter);
      setHomeFilter(true);
    }
  }, [price_filter]);

  //search

  useEffect(() => {
    if (search_course != "" && search_course != null) {
      setSearchCourse(search_course);
      setHomeFilter(true);
    }
  }, [search_course]);

  ////////// search btn logic //////////

  const redirectToCourseDetails = (id, title) => {
    // window.open("/courseDetail?course_info=" + id, "_blank");
    window.open(`/live?${title.replaceAll(" ", "-")}=` + id, "_blank");
  };

  useEffect(() => {
    setDisplayData(courses);
  }, [courses]);

  useEffect(() => {
    if (SearchCourse != "") {
      Filter();
    }
  }, [SearchCourse]);

  useEffect(() => {
    Filter();
  }, [selectedCategory]);

  const Filter = (navigation) => {
    setFilterMenuCopy(filterMenuValue);
    setIsReset(false);
    setHomeFilter(false);
    var filterQuery = "";

    //1. checkbox data
    var filterMenuList = filterMenuValue.filter((x) => x.isActive == true);
    if (filterMenuList && filterMenuList.length > 0) {
      filterMenuList.map((x, index) => {
        filterQuery = filterQuery + x.type + "=" + x.value + "&";
      });
    }

    //2. search data
    if (SearchCourse != "") {
      filterQuery = filterQuery + "search=" + SearchCourse + "&";
    }
    //3. category data
    if (selectedCategory != "") {
      filterQuery = filterQuery + "search=" + selectedCategory + "&";
    }

    //min price
    if (minPrice != "") {
      filterQuery = filterQuery + "discount_min=" + minPrice + "&";
    }

    // max price
    if (maxPrice != "") {
      filterQuery = filterQuery + "discount_max=" + maxPrice + "&";
    }

    //4. page number
    var tempPageNumber = pageNumber;
    if (navigation == "prev") {
      tempPageNumber = tempPageNumber - 1;
      setPageNumber(tempPageNumber);
    } else if (navigation == "next") {
      tempPageNumber = tempPageNumber + 1;
      setPageNumber(tempPageNumber);
    } else {
      tempPageNumber = 1;
      setPageNumber(1);
    }
    filterQuery = filterQuery + "page=" + tempPageNumber + "&";
    //5. create query
    dispatch(allCoursesAction(filterQuery));
    setShowFilter(false);
  };

  const handleKeyDownSearch = (e) => {
    if (e.key == "Enter") {
      Filter();
    }
  };

  const [isClear, setIsClear] = useState(false);
  const clearSearch = () => {
    setSearchCourse("");
    setIsClear(true);
  };

  useEffect(() => {
    if (isClear) {
      Filter();
      setIsClear(false);
    }
  }, [isClear]);

  const shareLink = (link) => {
    window.open(link, "_blank");
  };

  const filterMenuHandleChange = (index, isActive) => {
    var tempFilterMenu = JSON.parse(JSON.stringify(filterMenuValue));
    tempFilterMenu[index].isActive = isActive;
    setFilterMenuValue(tempFilterMenu);
  };

  const resetFilter = () => {
    var tempFilterList = JSON.parse(JSON.stringify(filterMenuValue));
    tempFilterList.map((x) => (x.isActive = false));
    setFilterMenuValue(tempFilterList);
    setFilterMenuCopy(tempFilterList);
    setMaxPrice("");
    setMinPrice("");
    setShowFilter(false);
    setSearchCourse("");
    setSelectedCategory("");
    setIsReset(true);
  };

  useEffect(() => {
    if (isReset == true) {
      Filter();
    }
  }, [isReset]);

  const courseSpecificData = [
    {
      course: "Professional Certificate Program in Data Science",
      hours: "120 hrs",
      months: "5 Months",
      sessions: 48,
    },
    {
      course: "Professional Certificate Program in  Business Analytics",
      hours: "120 hrs",
      months: "5 Months",
      sessions: 48,
    },
    {
      course: "Complete Data Analytics Course",
      hours: "100 hrs",
      months: "4 Months",
      sessions: 40,
    },
    {
      course: "AWS Certified Cloud Practitioner - Complete Course",
      hours: "100 hrs",
      months: "4 Months",
      sessions: 40,
    },
  ];

  return (
    <div>
      {/* ////////breadcrum/////////// */}
      <div className="courses-topbar">
        <div className="container">
          <div className="breadcrum-navigation-wrapper">
            <Link to="/" className="link">
              <LazyLoadImage src={HomeIcon} alt="HomeIcon" />
              <span className="mx-2 breadcrum-home">Home</span>
            </Link>
            <LazyLoadImage src={rightArrow} alt="rightArrow" />
            <span className="mx-2 breadcrum-courses">Courses</span>
          </div>
          <div className="row">
            <div className="col-md-10 search-input">
              <Paper
                sx={{
                  display: "flex",
                  background: "#FDFDFD",
                  boxShadow: "0px 0px 0px 0px",
                  border: "1px solid #E4E4E7",
                  borderRadius: "8px",
                  alignItems: "center",
                  height: "58px",
                  width: "100%",
                }}
              >
                <InputBase
                  className=""
                  sx={{ ml: 2, flex: 1 }}
                  inputProps={{ "aria-label": "search google maps" }}
                  value={SearchCourse}
                  placeholder={"Search Courses"}
                  onChange={(e) => setSearchCourse(e.target.value)}
                  onKeyPress={handleKeyDownSearch}
                />
                {SearchCourse && (
                  <IconButton type="button" className="searchIcon-btn" onClick={clearSearch}>
                    <ClearIcon />
                  </IconButton>
                )}
                <IconButton
                  type="button"
                  className="searchIcon-btn"
                  aria-label="search"
                  onClick={Filter}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
            <div className="col-md-2">
              <button className="filter-btn" onClick={() => setShowFilter(true)}>
                <div className=" filter-content">
                  <LazyLoadImage
                    src={filter}
                    className="filter-icon align-self-center"
                    width={25}
                    height={25}
                    alt="filter"
                  />
                  <span className="filter-text align-self-center">Filters</span>
                  <span className="filter-count align-self-center">4 X</span>
                  <div className="">
                    <i className="filter-rightIcon bi bi-arrow-right"></i>
                  </div>
                </div>
              </button>
            </div>
          </div>
          {/* -------------Category Tab ------------------------------------------
          ------------------------------- */}

          <div className="row tabs-wrapper">
            <div className="col-md-12 col-sm-6 tabs-inner-wrapper">
              {/* <Box sx={{ color: "#FDFDFD" }}>
                <Tabs
                  value={"tab-" + 1}
                  sx={{ color: "#063343" }}
                  // onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  className="tabs"
                > */}
              {/* <Tab
                    onClick={() => setSelectedCategory("")}
                    key={"tab-all"}
                    className={
                      selectedCategory == ""
                        ? "selected-category btn btn-dark tab"
                        : "btn btn-dark tab"
                    }
                    sx={{ color: "#063343", background: "#FDFDFD" }}
                    label={"All"}
                  />
                  {category?.map((tab, index) => (
                    <Tab
                      onClick={() => setSelectedCategory(tab)}
                      key={"tab-" + index}
                      className={
                        selectedCategory == tab
                          ? "selected-category btn btn-dark tab"
                          : "btn btn-dark tab"
                      }
                      sx={{ color: "#063343", background: "#FDFDFD" }}
                      label={tab}
                    />
                  ))} */}
              {/* </Tabs>
              </Box> */}
            </div>
          </div>
        </div>
      </div>

      {/*////////////////// course list/////////////// */}

      {isLoading ? (
        <div className="loader mt-0">
          <img src={loader} alt="loader" />
        </div>
      ) : (
        <div className="allcourses-wrapper">
          <div className="container">
            <div className="contactUs-wrapper">
              {/* <ContactUs /> */}
              {/* <RequestCallBack/> */}
            </div>
            {/* <div className="allCoursesWpChat">
        <a href="https://wa.me/message/YIUPH6AA6FGUF1" target="_black"> <LazyLoadImage src={whatsapp} width={50} className="me-2 wpChatImg" alt="" /></a>
        </div> */}
            <div className="course-heading-wrapper mt-0">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <span className="popular-course">
                    <LazyLoadImage src={fire} width={20} alt="fire" /> Popular Courses
                  </span>
                  {/* <span className="new-course">
                    <LazyLoadImage src={starIcon} width={20} alt="starIcon" /> New Courses
                  </span> */}
                </div>
              </div>
              <div>
                <div className="dark-line"></div>
                <hr className="thin-line" />
              </div>
            </div>
            <div className="row">
              {DisplayData &&
                DisplayData.map((course, index) => (
                  <div
                    key={"course-" + index}
                    className={
                      course.title == "Data mastery with DA /DS /BA /PBI /Python/SQL"
                        ? "d-none"
                        : "col-md-6 col-xl-4"
                    }
                  >
                    <div className="card my-4">
                      {/* <img
                        src={iso}
                        alt="iso"
                        className="allCourses_card_iso"
                      /> */}
                      <div className="allCourses-card-img">
                        <img
                          src={course.course_image}
                          width="100%"
                          height="100%"
                          className="allcourses-card-img"
                          alt="mango"
                        />
                      </div>
                      <div className="card-img-overlay h-25 d-flex  justify-content-between">
                        <div className="overlay-wrapper">
                          <span className="tech-btn">{course.category}</span>
                        </div>
                        <div>
                          <img
                            src={share}
                            className="allCourses-share-img"
                            alt="share"
                            onClick={() =>
                              shareLink(
                                "https://api.whatsapp.com/send?text=" +
                                  "Hey! Checkout this amazing course " +
                                  course.title +
                                  " By " +
                                  course.edu_info.user_info.first_name +
                                  " on CoachX " +
                                  course.share_link
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="card-inner">
                        {course.title.length > 44 ? (
                          <h5 className="card-title title mx-2 my-2">
                            {course.title.slice(0, 44)}...
                          </h5>
                        ) : (
                          <h5 className="card-title title mx-2 my-2">{course.title}</h5>
                        )}
                        <div className="d-flex course-info flex-column mx-2 py-0">
                          {course.description.length > 70 ? (
                            <p className="my-3">{course.description.slice(0, 70)}...</p>
                          ) : (
                            <p className="my-3">{course.description}</p>
                          )}
                          {/* <span className="courseBy my-2">
                            Course by
                            <span className="trainer_name ms-1">
                              {course.edu_info.user_info.first_name}
                            </span>
                          </span> */}
                          <span className="my-2">
                            <StartRating avg_rating={course.avg_rating} />
                          </span>
                          <div className="d-flex my-2 justify-content-between">
                            <span className="course_features">
                              <img src={clock} width={20} alt="clock" />
                              {/* {
                                courseSpecificData.map((item)=>(item.course == course.title ? item.hours:"__" ))
                              } */}
                              {/* {moment(course.duration, "HH:mm:ss").format(
                                "H [H] m [M]"
                              )} */}
                              {course.duration} Hrs
                            </span>
                            <span width={20} className="course_features">
                              <img src={status} alt="status" />
                              {/* {
                                courseSpecificData.map((item)=>(item.course == course.title && item.months ))
                              } */}
                              {course.course_duration_in_months} Months
                              {/* {course.session_count} D
                              {course.type == "C" ? " Course" : " Workshop"} */}
                            </span>
                            <span width={20} className="course_features">
                              <img src={certificate} alt="certificate" />
                              Certificate
                            </span>
                          </div>
                          <button
                            className="btn book-btn align-self-center my-3 m w-100"
                            onClick={() => {
                              redirectToCourseDetails(course.id, course.title);
                            }}
                          >
                            Join Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              {/* <HomeCard
                btnName={"Join Now"}
                upcomingCourses={DisplayData?.results}
                course={"course"}
              /> */}
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary prev-btn mt-4"
                disabled={pageNumber <= 1 ? true : false}
                onClick={() => {
                  Filter("prev");
                  window.scrollTo(0, 0);
                }}
              >
                <span className="prev-arrow">
                  <DoubleArrowSharpIcon />
                </span>
                Previous
              </button>
              <button
                className="btn btn-primary next-btn ms-2 mt-4"
                disabled={DisplayData?.count / 10 < pageNumber}
                onClick={() => {
                  Filter("next");
                  window.scrollTo(0, 0);
                }}
              >
                Next
                <span>
                  <DoubleArrowSharpIcon />
                </span>
              </button>
            </div>
          </div>
        </div>
      )}

      {/*/////////////////// filter model /////////////////*/}

      <Modal
        show={showFilter}
        onHide={() => {
          setShowFilter(false);
          setFilterMenuValue(filterMenuCopy);
        }}
        backdrop="static"
        size="lg"
      >
        <button
          type="button"
          className="close-btn ms-auto mt-2 me-2"
          onClick={() => {
            setShowFilter(false);
            setFilterMenuValue(filterMenuCopy);
          }}
          aria-label="Close"
        ></button>
        <Modal.Body>
          <div className="row">
            <div className="col-md-4">
              <h4 className="filter-model-title">Content Type</h4>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    //value="start"
                    control={<Checkbox />}
                    label="Course"
                    checked={filterMenuValue[0].isActive}
                    onChange={() => filterMenuHandleChange(0, !filterMenuValue[0].isActive)}
                  />
                  <FormControlLabel
                    //value="bottom"
                    control={<Checkbox />}
                    label="Workshop"
                    checked={filterMenuValue[1].isActive}
                    onChange={() => filterMenuHandleChange(1, !filterMenuValue[1].isActive)}
                  />
                </FormGroup>
              </FormControl>
            </div>
            <div className="col-md-4">
              <h4 className="filter-model-title">Level</h4>
              <FormControl component="fieldset">
                <FormGroup aria-label="position">
                  <FormControlLabel
                    checked={filterMenuValue[2].isActive}
                    onChange={() => filterMenuHandleChange(2, !filterMenuValue[2].isActive)}
                    control={<Checkbox />}
                    label="Beginner"
                  />
                  <FormControlLabel
                    checked={filterMenuValue[3].isActive}
                    onChange={() => filterMenuHandleChange(3, !filterMenuValue[3].isActive)}
                    control={<Checkbox />}
                    label="Intermediate"
                  />
                  <FormControlLabel
                    checked={filterMenuValue[4].isActive}
                    onChange={() => filterMenuHandleChange(4, !filterMenuValue[4].isActive)}
                    control={<Checkbox />}
                    label="Advanced"
                  />
                </FormGroup>
              </FormControl>
            </div>
            <div className="col-md-4">
              <h4 className="filter-model-title">Price Range</h4>
              <div className="d-flex align-items-center ">
                <label htmlFor="" className="mt-2">
                  Min Price
                </label>
                <input
                  type="number"
                  className="form-control min-price "
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                />
              </div>
              <div className="d-flex align-items-center max-price-filter">
                <label htmlFor="" className="mt-2">
                  Max Price
                </label>
                <input
                  type="number"
                  className="form-control max-price "
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(e.target.value)}
                />
              </div>
            </div>
          </div>
          <button className="btn btn-primary mt-5" onClick={Filter}>
            Filter
          </button>
          <button className="btn btn-light mt-5 ms-4" onClick={() => resetFilter()}>
            Reset
          </button>
        </Modal.Body>
      </Modal>
      <Footer />
    </div>
  );
}
