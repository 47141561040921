import React from "react";
import StarIcon from "@mui/icons-material/Star";
import "../style/testionials.css"
import video from "../images/video.png";
import linkedin from "../images/linkedin.png";
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function TestimonialPage() {

  const feedback = [
    {
      profile_pic: video,
      rating: "4.8",
      review: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos rem delectus iste, nobis perferendis beatae illum ut voluptatum incidunt a dignissimos repellendus iusto nam explicabo. Libero voluptatem illum ut. Unde.",
      name:"lorem"
    },
    {
      profile_pic: video,
      rating: "5",
      review: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos rem delectus iste, nobis perferendis beatae illum ut voluptatum incidunt a dignissimos repellendus iusto nam explicabo. Libero voluptatem illum ut. Unde.",
      name:"lorem"
    },
    {
      profile_pic:video,
      rating: "4.8",
      review: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos rem delectus iste, nobis perferendis beatae illum ut voluptatum incidunt a dignissimos repellendus iusto nam explicabo. Libero voluptatem illum ut. Unde.",
      name:"lorem"
    },
  ];

  return (
    <div className="feedback-wrapper">
      <div className="container">
        <p className="feedback mt-5">Course Feedback</p>
        <div className="row">
          {feedback.map((feedback, index) => (
              <div className="col-sm-6 col-lg-4 col-10 offset-1 offset-md-0 mb-2 d-flex justify-content-center">
              <div className=" feedback-swiper-card">
                <div className="feedback-card-body">
                  <div className="feedback-slider-header d-flex justify-content-between align-items-center">
                    <LazyLoadImage
                      src={feedback.profile_pic}
                      className="feedback-slider-img"
                      alt="feedback.profile_pic"
                    />
                    <LazyLoadImage src={linkedin} alt="linkedin" className="linkedIn"/>
                    <span className="feedback-slider-rating">
                      {[...Array(Math.round(feedback.rating))].map(
                        (item, i) => (
                          <StarIcon key={i} sx={{ color: "#E7B008" }} />
                        )
                      )}
                      {feedback.rating}
                    </span>
                  </div>
                  <p className="feedback-name">{feedback.name}</p>
                  <p className="feedback-slider-para">
                    {feedback.review.slice(0, 150)}...
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
