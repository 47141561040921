import axios from "axios"
import {FEEDBACK_REQUEST,FEEDBACK_SUCCESS,FEEDBACK_FAIL} from "../constant/feedbackConst"
export const feedbackAction=()=>async(dispatch)=>{
    try {
        dispatch({type:FEEDBACK_REQUEST})
    const {data}=await axios.get("/feedback/list/")    
    console.log(data);
    dispatch({type:FEEDBACK_SUCCESS,payload:data.data.feedback_list})
    } catch (error) {
        dispatch({type:FEEDBACK_FAIL, payload:error})
    }
}