import {COURSE_DETAILS_REQUEST,COURSE_DETAILS_SUCCESS,COURSE_DETAILS_FAIL} from "../constant/allCourses"

export const courseDetailsReducer=(state={courseDetails:{}},{type,payload})=>{
    switch (type) {
        case COURSE_DETAILS_REQUEST:
            return {...state,isLoading:true}
        case COURSE_DETAILS_SUCCESS:
            return {isLoading:false,courseDetails:payload}
        case COURSE_DETAILS_FAIL:
            return {isLoading:false,courseDetailserror:payload}
        default:return state
    }
    }