import React from 'react'
import { isPlainObject } from '@mui/utils'
import { Link } from 'react-router-dom'
import Navbar from '../components/Navbar'
import db1 from "../images/db-1.png"
import db2 from "../images/db-2.png"
import db3 from "../images/db-3.png"
import db4 from "../images/db-4.png"
import addIcon from "../images/addIcon.png"
import "../style/dashboard.css"
//material ui
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function DashBoardHome() {
  const redirectToAboutCourse=()=>{
    window.location.href = window.location.origin + "/educatorAboutCourse";
  }
  return (
    <div>
    <Navbar/>
       <div className="container-fluid">
        <div className="row">
        <div className="col-2 sidebar-col text-light pb-0">
        <div className="d-flex flex-column align-items-end vh-100">
        <Link to="/dashBoardHome"><span className="mt-3 sidebar-item">Dashboard</span></Link>
              <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item">Edit Profile</span></Link>
              <Link to="/addSessions"><span className="mt-3 sidebar-item">Upload Courses</span></Link>
              <Link to="/myCourses"><span className="mt-3 sidebar-item">My Courses</span></Link>
              <Link to="/mySessions"><span className="mt-3 sidebar-item">My Sessions</span></Link>
        </div>
        <div className="footer-terms d-flex flex-column align-items-end">
          <span>
            <Link>Terms,</Link>
            <Link>Privacy Policy</Link>
          </span>
          <span>CoachX Rights Reserved</span>
        </div>
      </div>
      <div className="col-8 rightside-col">
        <div className="col-md-8">
          <div className='d-flex justify-content-evenly w-75'>
          <div className='courseComleted courseComleted-1'>
            <div className='d-flex justify-content-between'>
              <span>Courses Completed</span>
              <LazyLoadImage src={db4} alt="db4" />
            </div>
            <div className='d-flex justify-content-evenly align-items-center w-75'>
              <span className='coursesCompleted'>45</span>
              <span>+5 this month </span>
            </div>
          </div>
          <div className='courseComleted courseComleted-2'>
            <div className='d-flex justify-content-between'>
              <span>Courses Completed</span>
              <LazyLoadImage src={db2} alt="db2" />
            </div>
            <div className='d-flex justify-content-evenly align-items-center w-75'>
              <span className='coursesCompleted'>45</span>
              <span>+5 this month </span>
            </div>
          </div>
          </div>
          <div className='d-flex justify-content-evenly w-75'>
          <div className='courseComleted courseComleted-3 mt-5'>
            <div className='d-flex justify-content-between'>
              <span>Courses Completed</span>
              <LazyLoadImage src={db3} alt="db3" />
            </div>
            <div className='d-flex justify-content-evenly align-items-center w-75'>
              <span className='coursesCompleted'>45</span>
              <span>+5 this month </span>
            </div>
          </div>
          <div className='courseComleted courseComleted-4 mt-5'>
            <div className='d-flex justify-content-between'>
              <span>Courses Completed</span>
              <LazyLoadImage src={db1} alt="db1" />
            </div>
            <div className='d-flex justify-content-evenly align-items-center w-75'>
              <span className='coursesCompleted'>45</span>
              <span>+5 this month </span>
            </div>
          </div>
          </div>
        </div>
        <div className="col-md-4"></div>
        <div className='addIcon'><span>Add Course From Here <ArrowRightAltIcon/></span><LazyLoadImage src={addIcon} alt="addIcon" onClick={redirectToAboutCourse}/></div>
      </div>
      </div>
</div>
</div>
  )
}
