import React from "react";

import "../style/aboutUs.css"
import photo_1 from "../images/photoGallary-1.jpeg";
import photo_2 from "../images/photoGallary-2.jpeg";
import photo_3 from "../images/photoGallary-3.jpeg";
import photo_4 from "../images/photoGallary-4.jpeg";

export default function PhotoGallary() {
  return (
    <div className="container">
      <div className=" my-4">
        <h4 className="text-center aboutUs_features">Meet Our Dynamic Team</h4>
        <div className="hole-wrapper ">
          <div className="top-div">
            <img src={photo_4} alt="photo_4" width="100%"/>
          </div>
          <div className="lower-div">
            <div className="left-wrapper border">
              <img src={photo_1} alt="photo_1" width="100%" />
            </div>
            <div className="right-wrapper">
              <div className="right-upper  border">
                <img
                  src={photo_3}
                  alt="photo_3"
                /> 
              </div>
              <div className="right-lower">
                <img
                  src={photo_2}
                  alt="photo_2"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
