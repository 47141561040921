import React, { useState } from 'react'
import stuCourse from "../../images/stuCourses.png";
import myCoursesCalendar from "../../images/myCoursesCalendar.png";
import CourseRecording from './CourseRecording';
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function RecordedVideo() {
  const [comp, setcomp] = useState("")
const handleAttendence=(para)=>{
  setcomp(para)
}
// back btn logic
const [back, setBack] = useState("");
const handleBack = (para) => {
  setcomp("testGrades")
  setBack(para);
};
  return (
    <div>
       <div>
        {
        comp ==="recording"
        ?<CourseRecording/>
        :   <div>
        <h4>Recorded Courses</h4>
     <br/>
     <div className="row">
         <div className="col-md-6 col-lg-4 col-12 mb-3">
         <div className="card">
     <div className="">
     <LazyLoadImage
       src={stuCourse}
       width="100%"
       height="100%"
       alt="card img"
     />
     
     </div>
     <div className="">
       <h5 className="mx-2">Data analytics</h5>
       <p className='ms-2'><LazyLoadImage src={myCoursesCalendar} alt="myCoursesCalendar"/> <span>30-12-2022</span></p>
       <div className='d-flex justify-content-center'><button className='btn courseBtn my-3  w-75' onClick={()=>handleAttendence("recording")}>View</button></div>
     </div>
   </div>
         </div>
         <div className="col-md-6 col-lg-4 col-12 mb-3">
         <div className="card">
     <div className="">
     <LazyLoadImage
       src={stuCourse}
       width="100%"
       height="100%"
       alt="card img"
     />
     
     </div>
     <div className="">
       <h5 className="mx-2">Data analytics</h5>
       <p className='ms-2'><LazyLoadImage src={myCoursesCalendar} alt="myCoursesCalendar" /> <span>30-12-2022</span></p>
       <div className='d-flex justify-content-center'><button className='btn courseBtn my-3  w-75' onClick={()=>handleAttendence("recording")}>View</button></div>
     </div>
   </div>
         </div>
         <div className="col-md-6 col-lg-4 col-12 mb-3">
         <div className="card">
     <div className="">
     <LazyLoadImage
       src={stuCourse}
       width="100%"
       height="100%"
       alt="card img"
     />
     
     </div>
     <div className="">
       <h5 className="mx-2">Data analytics</h5>
       <p className='ms-2'><LazyLoadImage src={myCoursesCalendar} alt="myCoursesCalendar" /> <span>30-12-2022</span></p>
       <div className='d-flex justify-content-center'><button className='btn courseBtn my-3  w-75' onClick={()=>handleAttendence("recording")}>View</button></div>
     </div>
   </div>
         </div>
       </div>
       </div>
        }
    </div>
    </div>
  )
}
