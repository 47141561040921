import {VIDEO_SLIDER_REQUEST,VIDEO_SLIDER_SUCCESS,VIDEO_SLIDER_FAIL} from "../constant/videoSliderConstant"

export const videoSliderReducer=(state={videos:[]},{type,payload})=>{
    switch (type) {
        case VIDEO_SLIDER_REQUEST:
            return {...state,isLoading:true}
        case VIDEO_SLIDER_SUCCESS:
            return {isLoading:false,videos:payload}
        case VIDEO_SLIDER_FAIL:
            return {isLoading:false,videoserror:payload}
        default:return state
    }
    }