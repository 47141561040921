import React from 'react'
import "../style/thankYou.css"
import Footer from "../components/Footer.jsx"
import emoji_happy from "../images/emoji-happy.png"
import { Link } from 'react-router-dom'
// lazy loading
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function ThankYou() {
  return (
    <div>
        <div className="thankYouWrapper">
           <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="thankYou-middle-wrapper">
                        <div className="thankYou-inner-wrapper">
                                <LazyLoadImage src={emoji_happy} alt="emoji_happy" />
                                <h3 className='thankYou-heading'>Thank you for submitting your details</h3>
                                <p className='thankYou-para'>Thank you for submitting your details, we will contact you shortly. </p>
                               <a href="https://coachxsession.web.app/"><button className='done-btn'>Done</button>     
                               </a>
                        </div>
                    </div> 
                </div>
            </div>
           </div>
        </div>
        <Footer/>
    </div>
  )
}
