import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import BatchRecordig from './BatchRecordig';

export default function BatchInfo() {
const [createTest, setCreateTest] = useState(false)
// back btn logic
const [back, setBack] = useState("");
const handleBack = (para) => {
  setBack(para);
};
  return (
    <div>
      {
         back === "ManageBatch"
         ?<BatchRecordig/>
         :<div>
        <h1 className="backbtn2">
            <i
              className="backbtn bi bi-arrow-left-circle-fill"
              onClick={() => handleBack("ManageBatch")}
            ></i>
        </h1>
        <h2>Course Name</h2>
        <div className="">
            <button className="btn btn-success ms-auto my-4" onClick={()=>setCreateTest(true)}>Add Student</button>
        </div>
        <div className="table-responsive mt-5">
        <table className="table table-success table-striped table-bordered">
          <thead>
          <tr className='table-primary'>
              <th className='tableSrNo'>Sr.No.</th>
              <th>Student Name</th>
              <th>Email Id</th>
              <th>Mobile No.</th>
              <th>Actions</th>
          </tr>
          </thead>
          <tbody>
          <tr>
              <td>1.</td>
              <td>aaa</td>
              <td>a@gmail.com</td>
              <td>8888888888</td>
              <td>
                <button className='btn btn-success btn-sm me-2'><i class="bi bi-pencil-square"></i></button>
                <button className='btn btn-danger btn-sm'><i class="bi bi-trash"></i></button>
              </td>
          </tr>
          <tr>
              <td>2.</td>
              <td>bbb</td>
              <td>b@gmail.com</td>
              <td>8888888888</td>
              <td>
                <button className='btn btn-success me-2 btn-sm'><i class="bi bi-pencil-square"></i></button>
                <button className='btn btn-danger btn-sm'><i class="bi bi-trash"></i></button>
              </td>
          </tr>
          </tbody>
      </table>
        </div>
            {/* Create test model */}
      <Modal backdrop="static" show={createTest}>
        <button
          type="button"
          className="close-btn ms-auto mt-2 me-2"
          aria-label="Close"
          onClick={() => setCreateTest(false)}
        ></button>
        <Modal.Body closeButton>
         <form action="">
         <div className='mb-3'>
            <label htmlFor="">Select Batch</label>
          <select class="form-select" aria-label="Default select example">
            <option selected>Open this select menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
          </div>
          <div className='mb-3'>
            <label htmlFor="">Student Name</label>
            <input type="text"  className="form-control" placeholder='Enter Student Name'/>
          </div>
          <div className='mb-3'>
            <label htmlFor="">Email Id</label>
            <input type="email"  className="form-control" placeholder='Enter Email Id'/>
          </div>
          <div className='mb-3'>
            <label htmlFor="">Mobile No.</label>
            <input type="email"  className="form-control" placeholder='Enter Mobile No.'/>
          </div>
          <button className='btn btn-info w-100'>Add</button>
         </form>
        </Modal.Body>
      </Modal>
      </div>
      }
    </div>
  )
}
