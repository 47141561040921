import {PREV_ACTION_REQUEST,PREV_ACTION_SUCCESS,PREV_ACTION_FAIL} from "../constant/nextAndPrevConst"
export const prevReducer=(state={prev:{}},{type,payload})=>{
    switch (type) {
        case PREV_ACTION_REQUEST:
            return {...state,isLoading:true}
        case PREV_ACTION_SUCCESS:
            return {isLoading:false,prev:payload}
        case PREV_ACTION_FAIL:
            return {isLoading:false,prev:payload}
        default:return state
    }
    }