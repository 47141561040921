import React from "react";

export default function Support() {
  return (
    <div className="backbtn3">
      <div className="row">
        <h4>Add Query</h4>
        <div className="col-10 offset-1">
          <form action="">
            <div className="mb-3">
              <label htmlFor="">Ticket Title</label>
              <input
                type="text"
                placeholder="Ticket Title"
                className="form-control"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="">Ticket Title</label>
              <select class="form-select" aria-label="Default select example">
                <option value="Payment">Payment</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="">Ticket Title</label>
              <textarea
                name=""
                id=""
                cols="30"
                rows="3"
                className="form-control"
                placeholder="Add Your Query"
              ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="">Add Attachment</label>
              <input
                type="file"
                placeholder="Add Attachment"
                className="form-control"
              />
            </div>
            <button className="btn btn-info w-100 text-light fs-4">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
