import React, { useState } from 'react'
import stuCourse from "../../images/stuCourses.png";
import myCoursesCalendar from "../../images/myCoursesCalendar.png";
import NotesList from './NotesList';

export default function EduNotes() {
  const [comp, setcomp] = useState("")
  const handleAttendence=(para)=>{
    setcomp(para)
  }
  return (
    <div>
    <div className="row">
      <h2>Upload Notes </h2>
      <div className="col-8 offset-2">
        <form action="">
          <div className="mb-2">
            <label htmlFor="">Select Course</label>
            <select class="form-select" aria-label="Default select example">
              <option selected value="1">
                One
              </option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className="mb-2">
            <label htmlFor="">Select Batch</label>
            <select class="form-select" aria-label="Default select example">
              <option selected value="1">
                One
              </option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className="mb-2">
            <label htmlFor="">upload Notes</label>
            <input type="file" className='form-control' />
          </div>
          <div className="my-2">
            <button className="btn btn-info w-100">Submit</button>
          </div>
        </form>
      </div>
    </div>
    <hr />
        <div className="row">
            <h4>Total(1)</h4>
            <div className="col-12">
            <div className='table-responsive'>
            <table className='table table-light table-hover'>
              <thead>
              <tr>
                  <th>Sr. No.</th>
                  <th>Course</th>
                  <th>Batch</th>
                  <th>Ass</th>
                  <th>Actions</th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>Course</td>
                  <td>Batch-1</td>
                  <td>Note-1</td>
                  <td>
                  <button className='btn btn-success me-2 btn-sm'><i class="bi bi-pencil-square"></i></button>
                <button className='btn btn-danger btn-sm'><i class="bi bi-trash"></i></button>
                  </td>

                </tr>
                </tbody>
            </table>
          </div>              
            </div>
        </div>
  </div>
  )
}
