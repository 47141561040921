import React, { useState } from "react";
import video from "../../images/video.png";
import pluse from "../../images/pluse.png";
import AdminEducator from "./AdminEducator";
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function EducatorDetails() {
  // back btn logic
  const [back, setBack] = useState("");
  const handleBack = (para) => {
    setBack(para);
  };
  return (
    <div>
      {back === "AdminEducator" ? (
        <AdminEducator />
      ) : (
        <div className="row mt-5">
          <h1 className="backbtn">
            <i
              className="backbtn bi bi-arrow-left-circle-fill"
              onClick={() => handleBack("AdminEducator")}
            ></i>
          </h1>
          <div className="col-sm-6 col-12 mt-4 mt-sm-0">
            <div className="row mb-5">
              <div className="col-6 offset-3 d-flex flex-column justify-content-center align-items-center">
                <LazyLoadImage src={video} alt="video" className="EducatorProfile" />
                <h5 className="mt-2">UserName</h5>
                <span>+918888888888</span>
              </div>
            </div>
            <div className="row">
              <div className="d-flex flex-column justify-content-center align-items-start mb-4">
                <h6>Date Of Birth</h6>
                <span>2000-07-22</span>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-start mb-4">
                <h6>About</h6>
                <span>Name</span>
              </div>
              <div className="d-flex flex-column justify-content-center align-items-start mb-4">
                <h6>Laanguage Known</h6>
                <span className="d-flex justify-content-center align-items-start mb-4">
                  <span className="languageBtn">Marathi</span>
                  <span className="languageBtn">English</span>
                </span>
              </div>
              <p className="Introvideo">
                Introduction Video <LazyLoadImage src={pluse} alt="pluse" />
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-12">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item educator-accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    Qualification & Certification
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingOne"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the first item's accordion body.
                  </div>
                </div>
              </div>
              <div class="accordion-item educator-accordion-item">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    ID Proof
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingTwo"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the second item's accordion body. Let's imagine this being
                    filled with some actual content.
                  </div>
                </div>
              </div>
              <div class="accordion-item educator-accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    Teaching Domain
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  class="accordion-collapse collapse"
                  aria-labelledby="flush-headingThree"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div class="accordion-body">
                    Placeholder content for this accordion, which is intended to
                    demonstrate the <code>.accordion-flush</code> class. This is
                    the third item's accordion body. Nothing more exciting
                    happening here in terms of content, but just filling up the
                    space to make it look, at least at first glance, a bit more
                    representative of how this would look in a real-world
                    application.
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <button className="btn btn-info me-2">Approve</button>
            <button className="btn btn-danger">Reject</button>
          </div>
        </div>
      )}
    </div>
  );
}
