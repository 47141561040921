import {EDUCATOR_PROFILE_REQUEST,EDUCATOR_PROFILE_SUCCESS,EDUCATOR_PROFILE_FAIL} from "../constant/eduProConstant"

export const eduProfileReducer=(state={eduPro:{}},{type,payload})=>{
    switch (type) {
        case EDUCATOR_PROFILE_REQUEST:
            return {...state,isLoading:true}
        case EDUCATOR_PROFILE_SUCCESS:
            return {isLoading:false,eduPro:payload}
        case EDUCATOR_PROFILE_FAIL:
            return {isLoading:false,eduPro:payload}
        default:return state
    }
    }