import React, { useEffect, useState } from "react";
import "../style/coupenCodeNavbar.css";
import CoupenCodeNavbar from "../components/CoupenCodeNavbar";
import coupenCode3 from "../images/coupenCode3.png";
import coupenCode4 from "../images/coupenCode4.png";
import coupenCode5 from "../images/coupenCode5.png";
import coupenCode6 from "../images/coupenCode6.png";
import coupenCode7 from "../images/coupenCode7.png";
import coupenCode8 from "../images/coupenCode8.png";
import coupenCode9 from "../images/coupenCode9.png";
import coupenCode10 from "../images/coupenCode10.png";
import coupenCode11 from "../images/coupenCode11.png";
import { useDispatch, useSelector } from "react-redux";
import { requestCallAction } from "../Action/requestCallAction";
import { useHistory, useLocation } from "react-router-dom";
import Footer from "../components/Footer";
// lazy loading
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";

export default function LeadForm() {
  const { courseDetails, isLoading } = useSelector((state) => state.course);
  const dispatch = useDispatch();
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [GraduationYear, setGraduationYear] = useState("");
  const search = useLocation().search;
  const course_info = new URLSearchParams(search).get("course_info");
  let query = `I Am Interested To Know More About ${courseDetails?.title}`;
  const { requestCall } = useSelector((state) => state.reqCall);

  const history = useHistory();

  const ListOfCourse = [
    {
      course: "Professional Certificate Program in Data Science",
      ID: 7,
    },
    {
      course: "Complete Data Analytics Course",
      ID: 1,
    },
    {
      course: "Professional Certificate Program in Business Analytics",
      ID: 8,
    },
    {
      course: "AWS Certified Cloud Practitioner - Complete Course",
      ID: 9,
    },
    {
      course: "Complete Tableau Training Program",
      ID: 1,
    },
    {
      course: "The Advanced SQL Course",
      ID: 1,
    },
    {
      course: "Python Django Certification Course",
      ID: 1,
    },
  ];

  const reqCallBackAction = () => {
    const courseId = ListOfCourse.find((item) => item.course == courseDetails?.title && item.ID);
    dispatch(
      requestCallAction({
        name,
        email,
        mobile: mobileNo,
        query,
        year_of_graduation: GraduationYear,
      })
    );

    // axios.post("https://thirdpartyapi.extraaedge.com/api/SaveRequest", {
    //   AuthToken: "COACHX-06-07-2023",
    //   Source: "coachx",
    //   FirstName: name,
    //   Email: email,
    //   MobileNumber: mobileNo,
    //   LeadSource: 22,
    //   LeadCampaign: "Website Leads",
    //   Course: courseId.ID,
    //   Center: "5",
    //   State: "",
    //   City: "",
    // });
  };

  useEffect(() => {
    if (requestCall.status == "0x0") {
      history.push("/thankYouForEnrollment?course_info=" + course_info);
    }
  }, [requestCall]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <CoupenCodeNavbar />
      <div className="coupenCode-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-lg-first order-last">
              <h3 className="coupenCodeHeading ">What You'll Get?</h3>
              <div>
                <p className="d-flex my-4">
                  <LazyLoadImage src={coupenCode3} alt="coupenCode3" />{" "}
                  <span className="couponCodeText ms-3">100% Placement Assistance</span>
                </p>
                <p className="d-flex my-4">
                  <LazyLoadImage src={coupenCode4} alt="coupenCode4" />
                  <span className="couponCodeText ms-3">
                    Career guidance from IIT Mumbai Technology expert
                  </span>
                </p>
                <p className="d-flex my-4">
                  <LazyLoadImage src={coupenCode5} alt="coupenCode5" />
                  <span className="couponCodeText ms-2">100% Live Training</span>
                </p>
                <p className="d-flex my-4">
                  <LazyLoadImage src={coupenCode6} alt="coupenCode6" />
                  <span className="couponCodeText ms-2">
                    Working Extensively On Real Time Projects
                  </span>
                </p>
                <p className="d-flex my-4">
                  <LazyLoadImage src={coupenCode7} alt="coupenCode7" />
                  <span className="couponCodeText ms-4">Advanced Curriculum</span>
                </p>
                <p className="d-flex my-4">
                  <LazyLoadImage src={coupenCode8} alt="coupenCode8" />
                  <span className="couponCodeText ms-3">Resume Building And Mock Interviews</span>
                </p>
                <p className="d-flex my-4">
                  <LazyLoadImage src={coupenCode7} alt="coupenCode7" />{" "}
                  <span className="couper2CodeText ms-4">
                    Convenient Batch Timings With All Recordings
                  </span>
                </p>
                <p className="d-flex my-4">
                  <LazyLoadImage src={coupenCode9} alt="coupenCode9" />
                  <span className="couponCodeText ms-2">
                    Quizzes, Assignments, and tests to evaluate your levels
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-6 order-lg-last order-first">
              <div className="coupenForm">
                <h4 className="formHeading text-center">Request Call Back</h4>
                <div>
                  <label className="leadLabel">Enter Name *</label>
                  <input
                    type="text"
                    className="form-control coupenInput3 mb-1"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="leadLabel">Enter WhatsApp no. *</label>
                  <input
                    type="number"
                    max={10}
                    className="form-control coupenInput3 mb-1"
                    placeholder="WhatsApp Number"
                    value={mobileNo}
                    onChange={(e) => setmobileNo(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="leadLabel">Enter email *</label>
                  <input
                    type="email"
                    className="form-control coupenInput3 mb-1"
                    placeholder="Email ID"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="leadLabel">Enter Graduation Year *</label>
                  <input
                    type="date"
                    className="form-control coupenInput3 mb-4 mb-1"
                    placeholder="year of graduation"
                    value={GraduationYear}
                    onChange={(e) => setGraduationYear(e.target.value)}
                    required
                  />
                </div>

                <input
                  type="text"
                  className="form-control coupenInput3 mb-4 mb-1"
                  value={query}
                  required
                />

                <div className="d-flex justify-content-center w-100">
                  <button className="book-now  mx-auto w-100" onClick={reqCallBackAction}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* /////// */}

          <div className="couponCode-certificate-wrapper">
            <div className="">
              <div className="row">
                <h3 className="certificate-title">
                  Yes! You will be certified once you complete this course
                </h3>
                <p className="certificate-para">
                  Do use this certificate to enhance your professional credibility and stand out
                  among your peers!
                </p>
                <div className="col-lg-10 col-md-12 offset-lg-1">
                  <div className="couponCode-certificate-frame">
                    <LazyLoadImage
                      src={
                        courseDetails?.courseData?.course_dummy_certificate == ""
                          ? courseDetails?.courseData?.dummy_certificate
                          : courseDetails?.courseData?.course_dummy_certificate
                      }
                      alt="no img"
                      className="couponCode-dummy-certificate"
                    />
                    {/* <LazyLoadImage
                      src={courseDetails?.dummy_certificate}
                      alt="no img"
                      className="couponCode-dummy-certificate"
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
