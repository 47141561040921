import {VERIFYOTP_REQUEST,VERIFYOTP_SUCCESS,VERIFYOTP_FAIL} from "../constant/loginConstant"

export const verifyOTPReducer=(state={OTPVerification:{}},{type,payload})=>{
    switch (type) {
        case VERIFYOTP_REQUEST:
            return {...state,isLoading:true}
        case VERIFYOTP_SUCCESS:
            return {isLoading:false,OTPVerification:payload}
        case VERIFYOTP_FAIL:
            return {isLoading:false,OTPVerification:payload}
        default:return state
    }
    }