import React from "react";
import "../style/mycoursesCard.css"
import myCourses from "../images/myCoursesImg.png";
import calender from "../images/myCoursesCalendar.png";
import timer from "../images/myCoursesTimer.png";
import whatsapp from "../images/whatsapp.png";
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function MyCourseCard() {
  return (
    <div className="container">
      {/* <div className="row mt-5"> */}
        {/* <div className="col-md-4 mt-5"> */}
          <div className="card">
            <LazyLoadImage
              src={myCourses}
              className="card-img-top"
              height="300"
              alt="myCourses"
            />
            <div className="card-body myCoursesCardBody">
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="myCoursesCard-title">course name</h5>
                    <button className="btn myCoursesShare"><LazyLoadImage src={whatsapp} alt="whatsapp" width={20} /> Share</button>
                </div>
              <p className="card-text mt-2">
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </p>
              <div className="d-flex justify-content-between">
                <span>
                  <LazyLoadImage src={calender} alt="calender"/> 28 july 2022
                </span>
                <span>
                  <LazyLoadImage src={timer} alt="timer"/> 4pm-5pm
                </span>
              </div>
              <div className="d-flex flex-column mt-2">
                <a href="#" className="btn myCoursesBtn">
                  EDIT
                </a>
                <a href="#" className="btn myCoursesBtn mt-2">
                  UNDER REVIEW
                </a>
              </div>
            </div>
          </div>
        {/* </div> */}
      {/* </div> */}
    </div>
  );
}
