import React from 'react'
import { isPlainObject } from '@mui/utils'
import { Link } from 'react-router-dom'
import Navbar from '../../components/Navbar'
import db1 from "../../images/db-1.png"
import db2 from "../../images/db-2.png"
import db3 from "../../images/db-3.png"
import db4 from "../../images/db-4.png"
import addIcon from "../../images/addIcon.png"
import settings from "../../images/Settings.png"
import overview from "../../images/Overview.png"
import courseIcon from "../../images/CoursesIcon.png"
import assignments from "../../images/Assignments.png"
import resume from "../../images/Resume.png"
import footer_logo from "../../images/footer_logo.png";
import video from "../../images/video.png";
import stuCourse from "../../images/stuCourses.png";
import myCoursesCalendar from "../../images/myCoursesCalendar.png";
import "../../style/studentDashboard.css"
//material ui
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function LiveClasses() {
    const redirectToAboutCourse=()=>{
        window.location.href = window.location.origin + "/educatorAboutCourse";
      }
  return (
   <div>
    <h4>Live Classes</h4>
      <br />
      <div className="row">
          <div className="col-md-6 col-lg-4 col-12 mb-3">
          <div className="card">
      <div className="">
      <LazyLoadImage
        src={stuCourse}
        width="100%"
        height="100%"
        alt="card img"
      />
      
      </div>
      <div className="">
        <h5 className="mx-2">Data analytics</h5>
        <p className='ms-2'><LazyLoadImage src={myCoursesCalendar} alt="myCoursesCalendar" /> <span>30-12-2022</span></p>
        <div className='d-flex justify-content-center'><button className='btn courseBtn my-3  w-75'>JOIN NOW</button></div>
      </div>
    </div>
          </div>
          <div className="col-md-6 col-lg-4 col-12 mb-3">
          <div className="card">
      <div className="">
      <LazyLoadImage
        src={stuCourse}
        width="100%"
        height="100%"
        alt="card img"
      />
      
      </div>
      <div className="">
        <h5 className="mx-2">Data analytics</h5>
        <p className='ms-2'><LazyLoadImage src={myCoursesCalendar} alt="myCoursesCalendar" /> <span>30-12-2022</span></p>
        <div className='d-flex justify-content-center'><button className='btn courseBtn my-3  w-75'>JOIN NOW</button></div>
      </div>
    </div>
          </div>
          <div className="col-md-6 col-lg-4 col-12 mb-3">
          <div className="card">
      <div className="">
      <LazyLoadImage
        src={stuCourse}
        width="100%"
        height="100%"
        alt="card img"
      />
      
      </div>
      <div className="">
        <h5 className="mx-2">Data analytics</h5>
        <p className='ms-2'><LazyLoadImage src={myCoursesCalendar} alt="myCoursesCalendar" /> <span>30-12-2022</span></p>
        <div className='d-flex justify-content-center'><button className='btn courseBtn my-3  w-75'>JOIN NOW</button></div>
      </div>
    </div>
          </div>
      </div>
   </div>
  )
}
