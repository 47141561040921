import {NEXT_ACTION_REQUEST,NEXT_ACTION_SUCCESS,NEXT_ACTION_FAIL} from "../constant/nextAndPrevConst"

export const nextReducer=(state={next:{}},{type,payload})=>{
    switch (type) {
        case NEXT_ACTION_REQUEST:
            return {...state,isLoading:true}
        case NEXT_ACTION_SUCCESS:
            return {isLoading:false,next:payload}
        case NEXT_ACTION_FAIL:
            return {isLoading:false,next:payload}
        default:return state
    }
    }