import React from "react";
import "../style/aboutUs.css";
import Footer from "../components/Footer.jsx";
import aboutUs_box_1 from "../images/aboutUs_box_1.png";
import feature_5 from "../images/feature_5.png";
import feature_6 from "../images/feature_6.png";
import autoCertificate from "../images/autoCertificate.png";
import batchManagement from '../images/batchManagement.png'
import ComfirtHome from "../images/ComfirtHome.png";
import status from "../images/status.png";
import group_68 from "../images/Group_68.png";
import group_69 from "../images/Group_69.png";
import group_70 from "../images/Group_70.png";
import whatsapp from '../images/whatsapp.png'
import { Link } from "react-router-dom";
import ContactUs from "../components/ContactUs";
import bhanu_sir from "../images/bhanu_sir.jpeg"
import renu from "../images/renu.jpeg";
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";
import RequestCallBack from "../components/RequestCallBack";
import PhotoGallary from "../components/PhotoGallary";

export default function AboutUs() {
  const features = [
    {
      img: status,
      desc: "Live Classes Where You Can Interact With Your Mentors ",
    },
    {
      img: ComfirtHome,
      desc: "Get Recording and Notes of The Classes for Easy Learning ",
    },
    {
      img: batchManagement,
      desc: "Real Classroom-like environment, Learn with your peers ",
    },
    {
      img: feature_5,
      desc: "Doubt Clearing Sessions, Real time assessments ",
    },
    {
      img: autoCertificate,
      desc: "Digital Certificates After The Completion of The Course ",
    },
    {
      img: feature_6, 
      desc: "Detailed learning paths & Complete Placement Assistance",
    },
  ];

  return (
    <div>
      <div className="aboutUs-header-main">
        <div className="contactUs">
          {/* <ContactUs /> */}
          {/* <RequestCallBack/> */}
        </div>
        {/* <div className="wpChat">
        <a href="https://wa.me/message/YIUPH6AA6FGUF1" target="_black"> 
          <LazyLoadImage src={whatsapp} width={50} className="me-2 wpChat" alt="whatsapp"  />
        </a>
        </div> */}
        <div className="container aboutUs-header-text">
          <span className="aboutUs-header-title">Why Choose CoachX?</span>
          <p className="aboutUs-header-para mt-3">
            CoachX is an online learning community where we offer a plethora of
            live classes that can equip you with the necessary skills for career
            growth. Remove all the hurdles with the right set of skills and
            unlock your true potential with CoachX. <br />
            <h3>
              We are here to empower you to do more and give wings to your
              ambitions.
            </h3>
          </p>
          <div className="row">
            <div className="col-lg-3 col-md-6 box-wrapper ps-0">
              <div className="aboutUs-header-box">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-2">
                    <LazyLoadImage 
                      src={aboutUs_box_1}
                      className="mb-3"
                      alt="aboutUs_box_1"  
                    />
                  </div> 
                  <div className="col-md-10">
                    <p className="box-para ps-3">
                      <span className="counter">1M+</span>{" "}
                      <span className="box-text">Learners Community</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 box-wrapper ps-0">
              <div className="aboutUs-header-box">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-2">
                    <LazyLoadImage 
                     src={aboutUs_box_1}
                     className="mb-3"
                     alt="aboutUs_box_2"
                    />
                  </div>
                  <div className="col-md-10">
                    <p className="box-para ps-3">
                      <span className="counter">500+</span>{" "}
                      <span className="box-text">Teachers at CoachX</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 box-wrapper ps-0">
              <div className="aboutUs-header-box">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-2">
                    <LazyLoadImage 
                     src={aboutUs_box_1}
                     className="mb-3"
                     alt="aboutUs_box_3"
                    />
                  </div>
                  <div className="col-md-10">
                    <p className="box-para ps-3">
                      <span className="counter">2500+</span>
                      <span className="box-text">Live Sessions</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 box-wrapper ps-0">
              <div className="aboutUs-header-box">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-md-2">
                     <LazyLoadImage 
                     src={aboutUs_box_1}
                     className="mb-3"
                     alt="aboutUs_box_1"
                    />
                  </div>
                  <div className="col-md-10">
                    <p className="box-para ps-3">
                      <span className="counter">100%</span>{" "}
                      <span className="box-text">Placement Assistance</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* why joining coachx */}
      <div className="why-coachx-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-md-6 ">
              <h3 className="why-coachx-title ">Your Live Learning Partner</h3>
              <p className="why-coachx-para">
                We all are lifelong learners and thirsty for knowledge. No
                matter where you are, you can access CoachX from any corner. We
                bring you popular and diverse courses that aid your curiosity
                and help you embark on a path of equipping yourself with a new
                set of skills.We believe everyone should get the opportunity to
                make progress by building the right skills of tomorrow. At
                CoachX, we are on a mission to create new opportunities for you
                by connecting you with the right experts.
              </p>
            </div>
            <div className="col-md-6">
              <div className="why-coachx-img">
                <LazyLoadImage src={group_68} className="why-coachx-img-1" alt="group_68" />
                <LazyLoadImage src={group_69} className="why-coachx-img-2" alt="group_69" />
                <LazyLoadImage src={group_70} className="why-coachx-img-3" alt="group_70" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* features  */}
      <div className="aboutUs_features_wrapper">
        <div className="container">
          <div className="row">
            <h3 className="aboutUs_features">Achieve Your Goals On CoachX</h3>
            {features.map((feature, index) => (
              <div key={index} className="col-md-6">
                <div className="feature_box">
                  <LazyLoadImage src={feature.img} width="25" alt="feature.img" />
                  <span className="feature_para">{feature.desc}</span>
                </div>
              </div>
            ))}
          </div>

          <h3 className="text-center my-4 aboutUs_features">OUR FOUNDING TEAM</h3>
          <div className="row our-founder-wrapper">
          <div className="col-12 col-md-5 offset-md-1 d-flex justify-content-center">
            <div>
            <div className="cofounderImg">
            <LazyLoadImage src={bhanu_sir} width="100%" alt="bhanu_sir"/>
            </div>
            <div className="cofounderImg mt-2">
            <h4 className="text-center">BHANU PRASAD</h4>
            <p className="text-center">Co-Founder</p>
            </div>
            </div>
          </div>
          <div className="col-12 order-sm-last order-first col-md-5 offset-md-1 d-flex justify-content-center">
            <div>
            <div className="cofounderImg">
              <LazyLoadImage src={renu} width="100%" alt="renu"/>
            </div>
            <div className="cofounderImg mt-2">
            <h4 className="text-center">RENU SALGARKAR</h4>
            <p className="text-center">Founder</p>
            </div>
            </div>
          </div>
          </div>

          {/* ================ photo gallary =============== */}
          <div>
            <PhotoGallary/>
          </div>
        </div>
      </div>
      {/* mentor section  */}
      <div className="mentor-main">
        <div className="container position-relative">
          <div className="aboutUs-mentor-text">
            <span className="share-knowledge">
              want to share your knowledge?
            </span>
            <h2 className="mentor-heading">Become a mentor</h2>
            <p className="mentor-para">
              Do you have any skill to teach? CoachX is your game-changer
              Partner, No upfront or hidden charges : You pay from what you
              earn. Apply Now!
            </p>
            <Link to="/BecomeEducator">
              {" "}
              <button className="btn enroll-btn">Apply Now</button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
