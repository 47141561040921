import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { addCourseAction } from '../Action/addCourseAction'
import Navbar from './Navbar'
import "../style/batch.css"
import EduNavigationButtons from './EduNavigationButtons'
export default function Batches() {

  const [batches, setbatches] = useState({})
  const [day, setday] = useState(false)
  const [index, setindex] = useState()
  const redirectToSuccessFullPage=()=>{
    window.location.href = window.location.origin + `/successfullPage`
  }
  const redirectToBack=()=>{
    window.location.href = window.location.origin + "/addSessions";
  }
  const weekdays=["M","TU","W","T","F","SA","S"]

  const handleWeekDays =(i)=>{
    setindex(i)
    index == i && setday(prev=>!prev)
  }
  
  const [ courseData,  setcourseData] = useState({
    batches
  })
  console.log(courseData);
  const dispatch =useDispatch()
  dispatch(addCourseAction(courseData))
  return (
    <div>
    <Navbar/>
       <div className="container-fluid">
       <div className="row">
        <div className="col-md-2 sidebar-col text-light pb-0">
        <div className="d-flex flex-column align-items-end vh-100">
        <Link to="/dashBoardHome"><span className="mt-3 sidebar-item">Dashboard</span></Link>
              <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item">Edit Profile</span></Link>
              <Link to="/addSessions"><span className="mt-3 sidebar-item">Upload Courses</span></Link>
              <Link to="/myCourses"><span className="mt-3 sidebar-item">My  Courses</span></Link>
              <Link to="/mySessions"><span className="mt-3 sidebar-item">My Sessions</span></Link>
        </div>
        <div className="footer-terms d-flex flex-column align-items-end">
          <span>
            <Link>Terms,</Link>
            <Link>Privacy Policy</Link>
          </span>
          <span>CoachX Rights Reserved</span>
        </div>
      </div>
      <div className="col-md-8 col-12 rightside-col ">
        {/* /////////////////offcanvas///////////////// */}
      <button className="btn btn-primary sidebar-btn" type="button" data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">sidebar</button>

      <div className="offcanvas offcanvas-start sidebar" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
        <div className="offcanvas-header">
          <button type="button" className="btn-close ms-auto text-light" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body ">
        <div className="d-flex flex-column align-items-end vh-100">
        <Link to="/dashBoardHome"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Dashboard</span></Link>
              <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Edit Profile</span></Link>
              <Link to="/addSessions" ><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Upload Courses</span></Link>
              <Link to="/myCourses"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">My Courses</span></Link>
              <Link to="/"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">My Sessions</span></Link>
        </div>
        </div>
      </div>
        <div className='row'>
        <div className='col-12 d-flex justify-content-center'>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
        </div>
        </div>
        <h1 className='additionalReqTitle'>Batches you want to conduct</h1>
        <p>Let’s Transform Learning Together</p>
        <p>Pro Tip: Plan your classes methodically and as per the audience demands</p>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <div className="row w-75">
            <div className="col-md-12 p-0">
              <input type="text" className='form-control form-control-lg mt-4' placeholder='Batch Name'
              onChange={(e)=>setbatches({...batches,title:e.target.value})}
              />
            </div>
            <div className='col-md-12 mt-4 d-flex align-items-center  text-center'>
              {weekdays.map((days,i)=>( 
                  <div className={day? 'CourseWeekdays daySelected':'CourseWeekdays'} 
                  onClick={(e)=>handleWeekDays(i)}>{days}</div>
              ))}
            </div>
            <div className="col-md-6 col-12 w-md-50 p-0">
              <input type="time" placeholder='Session start time'className='form-control form-control-lg  mt-4'
               onChange={(e)=>setbatches({...batches,start_date:e.target.value})}
              />
            </div>
            <div className="col-md-6 col-12 w-md-50 p-0">
              <input type="time" placeholder='Session end time'    className='form-control form-control-lg ms-md-1 mt-4'
               onChange={(e)=>setbatches({...batches,end_date:e.target.value})}
              />
            </div>
            <div className="col-md-6 col-12 w-md-50 p-0">
              <input type="date" className='form-control form-control-lg mt-4'
               onChange={(e)=>setbatches({...batches,start_date:e.target.value})}
              />
            </div>
            <div className="col-md-6 w-md-50 p-0">
              <input type="date" placeholder='Session end date' className='form-control form-control-lg ms-md-1 mt-4'
               onChange={(e)=>setbatches({...batches,end_date:e.target.value})}
              />
            </div>
          </div>
            <input type="number" placeholder='Batch Size(Total number of student who can join this one session)'className='form-control form-control-lg w-75 mt-4'
             onChange={(e)=>setbatches({...batches,size:e.target.value})}
            />
        <EduNavigationButtons/>
        </div>
        </div>
        </div>
       </div>
</div>
  )
}
