import React, { useState } from 'react'
import EduTestCourses from './EduTestCourses'
import EduTestGrade from './EduTestGrade'
import StuGrades from './StuGrades'
import TestGradeList from './TestGradeList'

export default function TestGradeBatches() {
    const [comp, setcomp] = useState("")
    
    const handleTestGrades=(para)=>{
        setcomp(para)
    } 
    // back btn logic
  const [back, setBack] = useState("");
  const handleBack = (para) => {
    setcomp("testGrades")
    setBack(para);
  };
  return (
    <div>
         {comp === "testGrades" ? 
         <div>
          {
          back === "EduTestCourses"
          ?<EduTestCourses/>
          :<StuGrades/>
          }
         </div>
       : <div>
        <h1 className="backbtn2">
        <i
          className="backbtn bi bi-arrow-left-circle-fill"
          onClick={() => handleBack("EduTestCourses")}
        ></i>
      </h1>
    <h2>Batch-1</h2>
    <div className="table-responsive">
    <table className="table table-success table-striped table-bordered">
        <thead>
        <tr className='table-primary'>
            <th className="tableSrNo">Sr.No.</th>
            <th>Baches</th>
        </tr>
        </thead>
        <tbody>
        <tr onClick={()=>handleTestGrades("testGrades")}>
            <td>1.</td>
            <td>Batch-1</td>
        </tr>
        <tr onClick={()=>handleTestGrades("testGrades")}>
            <td>1.</td>
            <td>Batch-1</td>
        </tr>
        </tbody>
    </table>
  </div>
  </div>
  }
</div>
  )
}
