import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import "../style/addSessions.css"
import sessionImg from "../images/sessionImg.png"
import editIcon from "../images/Edit_Icon.png"
import EduNavigationButtons from './EduNavigationButtons'
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function AddSessions({location}) {
  
const [courseData, setcourseData] = useState({})


// useEffect(() => {
//   setcourseData(location.course)
// }, [])
console.log(courseData);
console.log(courseData?.session_count);
  // const redirectToBatch=()=>{
  //   window.location.href = window.location.origin + "/batches";
  // }

const redirectBack=()=>{
    window.location.href = window.location.origin + "/courseFee";
  }
  const editAddSession=()=>{
    window.location.href = window.location.origin + "/editSession";
  }
  return (
    <div>
    <Navbar/>
       <div className="container-fluid">
        <div className="row">
        <div className="col-2 sidebar-col text-light pb-0">
        <div className="d-flex flex-column align-items-end vh-100">
        <Link to="/dashBoardHome"><span className="mt-3 sidebar-item">Dashboard</span></Link>
              <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item">Edit Profile</span></Link>
              <Link to="/addSessions"><span className="mt-3 sidebar-item">Upload Courses</span></Link>
              <Link to="/myCourses"><span className="mt-3 sidebar-item">My Courses</span></Link>
              <Link to="/mySessions"><span className="mt-3 sidebar-item">My Sessions</span></Link>
        </div>
        <div className="footer-terms d-flex flex-column align-items-end">
          <span>
            <Link>Terms,</Link>
            <Link>Privacy Policy</Link>
          </span>
          <span>CoachX Rights Reserved</span>
        </div>
      </div>
      <div className="col-md-8 col-12 rightside-col ">

   {/* /////////////////offcanvas///////////////// */}
   <button className="btn btn-primary sidebar-btn" type="button"          data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">sidebar</button>

      <div className="offcanvas offcanvas-start sidebar" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
        <div className="offcanvas-header">
          <button type="button" className="btn-close ms-auto text-light" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
        <div className="d-flex flex-column align-items-end vh-100">
        <Link to="/dashBoardHome"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Dashboard</span></Link>
              <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Edit Profile</span></Link>
              <Link to="/addSessions"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Upload Courses</span></Link>
              <Link to="/myCourses"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">My Courses</span></Link>
              <Link to="/"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">My Sessions</span></Link>
        </div>
        </div>
      </div>

        <div className='row'>
        <div className='col-12 d-flex justify-content-center'>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
        </div>
        <h1 className='AddSessionTitle'>Add Sessions</h1>
       {[...Array(3).keys()].map((session,index)=>
        <div key={index} className='session-wrapper d-flex justify-content-between mt-4'>
                <div className='d-flex justify-content-evenly w-md-50 w-100 align-items-center'>
                <LazyLoadImage src={sessionImg} alt="si" height="50" />
                <div className='session-wrapper-2'>
                    <p className='sessions'>Session {index+1}</p>
                    <span className='mt-1'>Duration: <span className='duration'>60 mins</span></span>
                </div>
                </div>
                <button className='editbtn' onClick={editAddSession}>Edit <LazyLoadImage src={editIcon} alt="ei"/> </button>
        </div>
        )}
        <EduNavigationButtons/>
        </div>
        </div>
        </div>
       </div>
</div>
  )
}
