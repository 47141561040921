import {
  NEW_COURSE_REQUEST,
  NEW_COURSE_SUCCESS,
  NEW_COURSE_FAIL,
} from "../constant/newCourseConst";

export const addCourseReducer = (state = { newCourse: {} }, { type, payload }) => {
  switch (type) {
    case NEW_COURSE_REQUEST:
      return { ...state, isLoading: true };
    case NEW_COURSE_SUCCESS:
      return { isLoading: false, newCourse: payload };
    case NEW_COURSE_FAIL:
      return { isLoading: false, newCourse: payload };
    default:
      return state;
  }
};
