import React from "react";
import Footer from "../Footer";
import "../../components/landingpages/css/Datascience.css";

function Datascience() {
  return (
    <div className="datascience">
      <div className="container">
        <div>
          
        </div>
        
      </div>
      <Footer />
    </div>
  );
}

export default Datascience;
