import React from 'react'

export default function EduSessionAttendence() {
  return (
    <div>
    <h4>Students Attendence</h4>
    <br />
      <table className="table table-success table-striped table-bordered">
          <thead>
          <tr className='table-primary'>
              <th className="tableSrNo">Sr.No.</th>
              <th>Student Name</th>
              <th>Attendence</th>
          </tr>
          </thead>
          <tbody>
          <tr>
              <td>1.</td>
              <td>a</td>
              <td><button className='btn btn-sm btn-success'>Present</button></td>
          </tr>
          <tr>
              <td>2.</td>
              <td>b</td>
              <td><button className='btn btn-sm btn-danger'>Absent</button></td>
          </tr>
          </tbody>
      </table>      
  </div>
  )
}
