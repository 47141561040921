import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../style/becomeEducator.css";
import user from "../images/user.png";
import expect_community from "../images/expect_community.png";
import feature_6 from "../images/feature_6.png";
import formImg from "../images/joineUsOnlineNew.png";
import status from "../images/status.png";
import whatsapp from "../images/whatsapp.png";
import noManualAttendence from "../images/noManualAttendence.png";
import batchManagement from "../images/batchManagement.png";
import autoCertificate from "../images/autoCertificate.png";
import ComfirtHome from "../images/ComfirtHome.png";
import hiddenCost from "../images/hiddenCost.png";
import { useDispatch, useSelector } from "react-redux";
import { EducatorInfoAction } from "../Action/educatorInfoAction";
import ContactUs from "../components/ContactUs";
// lazy loading
import { LazyLoadImage } from "react-lazy-load-image-component";
import RequestCallBack from "../components/RequestCallBack";
export default function BecomeEducator() {
  const [educatorInfo, setEducatorInfo] = useState({
    full_name: "",
    phone_number: "",
    email_id: "",
    linkedin_url: "",
    experience: "",
    skills: "",
    pitch: "",
  });
  const dispatch = useDispatch();
  const { eduInfo } = useSelector((state) => state.educatorInfo);

  useEffect(() => {
    if (eduInfo?.data?.result && eduInfo?.data?.result?.is_uploaded == undefined) {
      window.location.href = window.location.origin + "/thankYou";
    }
  }, [eduInfo]);

  const saveEducatorInfo = (e) => {
    e.preventDefault();
    dispatch(EducatorInfoAction(educatorInfo));
  };

  const features = [
    {
      img: feature_6,
      desc: "Entire Marketing, student counselling & Student acquisition/ admission Support ",
    },
    {
      img: hiddenCost,
      desc: "No upfront or hidden Cost, Earn additional revenue in your free slots.",
    },
    {
      img: status,
      desc: "No Zoom Link Sharing & Complete Live Sessions",
    },
    {
      img: noManualAttendence,
      desc: "No Manual Attendance Management",
    },
    {
      img: batchManagement,
      desc: "No Manual  Batch Management",
    },
    {
      img: feature_6,
      desc: "Dedicated Landing pages with integrated Payment Management",
    },
    {
      img: autoCertificate,
      desc: "Auto - Digital certificate distribution",
    },
    {
      img: ComfirtHome,
      desc: "You work according to your own free timing from comfort of your home",
    },
  ];

  const handleKeyDownSearch = (e) => {
    if (e.key === "Enter") {
      saveEducatorInfo();
    }
  };
  return (
    <div>
      <div className="educator-main">
        <div className="container position-relative">
          <div className="educator-text">
            <span className="share-knowledge">Share Your Knowledge With The World </span>
            <h2 className="educator-heading">Become a Trainer On CoachX </h2>
            <p className="educator-para">
              If you are an expert in your field, start teaching on CoachX today and earn additional
              revenue from the comfort of your home. No upfront or hidden charges: You pay from what
              you earn!
            </p>
            <a href="#form">
              <button className="btn enroll-btn">Join Now</button>
            </a>
          </div>
        </div>
      </div>
      <div className="contactUs">
        {/* <ContactUs /> */}
        {/* <RequestCallBack/> */}
      </div>
      {/* <div className="wpChat">
        <a href="https://wa.me/message/YIUPH6AA6FGUF1" target="_black"> <LazyLoadImage src={whatsapp} width={50} className="me-2 wpChat" alt="whatsapp" /></a>
        </div> */}
      {/* about our mentor  */}
      <div className="about-mentor">
        <div className="container">
          <div className="row">
            <div className="col-md-6 aboutMentor-box-wrapper pt-5 d-sm-flex d-md-block">
              <LazyLoadImage className="align-self-right" src={user} width="80%" alt="user" />
            </div>
            <div className="col-md-6 about-mentor-text d-flex flex-column justify-content-center">
              <div className="row ">
                <h4 className="aboutMentor-heading mb-4">About Our Mentors</h4>
                <div className="col-md-6 aboutMentor-box-wrapper">
                  <div className="aboutMentor-box d-flex flex-md-column">
                    <LazyLoadImage src={expect_community} width={50} alt="expect_community" />
                    <div className="aboutMentor-inner-wrapper">
                      <span className="aboutEducator-desc">Trainers</span>
                      <h3 className="aboutEducator-count">40 +</h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 aboutMentor-box-wrapper ">
                  <div className="aboutMentor-box d-flex flex-md-column">
                    <LazyLoadImage src={expect_community} width={50} alt="expect_community" />
                    <div className="aboutMentor-inner-wrapper">
                      <span className="aboutEducator-desc ">Hours Tought</span>

                      <h3 className="aboutEducator-count mt-1">500 + hrs</h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 aboutMentor-box-wrapper">
                  <div className="aboutMentor-box d-flex flex-md-column">
                    <LazyLoadImage src={expect_community} width={50} alt="expect_community" />
                    <div className="aboutMentor-inner-wrapper">
                      <span className="aboutEducator-desc">Students Enrolled</span>
                      <h3 className="aboutEducator-count">15000 +</h3>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 aboutMentor-box-wrapper">
                  <div className="aboutMentor-box d-flex     flex-md-column">
                    <LazyLoadImage src={expect_community} width={50} alt="expect_community" />
                    <div className="aboutMentor-inner-wrapper">
                      <span className="aboutEducator-desc">Mentor Earnings</span>
                      <h3 className="aboutEducator-count">Avg 2.5 lakhs/months</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="educator-wrapper">
        <div className="container pb-1">
          <div className="row">
            <h3 className="educator-features-heading">Features</h3>
            {features.map((features, index) => (
              <div key={index} className="col-md-6 mb-4">
                <div className="feature-box">
                  <LazyLoadImage src={features.img} width={50} height={50} alt="features.img" />
                  <p className="features-para">{features.desc}</p>
                </div>
              </div>
            ))}
          </div>
          {/* educator form */}
          <div id="form" className="educator-form">
            <div className="row">
              <div className="col-lg-6 formImg-wrapper col-sm-10 offset-sm-1 offset-lg-0 ">
                <LazyLoadImage src={formImg} className="formImg" alt="formImg" />
              </div>
              <div className="col-lg-6 col-sm-10 offset-sm-1 offset-lg-0">
                <h2 className="educator-form-heading">Let us help you start the journey !</h2>
                <p className="educator-form-para">
                  Fill the form below and one of our team would connect you in 24 hours.
                </p>

                <form className="needs-validation" onSubmit={saveEducatorInfo}>
                  <div className="input-wapper mb-3">
                    <label for="validationCustom01">Full Name</label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setEducatorInfo({ ...educatorInfo, full_name: e.target.value });
                      }}
                      id="validationCustom01"
                      value={educatorInfo.full_name}
                      className="form-control mt-0"
                      required
                    />
                  </div>
                  <div className="input-wapper mb-3">
                    <label htmlFor="">Phone Number</label>
                    <input
                      type="number"
                      value={educatorInfo.phone_number}
                      onChange={(e) => {
                        setEducatorInfo({ ...educatorInfo, phone_number: e.target.value });
                      }}
                      className="form-control mt-0"
                      required
                    />
                    <div className="text-danger">{eduInfo?.data?.result?.error?.phone_number}</div>
                  </div>
                  <div className="input-wapper mb-3">
                    <label htmlFor="">Email ID</label>
                    <input
                      type="email"
                      value={educatorInfo.email_id}
                      onChange={(e) => {
                        setEducatorInfo({ ...educatorInfo, email_id: e.target.value });
                      }}
                      name="firstName"
                      className="form-control mt-0 "
                      required
                    />
                    <div className="text-danger">{eduInfo?.data?.result?.error?.email_id}</div>
                  </div>
                  <div className="input-wapper mb-3">
                    <label htmlFor="">LinkedIn URL</label>
                    <input
                      type="text"
                      value={educatorInfo.linkedin_url}
                      onChange={(e) => {
                        setEducatorInfo({ ...educatorInfo, linkedin_url: e.target.value });
                      }}
                      name="firstName"
                      className="form-control mt-0"
                    />
                  </div>
                  <div className="input-wapper mb-3">
                    <label htmlFor="">Experience (in Years)</label>
                    <input
                      type="number"
                      value={educatorInfo.experience}
                      onChange={(e) => {
                        setEducatorInfo({ ...educatorInfo, experience: e.target.value });
                      }}
                      name="firstName"
                      className="form-control mt-0"
                      requied
                    />
                  </div>
                  <div className="input-wapper mb-3">
                    <label htmlFor="">Skills</label>
                    <input
                      type="text"
                      value={educatorInfo.skills}
                      onChange={(e) => {
                        setEducatorInfo({ ...educatorInfo, skills: e.target.value });
                      }}
                      name="firstName"
                      className="form-control mt-0"
                      required
                      onKeyPress={handleKeyDownSearch}
                    />
                  </div>
                  <div className="input-wapper mb-3">
                    <label htmlFor="">Pitch about yourself</label>
                    <textarea
                      name=""
                      value={educatorInfo.pitch}
                      className="form-control mt-0"
                      id=""
                      cols="10"
                      rows="2"
                      onChange={(e) => {
                        setEducatorInfo({ ...educatorInfo, pitch: e.target.value });
                      }}
                      required
                    ></textarea>
                  </div>
                  <button
                    className="btn 
                                    educator-form-btn w-100"
                  >
                    Apply Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
