import React from 'react'
import "../style/eduNavigationButtons.css"
export default function EduNavigationButtons() {
  return (
         <div className='mt-3 d-flex justify-content-evenly w-md-75 w-100'> 
                  <button className='cancel-btn'
                //    onClick={redirectToBack}
                   >BACK</button>
                  <button className='aboutUs-submit-btn' 
                //   onClick={redirectToAdditionalReqi}
                  >NEXT</button>
                </div>
  )
}
