import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Tests from './Tests';

export default function TestGrade() {
  // back btn logic
  const [back, setBack] = useState("");
  const handleBack = (para) => {
    setBack(para);
  };
  return (
    <div>
      {
        back === "Assignment"
        ?<Tests/>
        :<div>
       <h1>
            <i
              className="backbtn bi bi-arrow-left-circle-fill"
              onClick={() => handleBack("Assignment")}
            ></i>
       </h1>
    <h4>Test Grades</h4>
    <br />
    <div className="table-responsive">
      <table className="table table-success table-striped table-bordered">
          <thead>
          <tr className='table-primary'>
              <th className='tableSrNo'>Sr.No.</th>
              <th>Test</th>
              <th>Action</th>
          </tr>
          </thead>
          <tbody>
          
          <tr>
              <td>1.</td>
              <td>Test-1</td>
              <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSd3fJJttsKrMZg_7K28xfdJ9iC-C9nbdRSgZcIQwwKxq6kAWQ/viewform" target="_blank"><button className='btn btn-success'>Start</button></a></td>
          </tr>
          
          <tr>
              <td>2.</td>
              <td>Test-2</td>
              <td><a href="https://docs.google.com/forms/d/e/1FAIpQLSd3fJJttsKrMZg_7K28xfdJ9iC-C9nbdRSgZcIQwwKxq6kAWQ/viewform" target="_blank"><button className='btn btn-success'>Start</button></a></td>
          </tr>
          </tbody>
      </table>  
    </div> 
    </div>
    }    
  </div>
  )
}
