import React, { useState } from 'react'
import "../style/starRating.css"
import StarRateIcon from '@mui/icons-material/StarRate';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
// import { upcomingworkshopAction } from "../../Action/upcomingCourses";
import { useDispatch, useSelector } from "react-redux";

export default function StartRating({avg_rating}) {
 
    const [rating, setRating] = React.useState(avg_rating && Math.round(avg_rating))
    const [hover, sethover] = React.useState(0)
     
    
  return (
    <div className='container'>
          <div className="row">
            <div className="d-flex align-items-center ps-0">
              <div className='d-flex me-2 mt-1 justify-content-center'>
                  {[...Array(5)].map((star,i)=>{
                         let ratingValue=(i+1)
                         
                        return <label key={i}>
                           <StarRoundedIcon
                          //  isHalf={true}                      
                            sx={{width:20,color:rating >= ratingValue ? "#E7B008":"gray"}}
                           onMouseEnter={()=>sethover(ratingValue)}
                           onMouseLeave={()=>sethover(null)}
                          //   color={rating= i ? "#E7B008":"gray"}
                          //   onClick={()=>setRating(ratingValue)}
                           />
                        </label>
                })}  
              </div>  
              <div className='rating mb-2'><strong>{rating}.0</strong></div>
          </div>  
    </div>
    </div>
  )
}
