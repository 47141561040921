import React, { useState } from 'react'
import video from "../../images/video.png";

export default function AddNotes() {
    const [comp, setcomp] = useState("")
  const handleAttendence=(para)=>{
    setcomp(para)
  }
  return (
    <div>
        <div className="row mt-5">
            <div className="col-4 mt-4">
                <h2>Study Material</h2>
            </div>
        <div className="col-8 mt-4">
            <div>
                <label htmlFor="">Add Your Notes </label>
                <input type="file" className='form-control'/>
            </div>
        </div>
        </div>
    </div>
  )
}
