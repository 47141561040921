import React, { useState } from 'react'
import EduAssignmentList from './EduAssignmentList'

export default function StuAssignmentOverview() {
   // back btn logic
   const [back, setBack] = useState("")
   const handleBack=(para)=>{
     setBack(para)
   }
  return (
    <div className='mt-4'>
      {
        back ==="EduAssignmentList"
        ?<EduAssignmentList/>
        :<div>
          <h1>
            <i className="backbtn bi bi-arrow-left-circle-fill" onClick={()=>handleBack("EduAssignmentList")}></i>
          </h1>
        <h2>Students Assignment Grades</h2>
      <div className="table-responsive">
        <table className="table table-success table-striped table-bordered">
            <thead>
              <tr className='table-primary'>
                  <th className="tableSrNo">Sr.No.</th>
                  <th>Name</th>
                  <th>Grade</th>
              </tr>
            </thead>
            <tbody>
            <tr>
                <td>1.</td>
                <td>aaaa</td>
                <td>10/9</td>
            </tr>
            <tr>
                <td>1.</td>
                <td>aaaa</td>
                <td>10/9</td>
            </tr>
            </tbody>
        </table>
      </div>
      </div>
      }
    </div>
  )
}
