import React, { useEffect, useState } from "react";
import "../style/thankYouEnrolling.css";
import thankYouImg from "../images/thankYouImg.jpeg";
import Footer from "../components/Footer";
import { useHistory, useLocation } from "react-router-dom";
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function ThankYouEnrolling() {
  const search = useLocation().search;
  const [switchApi, setSwitchApi] = useState()
  const course_info = new URLSearchParams(search).get("course_info");
  const  history =useHistory()
  useEffect(() => {
    setSwitchApi(course_info)
  }, [])
  
  const redirectToCourseDetails = () => {
      if(switchApi=="ThankYouPage"){
        history.push("/")
      }else{
        window.location.href = window.location.origin + "/courseDetail?course_info=" + course_info;
      }
  };
  return (
    <div>
      <div className="thankYouEnrolling-wrapper">
        <div className="container">
          <div className="thankYouEnrolling-middle-wrapper">
            <div className="row ">
              <div className="col-md-6 ">
                <LazyLoadImage
                  src={thankYouImg}
                  className="thankYouImg"
                  width="100%"
                  height="100%"
                  alt="thankYouImg"
                />
              </div>
              <div className="col-md-6 thankYouEnrolling-inner-wrapper">
                <h3 className="thankYouEnrolling-heading">
                  Thank you for submitting your details
                </h3>
                <p className="thankYouEnrolling-para">
                  Thank you for submitting your details, we will contact you
                  shortly.
                </p>
                <button className="done-btn" onClick={redirectToCourseDetails}>
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}
