import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import homebanner from "../images/home_banner.jpg";
import iso from "../images/9k.png";
import SkillIndiaColor from "../images/skill-India2.png";
import "../style/header.css";
// lazy loading
import { LazyLoadImage } from "react-lazy-load-image-component";
import ContactUs from "./ContactUs";
//////
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import contactUs from "../images/contactShape.png";
import whiteDotLive from "../images/whiteDotLive.png";
import LiveImg from "../images/LiveImg.png";

import whatsapp from "../images/whatsapp.png";
import placementBg from "../images/placementBg.png";
import reqCallImg from "../images/reqCallImg.png";
import phone from "../images/phone.png";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import { requestCallAction } from "../Action/requestCallAction";
import { useDispatch } from "react-redux";
import RequestCallBack from "./RequestCallBack";

export default function Header({
  Headerstyle,
  headerLearnNewKills,
  headerTitle1,
  headerTitle2,
  headerLiveBtn,
  attendFreeClassBtn,
  headerPara1,
  headerPara2,
  headerPara3,
  headerPara4,
  headerPara5,
  headerPara6,
}) {
  const [showRequestPage, setshowRequestPage] = useState(false);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [GraduationYear, setGraduationYear] = useState("2023-07-07");
  const [enquiry, setenquiry] = useState("");
  const [show, setShow] = useState(false);

  const reqCallBack = [];

  const handleRequestCallBack = () => {
    // setshowRequestPage(true);
    if (attendFreeClassBtn == "Success Stories") {
      return window.scroll({
        top: 0,
        behavior: "smooth",
      });
    }
    setShow(true);
  };

  useEffect(() => {}, []);

  const dispatch = useDispatch();

  const ListOfCourse = [
    {
      course: "Professional Certificate Program in Data Science",
      ID: 7,
    },
    {
      course: "Complete Data Analytics Course",
      ID: 1,
    },
    {
      course: "Professional Certificate Program in Business Analytics",
      ID: 8,
    },
    {
      course: "AWS Certified Cloud Practitioner - Complete Course",
      ID: 9,
    },
    {
      course: "Complete Tableau Training Program",
      ID: 1,
    },
    {
      course: "The Advanced SQL Course",
      ID: 1,
    },
    {
      course: "Python Django Certification Course",
      ID: 1,
    },
  ];

  const reqCallBackAction = () => {
    const courseId = ListOfCourse.find((item) => item.course == enquiry && item.ID);
    dispatch(
      requestCallAction({
        name,
        email,
        mobile: mobileNo,
        query: enquiry,
        year_of_graduation: "2023-07-07",
      })
    );

    // Privyr Post Webhook
    axios
      .post("https://www.privyr.com/api/v1/incoming-leads/0vZfjMQw/2JfhOXZs#generic-webhook", {
        name: name,
        email: email,
        phone: mobileNo,
        Course: courseId.course,
      })
      .then((response) => {
        console.log("Thank You");
      });

    // axios.post(
    //   "https://thirdpartyapi.extraaedge.com/api/SaveRequest",
    //   {
    //     "AuthToken": "COACHX-06-07-2023",
    //     "Source": "coachx",
    //     "FirstName":name,
    //     "Email":email,
    //     "MobileNumber":mobileNo,
    //     "LeadSource":22,
    //     "LeadCampaign":"Website Leads",
    //     "Course":courseId.ID,
    //     "Center":"5",
    //     "State":"",
    //     "City":""
    //   }
    // );
    // setshowRequestPage(false);
    setShow(false);
    setname("");
    setemail("");
    setmobileNo("");
    setGraduationYear("");
    setenquiry("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    reqCallBack.push("submited");
    localStorage.setItem("reqCallPopUp", JSON.stringify(reqCallBack));
    reqCallBackAction();
    e.target.reset();
  };

  const handleClose = () => {
    if (localStorage.getItem("reqCallPopUp")) {
      if (localStorage.getItem("reqCallPopUp").length >= 1) {
        return setShow(false);
      }
    }
    reqCallBack.push("denied");
    localStorage.setItem("reqCallPopUp", JSON.stringify(reqCallBack));
    setShow(false);
  };

  return (
    <div>
      <div className={"d-none"}></div>
      <div className="header-main" style={Headerstyle}>
        <div className="container header-text">
          <span className="headerpara4">{headerPara4}</span>
          <br />
          <span className="header-learnNewKills">{headerLearnNewKills}</span>

          <h2 className="header-title">
            {headerTitle1}
            {/* <span className={headerLiveBtn ===undefined?"d-none" :"header-live-btn"}> */}
            {/* {headerLiveBtn}  */}

            <img
              src={LiveImg}
              alt="whiteDotLive"
              className={headerPara5 === "" || headerPara5 === undefined ? "d-none" : "dotLiveImg"}
            />

            {/* </span> */}
            <br />
            <span className="headerTitle2">{headerTitle2}</span>
            {/* <span className="headerPara3">{headerPara4}</span> */}
          </h2>
          <p className="header-para mt-3">
            {headerPara1} <span className="headerPara6">{headerPara6}</span>{" "}
            <span
              className={headerPara5 != "" && headerPara5 != undefined ? "highPaying" : "d-none"}
            >
              {headerPara5}{" "}
            </span>{" "}
            {headerPara2} {headerPara3}
          </p>

          {/* old btn  */}

          {/* <button
            className="btn attendFreeClass_btn mt-3"
            onClick={() => {
              window.location.href = window.location.origin + "/allCourses";
            }}
          >
            Get Started Now <i className="bi bi-arrow-right"></i>
          </button> */}

          {/* new btn  */}
          <button
            className={
              attendFreeClassBtn == "Success Stories"
                ? "btn text-light attendFreeClass_btn1 mt-3"
                : "btn attendFreeClass_btn mt-3"
            }
            onClick={handleRequestCallBack}
          >
            {attendFreeClassBtn} <i className="bi bi-arrow-right"></i>
          </button>
        </div>
        <LazyLoadImage
          src={SkillIndiaColor}
          className="header_skill_India_logo"
          alt="Skill India"
        />
        <LazyLoadImage src={iso} className="header_iso_logo" alt="iso" />
      </div>
      {/* <RequestCallBack view={showRequestPage}/> */}
      <Modal
        show={show}
        onHide={handleClose}
        animation={false}
        className="reqModal"
        dialogClassName="modalWidth"
      >
        <Modal.Body className="modalBody">
          <div className="contactUsCrossbtn" onClick={handleClose}>
            <ClearIcon />
          </div>
          <div className=" w-100 d-flex justify-content-center">
            <div className="reqCallImg-wrapper">
              <img src={reqCallImg} alt="reqCallImg" />
            </div>
          </div>

          {/* Pop out form after clicking talk to an expert */}

          <form onSubmit={handleSubmit}>
            <div className="row mt-4 d-flex justify-content-center">
              <div className="col-md-5 col-12 d-flex justify-content-between order-2 order-md-2">
                <div className="d-flex justify-content-center align-items-center flex-column my-5">
                  <div className="w-75">
                    {/* <span>Call us on</span> */}
                    <h5 className="mobNo">
                      <i className="bi bi-telephone-fill me-1"></i>
                      +91-7378757330
                    </h5>
                  </div>
                  <p className="text-center">Or</p>
                  <div className="wp-wrapper w-75">
                    <a href="https://wa.me/message/YIUPH6AA6FGUF1" target="_black">
                      <span className="wpImg">
                        <img src={whatsapp} alt="whatsapp" width="13%" />
                      </span>
                      <span className="ms-3">WhatsApp Us</span>
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-1 d-none d-md-block order-md-2">
                <div className="d-flex flex-column justify-content-between align-items-center my-5">
                  <div className="upper"></div>
                  <div>Or</div>
                  <div className="lower"></div>
                </div>
              </div>

              <div className="col-md-5 col-12  order-1 order-md-3">
                <div className=" ">
                  <h4 className="text-center reqCallBackHeading">Request Call Back</h4>

                  <div className="my-3">
                    <input
                      type="Name"
                      className="form-control"
                      placeholder="Name*"
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                      required
                    />
                  </div>
                  <div className="my-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email*"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="my-3">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Contact No.*"
                      value={mobileNo}
                      onChange={(e) => setmobileNo(e.target.value)}
                      required
                    />
                  </div>
                  {/* <div className="my-3">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Year of graduation"
                      value={GraduationYear}
                      onChange={(e) => setGraduationYear(e.target.value)}
                      required
                    />
                  </div> */}
                  <div className="my-3">
                    <div className="my-3">
                      <select
                        className="form-select select_course"
                        name="UserRole"
                        onChange={(e) => setenquiry(e.target.value)}
                        value={enquiry}
                        required
                      >
                        <option value="" selected>
                          Select Course
                        </option>
                        <option value="Professional Certificate Program in Data Science">
                          Professional Certificate Program in Data Science
                        </option>
                        <option value="Complete Data Analytics Course">
                          Complete Data Analytics Course
                        </option>
                        <option value="Professional Certificate Program in Business Analytics">
                          Professional Certificate Program in Business Analytics
                        </option>
                        <option value="AWS Certified Cloud Practitioner - Complete Course">
                          AWS Certified Cloud Practitioner - Complete Course
                        </option>
                        <option value="Professional Certificate Program in Business Analytics">
                          Professional Certificate Program in Business Analytics
                        </option>
                        <option value="Complete Tableau Training Program">
                          Complete Tableau Training Program{" "}
                        </option>
                        <option value="The Advanced SQL Course">The Advanced SQL Course</option>
                        <option value="Python Django Certification Course">
                          Python Django Certification Course
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button
                      type="submit"
                      variant=""
                      className="w-75 submitBtn"
                      // onClick={handleClose}
                    >
                      SUBMIT
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/* <div className="row">
            <Link to="/"><p>I hereby agree to the terms & Conditions & Privacy policy of Coachx.Live.</p></Link>
          </div> */}
        </Modal.Body>
      </Modal>
    </div>
  );
}
