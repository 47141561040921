import axios from "axios";
import {
  PLACEMENT_LIST_REQUEST,
  PLACEMENT_LIST_SUCCESS,
  PLACEMENT_LIST_FAIL,
} from "../constant/placementListConst";


//Original Code from Mangesh
export const placementListAction = (pageNo) => async (dispatch) => {
  console.log(pageNo);

  try {
    dispatch({ type: PLACEMENT_LIST_REQUEST });
    const { data } = await axios.get(`/placement/placed_students/list?page=${pageNo}`);
    console.log(data.data);
    dispatch({
      type: PLACEMENT_LIST_SUCCESS,
      payload: data.data,
    });
  } catch (error) {
    dispatch({ type: PLACEMENT_LIST_FAIL, payload: error });
  }
};
