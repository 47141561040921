import React, { useState } from "react";
import StuSessionAttendence from "./StuSessionAttendence";

export default function EduSessionAttendence() {
  const [comp, setcomp] = useState("");
  const handleAttendence = (para) => {
    setcomp(para);
  };
  return (
    <div>
      {comp === "attendence" ? (
        <StuSessionAttendence />
      ) : (
        <div>
          <h4>Sessions</h4>
          <br />
          <table className="table table-success table-striped table-bordered">
            <thead>
              <tr className="table-primary">
                <th className="tableSrNo">Sr.No.</th>
                <th>Session Name</th>
                <th>Attendence</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td>Session-1</td>
                <td>
                  <button
                    className="btn btn-sm btn-success"
                    onClick={() => handleAttendence("attendence")}
                  >
                    view
                  </button>
                </td>
              </tr>
              <tr>
                <td>2.</td>
                <td>Session-2</td>
                <td>
                  <button className="btn btn-sm btn-success">view</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
