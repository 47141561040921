import { ALL_DATA_REQUEST, ALL_DATA_SUCCESS, ALL_DATA_FAIL } from "../constant/allCourses";

export const getAllCoursesReducer = (state = { courses: [] }, { type, payload }) => {
  switch (type) {
    case ALL_DATA_REQUEST:
      return { ...state, isLoading: true };
    case ALL_DATA_SUCCESS:
      return { isLoading: false, courses: payload };
    case ALL_DATA_FAIL:
      return { isLoading: false, courseserror: payload };
    default:
      return state;
  }
};
