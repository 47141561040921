import axios from "axios"
import {VERIFYOTP_REQUEST,VERIFYOTP_SUCCESS,VERIFYOTP_FAIL} from "../constant/loginConstant"
export const verifyOTPAction=(loginCredentials)=>async(dispatch)=>{
    // console.log(loginCredentials);
    try {
        dispatch({type:VERIFYOTP_REQUEST})
    const {data}=await axios.post("users/verifyotp/",loginCredentials)    
    console.log(data);
    dispatch({type:VERIFYOTP_SUCCESS,payload:data})
    } catch (error) {
        dispatch({type:VERIFYOTP_FAIL, payload:error.response.data})
    }
}
