import {PERSONAL_INFO_REQUEST,PERSONAL_INFO_SUCCESS,PERSONAL_INFO_FAIL} from "../constant/personalInfoConstant"
import axios from "axios"
import { useSelector } from "react-redux";
import { refreshTokenAction } from "./refreshTokenAction";

export const personalInfoAction=(profileInfo,token)=>async(dispatch)=>{
    // const { token } = useSelector((state) => state.token);
    var tokenDetails  = JSON.parse(localStorage.getItem("UserData")); 
    let deviceUDID = localStorage.getItem("DEVICE-UDID");   
    try {
        dispatch({type:PERSONAL_INFO_REQUEST})
        axios.defaults.headers.common = {
            'Authorization': `Bearer ${tokenDetails.access}`,
            'DEVICE-UDID': deviceUDID
          }
          profileInfo.user_info.id =  tokenDetails.id; 
    const {data}=await axios.post("/course/stu/profile/",profileInfo)    
    dispatch({type:PERSONAL_INFO_SUCCESS,payload:data})
    } catch (error) {
        if(error?.response?.status == 401){
            dispatch(refreshTokenAction())            
        }
        dispatch({type:PERSONAL_INFO_FAIL, payload:error})
    }
}