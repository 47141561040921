import React from "react";


function Clients(props) {
  return (
    <div className="clients">
      <div className="container">
        <div className="logos slide-bar d-flex m-3">
          {props.data.map((items) => (
            <div className="m-3 clientLogo">
              <img src={items.image} width={100} onContextMenu="return false;" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Clients;
