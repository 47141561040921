import {SIGNUP_VERIFY_REQUEST,SIGNUP_VERIFY_SUCCESS,SIGNUP_VERIFY_FAIL} from "../constant/loginConstant"

export const SignUpVerifyReducer=(state={signUpVerify:{}},{type,payload})=>{
    switch (type) {
        case SIGNUP_VERIFY_REQUEST:
            return {...state,isLoading:true}
        case SIGNUP_VERIFY_SUCCESS:
            return {isLoading:false,signUpVerify:payload}
        case SIGNUP_VERIFY_FAIL:
            return {isLoading:false,signUpVerifyerror:payload}
        default:return state
    }
    }