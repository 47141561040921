import {TERMS_REQUEST,TERMS_SUCCESS,TERMS_FAIL} from "../constant/privacyPolicyConst"
export const tersReducer=(state={terms:{}},{type,payload})=>{
    switch (type) {
        case TERMS_REQUEST:
            return {...state,isLoading:true}
        case TERMS_SUCCESS:
            return {isLoading:false,terms:payload}
        case TERMS_FAIL:
            return {isLoading:false,termserror:payload}
        default:return state
    }
    }