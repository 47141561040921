import React from "react";

export default function ResumeUpload() {
  return (
    <div>
      <h4>Upload Resume</h4>
      <br />
      <div className="bg-light d-flex justify-content-evenly align-items-center">
        <span className="fs-2 w-50"> Upload Resume</span>
        <span className="input-group mt-2">
          <input type="file" className="d-none" id="profilePic"/>
          <label
            className="input-group-text bg-success text-white ms-auto"
            for="profilePic"
          >
            Upload
          </label>
          {/* <button className='btn-success' >Upload</button> */}
        </span>
      </div>
    </div>
  );
}
