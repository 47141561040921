import React, { useState } from "react";
import CourseAttendence from "./CourseAttendence";
import SessionAttendence from "./SessionAttendence";
import TestGrade from "./TestGrade";
import Avatar from "@material-ui/core/Avatar";
import annImg from "../../images/ann-img.png";
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Overview() {
  const [comp, setcomp] = useState("");
  const handleAttendence = (para) => {
    setcomp(para);
  };
  return (
    <div>
      {comp === "attendence" ? (
        <CourseAttendence />
      ) : (
        <div>
          {comp === "grade" ? (
            <TestGrade />
          ) : (
            <div>
              <h4>Overview</h4>
              <br />
              <div className="ann-img-wrapper">
                <div className="ann-img">
                  <LazyLoadImage src={annImg} alt="annImg" width="100%" />
                </div>
                <div className="ann-para">
                  <p>
                    “The Only skill that will be important in the 21st century
                    is the skill of learning new skills. Everything else will
                    become obsolete over time.”
                  </p>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-6 col-lg-3 p-0">
                  <div
                    className="attendance border"
                    onClick={() => handleAttendence("attendence")}
                  >
                    <span className="d-flex justify-content-between align-items-center">
                      <span>Attendance</span>
                    </span>
                    <span className="align-self-center dashboard-count">80%</span>
                  </div>
                </div>
                <div className="col-6 col-lg-3 p-0">
                  <div
                    className="testSubmited border"
                    onClick={() => handleAttendence("grade")}
                  >
                    <span>Tests Submited</span>
                    <span className="align-self-center dashboard-count">15</span>
                  </div>
                </div>
                <div className="col-6 col-lg-3 p-0">
                  <div
                    className="AssignentSub"
                    onClick={() => handleAttendence("grade")}
                  >
                    <span>Assignments Submited</span>
                    <span className="align-self-center dashboard-count">10</span>
                  </div>
                </div>
                <div className="col-6 col-lg-3 p-0">
                <div
                  className="grade"
                  onClick={() => handleAttendence("grade")}
                >
                  <span className="d-flex justify-content-between align-items-center">
                    <span>Profile Complition</span>
                  </span>
                  <span className="align-self-center dashboard-count">95%</span>
                </div>
                </div>
              </div>

              {/* card */}   

              <div className="mt-5">
                <div className="card announcement-card">
                  <div className="card-header">Latest Announcement</div>
                  {[...Array(2)].map((item, index) => {
                    return (
                      <div className="my-2 announcement-outer-wrapper">
                            <Avatar>N</Avatar>
                        <div className="d-flex flex-column justify-content-between announcement-inner-wrapper">
                          {/* <div className="w-100 d-flex flex-column"> */}
                            <p className="d-flex justify-content-between align-items-center "><span className="gm-span">Good morning students | name</span><span className="time-span">10-10-2022 posted 2 hrs ago</span></p>
                            <p>Todays class is cancelled</p>
                          {/* </div> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
