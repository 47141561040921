import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import settings from "../images/Settings.png";
import overview from "../images/Overview.png";
import assignments from "../images/Assignments.png";
import footer_logo from "../images/footer_logo.png";
import support from "../images/support.png";
import liveClasses from "../images/liveClasses.png";
import recordedSessions from "../images/recordedSessions.png";
import test from "../images/test.png";
import notes from "../images/notes.png";
//css import
import "../style/adminPanel.css";
//material ui
import StuDashboardSetting from "../components/student_dashboard/StuDashboardSetting";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Support from "../components/student_dashboard/Support";
import AdminEducator from "../components/adminPanel/AdminEducator";
import AdminPanelCourses from "../components/adminPanel/AdminPanelCourses";
import AddNotes from "../components/adminPanel/AddNotes";
import CreateNewBatch from "../components/adminPanel/CreateNewBatch";
import AddRecordedVideo from "../components/adminPanel/AddRecordedVideo";
import ManageBatch from "../components/adminPanel/ManageBatch";
import EduAssignment from "../components/educatorDashboard/EduAssignment";
import EduAssignmentCourses from "../components/educatorDashboard/EduAssignmentCourses";
import EduTestCourses from "../components/educatorDashboard/EduTestCourses";
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";
// material ui code
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));
export default function AdminPanel() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [first, setfirst] = useState(0);
  
  const courseTabs = [
    {
      img: overview,
      tabName: "Educator",
      component: <AdminEducator/>,
    },
    {
      img: recordedSessions,
      tabName: "Courses",
      component: <AdminPanelCourses/>,
    },
    {
      img: liveClasses,
      tabName: "Create New Batch",
      component: <CreateNewBatch/>,
    },
    {
      img: liveClasses,
      tabName: "Manage Batch",
      component: <ManageBatch/>,
    },
    {
      img: assignments,
      tabName: "Assignments", 
      component: <EduAssignment/>,
    },
    {
      img: assignments,
      tabName: "Assignments Grades",
      component: <EduAssignmentCourses />,
    },
    {
      img: test,
      tabName: "Test & Grades",
      component: <EduTestCourses />,
    },
    {
      img: notes,
      tabName: "Add Notes",
      component: <AddNotes/>,
    },
    {
      img: notes,
      tabName: "Add Recorded videos",
      component: <AddRecordedVideo/>,
    },
    {
      img: support,
      tabName: "Support",
      component: <Support />,
    },
    {
      img: settings,
      tabName: "Settings",
      component: <StuDashboardSetting />,
    },
  ];

  // window size
  function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener("resize", handleResize);
      // cleanup function
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
    return size;
  }
  const [height, width] = useWindowSize();
  const handleTabs = (index) => {
    setfirst(index);
    if (width < 768) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (width < 768) {
      setOpen(false);
    }
  }, []);

  return (
    <div>
      <div>
        {/* <CssBaseline /> */}
        <AppBar
          position="fixed"
        >
          <Toolbar className="menuIcon">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon/>
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="row  w-75 ">
          <div className="col-4">
          <Drawer
              variant="persistent"
              anchor="left"
              open={open}
            >
              <div className="adminMenuIcon">
                <IconButton onClick={handleDrawerClose}>
                  <span className="text-light fs-6">
                    <ChevronLeftIcon/>
                    Back
                  </span>
                </IconButton>
              </div>
              <Divider />
              <div className="col-2 admin-sidebar-col text-light pb-5">
                <div className="adminDashboardImg w-100">
                  <Link to="/">
                    <LazyLoadImage src={footer_logo} alt="footer_logo" width="120px" />
                  </Link>
                </div>
                <div className="d-flex flex-column align-items-start ms-5 mb-5 mt-0">
                  {courseTabs.map((item, index) => (
                    <p
                      onClick={() => handleTabs(index)}
                      key={index}
                      className="adminSidebar-item-wrapper"
                    >
                      <span className="admin-sidebar-item">
                        <LazyLoadImage src={item.img} className="me-3 tabLogo" alt="item.img" />
                        {item.tabName}
                      </span>
                    </p>
                  ))}
                </div>
                <div className="admin-footer-terms d-flex flex-column">
                  <span>
                    <Link to="/termsAndConditions">Terms,</Link>
                    <Link to="/privacyPolicy">Privacy Policy</Link>
                  </span>
                  <span>&copy;copyright:Coachx.Live Rights Reserved</span>
                </div>
              </div>
            </Drawer>
          </div>
          <div className="col-8 p-0">
          <main className="adminDashboard-wrapper">
              <div className="main-adminDashboard-wrapper mt-2">
                {courseTabs.map((comp, i) => i === first && comp.component)}
              </div>
          </main>
          </div>
        </div>
      </div>
    </div>
  );
}
