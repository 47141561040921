import React, { useState } from "react";
// import CourseAttendence from "./CourseAttendence";
import EduCourseAttendence from "./EduCourseAttendence";
import db1 from "../../images/db-1.png";
import db2 from "../../images/db-2.png";
import db3 from "../../images/db-3.png";
import db4 from "../../images/db-4.png";
import EduTestGrade from "./EduTestGrade";
import EduTestCourses from "./EduTestCourses";
import EduAssignmentCourses from "./EduAssignmentCourses";
import { useHistory } from "react-router-dom";
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function EduOverview() {
  const [comp, setcomp] = useState("");
  const compList = [
    {
      compName: "AssGrade",
      subComp: <EduAssignmentCourses OverviewComp={comp} />,
    },
    {
      compName: "testGrade",
      subComp: <EduTestCourses />,
    },
  ];

  const handleAttendence = (para) => {
    setcomp(para);
  };
  const history = useHistory();
  const handleSyllabus = () => {
    history.push(
      "https://docs.google.com/forms/d/e/1FAIpQLSd3fJJttsKrMZg_7K28xfdJ9iC-C9nbdRSgZcIQwwKxq6kAWQ/viewform"
    );
  };
  const found = compList.find((element) => element.compName === comp);
  return (
    <div>
      {comp === "attendence" ? (
        <EduCourseAttendence />
      ) : (
        <div>
          {comp === found?.compName ? (
            // <EduTestCourses/>
            found?.subComp
          ) : (
            // <EduAssignmentCourses OverviewComp={comp}/>
            <div>
              <h4>Overview</h4>
              <br />
              <div className="row d-flex">
                <div
                  className="col-6 col-lg-4 coursesComplited"
                  onClick={() => handleAttendence("attendence")}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span
                    // className="btn btn-info"
                    >
                      Courses Completed
                    </span>
                    <LazyLoadImage src={db4} alt="" width={25} height={25} />
                  </div>
                  <p className="d-flex justify-content-around align-items-center">
                    <span className="eduCount">45</span>
                    <span>+5 This Month</span>
                  </p>
                </div>

                <div
                  className="col-6 col-lg-4 sessionsCompleted"
                  onClick={() => handleAttendence("grade")}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span
                    // className="btn btn-success"
                    >
                      Sessions Completed
                    </span>
                    <LazyLoadImage src={db3} alt="" width={25} height={25} />
                  </div>
                  <p className="d-flex justify-content-around align-items-center">
                    <span className="eduCount">45</span>
                    <span>+5 This Month</span>
                  </p>
                </div>

                <div
                  className="col-6 col-lg-4 batchecCompleted"
                  onClick={() => handleAttendence("grade")}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span
                    // className="btn btn-success"
                    >
                      Batch Completed
                    </span>
                    <LazyLoadImage src={db2} alt="" width={25} height={25} />
                  </div>
                  <p className="d-flex justify-content-around align-items-center">
                    <span className="eduCount">45</span>
                    <span>+5 This Month</span>
                  </p>
                </div>

                <div
                  className="col-6 col-lg-4 stuEnrolled"
                  onClick={() => handleAttendence("grade")}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span
                    // className="btn btn-success"
                    >
                      Students Enrolled
                    </span>
                    <LazyLoadImage src={db1} alt="" width={25} height={25} />
                  </div>
                  <p className="d-flex justify-content-around align-items-center">
                    <span className="eduCount">45</span>
                    <span>+5 This Month</span>
                  </p>
                </div>

                <div
                  className="col-6 col-lg-4 assignmentGrades mt-2"
                  onClick={() => handleAttendence("AssGrade")}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span
                    // className="btn btn-success"
                    >
                      Assignment Grades
                    </span>
                    <LazyLoadImage src={db1} alt="" width={25} height={25} />
                  </div>
                  <p className="d-flex justify-content-around align-items-center">
                    <span className="eduCount">45</span>
                    <span>+5 This Month</span>
                  </p>
                </div>

                <div
                  className="col-6 col-lg-4 testGrades mt-2"
                  onClick={() => handleAttendence("testGrade")}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span
                    // className="btn btn-success"
                    >
                      Test Grades
                    </span>
                    <LazyLoadImage src={db1} alt="" width={25} height={25} />
                  </div>
                  <p className="d-flex justify-content-around align-items-center">
                    <span className="eduCount">45</span>
                    <span>+5 This Month</span>
                  </p>
                </div>

               
                <div
                  className="col-6 col-lg-4 updateSyllabus mt-2"
                  // onClick={handleSyllabus}
                >
                   <a className="eduSyllabus" href="https://docs.google.com/forms/d/e/1FAIpQLSd3fJJttsKrMZg_7K28xfdJ9iC-C9nbdRSgZcIQwwKxq6kAWQ/viewform" target="_blank">
                  <div className="d-flex justify-content-between align-items-center">
                    <span
                    // className="btn btn-success"
                    >
                      Update Syllabus
                    </span>
                    <LazyLoadImage src={db1} alt="" width={25} height={25} />
                  </div>
                  <p className="d-flex justify-content-around align-items-center">
                    <span className="eduCount">45</span>
                    <span>+5 This Month</span>
                  </p>
                  </a>
                </div>
                
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
