import axios from "axios"
import {RESEND_REQUEST,RESEND_SUCCESS,RESEND_FAIL} from "../constant/loginConstant"
export const resendOtpAction=(profileInfo)=>async(dispatch)=>{
    console.log(profileInfo);
    try {
        dispatch({type:RESEND_REQUEST})
    const {data}=await axios.post("users/resendotp/",profileInfo)
    console.log(data); 
    dispatch({type:RESEND_SUCCESS,payload:data})
    } catch (error) {
        dispatch({type:RESEND_FAIL, payload:error})
    }
}