import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import "../style/MyCourses.css";
import MyCourseCard from "./MyCourseCard";

//material ui
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

export default function MyCourses() {
const [index, setindex] = useState()
  const approvalArray=["In Review","Upcoming","Active","Complited"]
  return (
    <div>
      <Navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-2 sidebar-col text-light pb-0">
            <div className="d-flex flex-column align-items-end vh-100">
              <Link to="/dashBoardHome">
                <span className="mt-3 sidebar-item">Dashboard</span>
              </Link>
              <Link to="/educatorDashboardEditProfile">
                <span className="mt-3 sidebar-item">Edit Profile</span>
              </Link>
              <Link to="/addSessions">
                <span className="mt-3 sidebar-item">Upload Courses</span>
              </Link>
              <Link to="/myCourses">
                <span className="mt-3 sidebar-item">My Courses</span>
              </Link>
              <Link to="/mySessions">
                <span className="mt-3 sidebar-item">My Sessions</span>
              </Link>
            </div>
            <div className="footer-terms d-flex flex-column align-items-end">
              <span>
                <Link>Terms,</Link>
                <Link>Privacy Policy</Link>
              </span>
              <span>CoachX Rights Reserved</span>
            </div>
          </div>
          <div className="col-md-8 col-12 rightside-col ">
            <div className="d-flex justify-content-evenly">
              
            {approvalArray.map((status,i)=>(
              <div className={index == i ? "Approvel-status isSelected":"Approvel-status"} onClick={()=>setindex(i)}>{status}</div>))}
            </div>
            <div className="mt-5">
              <Paper
                sx={{
                  display: "flex",
                  background: "#FDFDFD",
                  boxShadow: "0px 0px 0px 0px",
                  border: "1px solid #02ADDA",
                  borderRadius: "20px",
                  alignItems: "center",
                  //   height: "58px",
                  width: "100%",
                }}
              >
                <IconButton
                  type="button"
                  className="searchIcon-btn"
                  aria-label="search"
                  //   onClick={Filter}
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  className=""
                  sx={{ ml: 2, flex: 1 }}
                  inputProps={{ "aria-label": "search google maps" }}
                  //   value={SearchCourse}
                  placeholder={"Search Courses"}
                  //   onChange={(e) => setSearchCourse(e.target.value)}
                  //   onKeyPress={handleKeyDownSearch}
                />
              </Paper>
            </div>
            <div className="w-100 my-4 d-flex flex-column align-items-end">
              <a href="#" className="addCourse"><i class="bi bi-plus-lg"></i>Add new courses</a>
            </div>
            <div className="row">
              <div className="col-md-6">
              <MyCourseCard/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
