import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import "../style/corporateTrainings.css";
import corporate_training1 from "../images/corporate_training1.jpg";
import corporate_training2 from "../images/corporate_training2.png";
import corporate_training3 from "../images/corporate_training3.png";
import check from "../images/succesfullLogo.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { requestCallAction } from "../Action/requestCallAction";
// lazy loading
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function CorporateTrainings() {
  const dispatch = useDispatch();
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [query, setquery] = useState("");
  const [GraduationYear, setGraduationYear] = useState("");
  const [companyName, setcompanyName] = useState("");
  const search = useLocation().search;

  const course_info = new URLSearchParams(search).get("course_info");

  const { requestCall } = useSelector((state) => state.reqCall);

  const history = useHistory();

  const reqCallBackAction = () => {
    const nameData = `${name}, company:${companyName}`;
    dispatch(
      requestCallAction({
        name: nameData,
        email,
        mobile: mobileNo,
        query,
        year_of_graduation: GraduationYear,
      })
    );
  };

  useEffect(() => {
    if (requestCall.is_submitted) {
      history.push("/thankYouForEnrollment?course_info=ThankYouPage");
    }
  }, [requestCall]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="coupenCode-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 order-lg-first order-last p-0">
              <div className="corporateTraining-wrapper">
                <h4 className="corporateTraining-formHeading text-center">
                  Your search for the right candidates stops here
                </h4>
                <p className="text-center p-2 p-md-0">
                  Take the first step in hiring industry ready and right talent from us
                </p>
                <LazyLoadImage
                  src={corporate_training1}
                  width="100%"
                  height="100%"
                  alt="corporate_training1"
                />
              </div>
            </div>
            <div className="col-lg-6 order-lg-last order-first">
              <div className="corporateTraining-form">
                <h4 className="corporateTraining-formHeading text-center">Request Call Back</h4>
                <div>
                  <label className="leadLabel">Name Of The Person *</label>
                  <input
                    type="text"
                    className="form-control coupenInput3 mb-1"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="leadLabel">Company Name*</label>
                  <input
                    type="text"
                    className="form-control coupenInput3 mb-1"
                    placeholder="Enter Company Name"
                    value={companyName}
                    onChange={(e) => setcompanyName(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="leadLabel">Contact No. *</label>
                  <input
                    type="number"
                    max={10}
                    className="form-control coupenInput3 mb-1"
                    placeholder="Contact No."
                    value={mobileNo}
                    onChange={(e) => setmobileNo(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="leadLabel">Work Email *</label>
                  <input
                    type="email"
                    className="form-control coupenInput3 mb-1"
                    placeholder="Email ID"
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="leadLabel">Traning Start date *</label>
                  <input
                    type="date"
                    className="form-control coupenInput3 mb-4 mb-1"
                    placeholder="Traning Start date"
                    value={GraduationYear}
                    onChange={(e) => setGraduationYear(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="leadLabel">Which Course Training Required? *</label>
                  <input
                    type="text"
                    className="form-control coupenInput3 mb-4 mb-1"
                    placeholder="Course requirment"
                    value={query}
                    onChange={(e) => setquery(e.target.value)}
                    required
                  />
                </div>
                <div className="d-flex justify-content-center w-100">
                  <button
                    className="corporateTraining-submit  mx-auto w-100"
                    onClick={reqCallBackAction}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6 col-12">
              <div>
                <h4 className="corporateTraining-formHeading text-center">WHY HIRE FROM COACHX</h4>
                <p className="text-center p-2 p-md-0">Tap in to the right talent.</p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>
                  <span className="why-hire-para fs-5 ms-2 why-hire-para">
                    Hire From Pan-India Talent Pool.
                  </span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>
                  <span className="fs-5 ms-2 why-hire-para">
                    Available for full time, Freelancing, Internship projects.
                  </span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>
                  <span className="fs-5 ms-2 why-hire-para">
                    Hire Job Ready Professionals at No Cost!
                  </span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>
                  <span className="fs-5 ms-2 why-hire-para">Complete Zero Cost Hiring.</span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>
                  <span className="fs-5 ms-2 why-hire-para">Dedicated Relationship Manager.</span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>
                  <span className="fs-5 ms-2 why-hire-para">Quick Turn Around Time.</span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>{" "}
                  <span className="fs-5 ms-2 why-hire-para">
                    Candidates who are Productive workforce from day 1.
                  </span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>{" "}
                  <span className="fs-5 ms-2 why-hire-para">
                    Experience ranging from 0 to 12 years.
                  </span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>{" "}
                  <span className="fs-5 ms-2 why-hire-para">
                    Available for full time, Freelancing, Internship projects.
                  </span>
                </p>
                <p className="d-flex align-items-center why-hire-para">
                  <span>
                    <LazyLoadImage src={check} width="25" alt="check" />
                  </span>{" "}
                  <span className="fs-5 ms-2 why-hire-para">
                    Demonstrable Complex Projects and Assignments.
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12 p-0 corporate_training_2-wrapper">
              <LazyLoadImage
                src={corporate_training2}
                className="corporate_training_2"
                alt="corporate_training2"
              />
              <LazyLoadImage
                src={corporate_training3}
                className="placementImg_3"
                alt="corporate_training3"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
