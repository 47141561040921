import {EDUCATOR_INFO_REQUEST,EDUCATOR_INFO_SUCCESS,EDUCATOR_INFO_FAIL} from "../constant/educatorInfo"

export const educatorInfoReducer=(state={eduInfo:{}},{type,payload})=>{
    switch (type) {
        case EDUCATOR_INFO_REQUEST:
            return {...state,isLoading:true}
        case EDUCATOR_INFO_SUCCESS:
            return {isLoading:false,eduInfo:payload}
        case EDUCATOR_INFO_FAIL:
            return {isLoading:false,eduInfo:payload}
        default:return state
    }
    }