import React, { useState } from 'react'
import EduAssignmentGradeBatches from './EduAssignmentGradeBatches';
import EduAssignmentStuList from './EduAssignmentStuList'
import StuAssignmentOverview from './StuAssignmentOverview';

export default function EduAssignmentList({OverviewComp}) {
    const [comp, setcomp] = useState("");
  const handleAssignment = (para) => {
    setcomp(para);
  };
  // back btn logic
  const [back, setBack] = useState("")
    const handleBack=(para)=>{
      setcomp("ass")
      setBack(para)
    }
  return (
    <div>
    {comp === "ass" ? 
    <div>
      {
      back==="EduAssignmentGradeBatches"
      ?<EduAssignmentGradeBatches/>
      :<div>
      { OverviewComp === "AssGrade"?
        <StuAssignmentOverview />
      : <EduAssignmentStuList />
      }
    </div>
    }
    </div>
    
  : <div>
    <h1 className="backbtn2">
      <i className="backbtn bi bi-arrow-left-circle-fill" onClick={()=>handleBack("EduAssignmentGradeBatches")}></i>
    </h1>
<h2>Batch-1</h2>
<div className="table-responsive">
<table className="table table-success table-striped table-bordered">
   <thead>
    <tr className='table-primary'>
        <th className="tableSrNo">Sr.No.</th>
        <th>Assignments</th>
    </tr>
   </thead>
   <tbody>
   <tr onClick={()=>handleAssignment("ass")}>
       <td>1.</td>
       <td>Ass-1</td>
   </tr>
   <tr onClick={()=>handleAssignment("ass")}>
       <td>2.</td>
       <td>Ass-2</td>
   </tr>
   </tbody>
</table>
</div>
</div>
}
</div>
  )
}
