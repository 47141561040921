import React, { useState } from "react";
import stuCourse from "../../images/stuCourses.png";
import myCoursesCalendar from "../../images/myCoursesCalendar.png";
import AssignmentList from "./AssignmentList";
import UploadAssignment from "./UploadAssignment";
import UploadAssnmtAnsKey from "./UploadAssnmtAnsKey";
import EduAssignmentCourses from "./EduAssignmentCourses";

export default function EduAssignment() {
  const [comp, setcomp] = useState("");
  const components = [
    {
      name: "UploadAssignment",
      compnt: <UploadAssignment />,
    },
    {
      name: "UploadAssnmtAnsKey",
      compnt: <UploadAssnmtAnsKey />,
    },
    {
      name: "EduAssignmentCourses",
      compnt: <EduAssignmentCourses/>,
    },
  ];
  const [DisplayComponent, setDisplayComponent] = useState();
  const handleAttendence = (para) => {
    setDisplayComponent(components.find((items, i) => items.name == para));
    setcomp(para);
    console.log(comp);
    console.log(DisplayComponent);
  };
  return (
    <div className="backbtn2">
      {
       comp === DisplayComponent?.name
       ? DisplayComponent?.compnt
       : (
        <div>
          <div>
            <button
              className="btn btn-info text-white me-2 mt-2"
              onClick={() => handleAttendence("UploadAssignment")}
            >
              Upload Assignment
            </button>
            <button
              className="btn btn-info text-white mt-2 me-2"
              onClick={() => handleAttendence("UploadAssnmtAnsKey")}
            >
              Upload AnsKey
            </button>
            <button
              className="btn btn-info text-white mt-2"
              onClick={() => handleAttendence("EduAssignmentCourses")}
            >
              View Assignments <span className="text-danger ms-2">2</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
