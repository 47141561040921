import {REFRESH_TOKEN_REQUEST,REFRESH_TOKEN_SUCCESS,REFRESH_TOKEN_FAIL} from "../constant/refreshTokenConst"
import axios from "axios"

export const refreshTokenAction=()=>async(dispatch)=>{
    try {
        let userData =JSON.parse(localStorage.getItem("UserData"));
        let Data = {
        username:userData.username,
        refresh: userData.refresh,
        }  
    dispatch({type:REFRESH_TOKEN_REQUEST})
    const {data}= await axios.post("/users/token/refresh/",Data)
    let userTempData = userData;
        userTempData.access =data.data.data.result.access;        
        localStorage.setItem("UserData", JSON.stringify(userTempData));
    dispatch({type:REFRESH_TOKEN_SUCCESS,payload:true})
    } catch (error) {
        localStorage.removeItem("UserData");
        dispatch({type:REFRESH_TOKEN_FAIL, payload:false})
    }
}