import React, { useState } from 'react'
import SettingComp1 from './SettingComp1'
import SettingComp2 from './SettingComp2'

export default function StuDashboardSetting() {

  return (
    <div>
     <SettingComp1/>
     {/* <SettingComp2/> */}
    </div>
  )
}
