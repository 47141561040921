import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import succesfullPgae from "../images/succesfullLogo.png"
import "../style/approvalStatus.css"
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function ProfileApprovel() {
  return (
    <div>
    <Navbar/>
       <div className="container-fluid">
        <div className="row">
        <div className="col-2 sidebar-col text-light pb-0">
        <div className="d-flex flex-column align-items-end vh-100">
        <Link to="/dashBoardHome"><span className="mt-3 sidebar-item">Dashboard</span></Link>
              <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item">Edit Profile</span></Link>
              <Link to="/addSessions"><span className="mt-3 sidebar-item">Upload Courses</span></Link>
              <Link to="/myCourses"><span className="mt-3 sidebar-item">My Courses</span></Link>
              <Link to="/mySessions"><span className="mt-3 sidebar-item">My Sessions</span></Link>
        </div>
        <div className="footer-terms d-flex flex-column align-items-end">
          <span>
            <Link>Terms,</Link>
            <Link>Privacy Policy</Link>
          </span>
          <span>CoachX Rights Reserved</span>
        </div>
      </div>
      <div className="col-md-8 col-12 rightside-col">

           {/* /////////////////offcanvas///////////////// */}
      <button className="btn btn-primary sidebar-btn" type="button" data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasWithBothOptions"
      aria-controls="offcanvasWithBothOptions">sidebar</button>

      <div className="offcanvas offcanvas-start sidebar" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
        <div className="offcanvas-header">
          <button type="button" className="btn-close ms-auto text-light" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body ">
        <div className="d-flex flex-column align-items-end vh-100">
        <Link to="/dashBoardHome"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Dashboard</span></Link>
              <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Edit Profile</span></Link>
              <Link to="/addSessions" ><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Upload Courses</span></Link>
              <Link to="/myCourses"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">My Courses</span></Link>
              <Link to="/"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">My Sessions</span></Link>
        </div>
        </div>
      </div>

        <div className='row'>
        <div className='col-12 d-flex justify-content-center'>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
            <div className='statusBar activeStatus'></div>
        </div>
        </div>
        <div className='d-flex justify-content-center mt-5'>
            <LazyLoadImage src={succesfullPgae} alt="sp" />
        </div>       
        <div className='d-flex justify-content-center mt-5'>
            <p className='successfullpara'>You Have Successfully Uploaded Your course!</p>
        </div>       
        <div className='d-flex justify-content-center mt-5'>
            <p className='successfullpara'>Dear Jay, thank you for joining with us.Your account is under verification, we are verifying your information, will let you know on your registered mobile number</p>
        </div>

        <div className='approvalStatus'>
            <span>Verification Status: <i class="bi bi-arrow-clockwise"></i> Pending</span>
        </div>       
        </div>
        </div>
       </div>
</div>
  )
}
