import React, { useEffect, useState } from 'react'
import BatchInfo from './BatchInfo'
import ManageBatch from './ManageBatch'
import SessionsRecording from './SessionsRecornig'

export default function BatchRecordig({batchInfo}) {
  const [comp, setcomp] = useState("")
  const handleSessions=(para)=>{
    setcomp(para)
  }
  const [manageBatch, setManageBatch] = useState("")
  useEffect(() => {
    setManageBatch(batchInfo)
    console.log(manageBatch);
  }, [])

  // back btn logic
  const [back, setBack] = useState("");
  const handleBack = (para) => {
    setcomp("session")
    setBack(para);
  };
  
  return (
    <div>
      {
        comp ==="session"
        ? <div>
          {
               back === "ManageBatch"
               ?<ManageBatch/>
               :<div>
               {
                 manageBatch === "batchInfo"
                 ?<BatchInfo/>
                 :<SessionsRecording/>
               }
               </div>
          }
        </div>
        :   <div>
           <h1 className="backbtn2">
            <i
              className="backbtn bi bi-arrow-left-circle-fill"
              onClick={() => handleBack("ManageBatch")}
            ></i>
        </h1>
          <h2>Batches</h2>
           <div className="table-responsive mt-2">
        <table className="table table-success table-striped table-bordered">
          <thead>
            <tr className='table-primary'>
                <th className='tableSrNo'>Sr.No.</th>
                <th>Batch Name</th>
            </tr>
          </thead>
          <tbody>
          <tr onClick={()=>handleSessions("session")}>
              <td>1.</td>
              <td>Batch-1</td>
          </tr>
          <tr onClick={()=>handleSessions("session")}>
              <td>2.</td>
              <td>Batch-2</td>
          </tr>
          </tbody>
      </table>
        </div>
        </div>
}
    </div>
  )
}
