import React from "react";
import "../services/services.css";

function Services(props) {
  return (
    <div className="services">
      <div className="container">
        <div className="d-flex services-wrap">
            {props.data.map((items) => (
              <div className="flex-items col-md-4">
                <div className="service-items">
                  <img src={items.image} width={50} />
                  <h4>{items.heading}</h4>
                  <p>{items.para}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Services;
