import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import "../style/mySessions.css";
import MyCourseCard from "./MyCourseCard";
import myCourses from "../images/myCoursesImg.png";
import timer from "../images/myCoursesTimer.png";
import whatsapp from "../images/whatsapp.png";
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function MySessions() {
  const [SelectedDate, setSelectedDate] = useState();
  
  return (
    <div>
      <Navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-2 sidebar-col text-light pb-0">
            <div className="d-flex flex-column align-items-end vh-100">
              <Link to="/dashBoardHome">
                <span className="mt-3 sidebar-item">Dashboard</span>
              </Link>
              <Link to="/educatorDashboardEditProfile">
                <span className="mt-3 sidebar-item">Edit Profile</span>
              </Link>
              <Link to="/addSessions">
                <span className="mt-3 sidebar-item">Upload Courses</span>
              </Link>
              <Link to="/myCourses">
                <span className="mt-3 sidebar-item">My Courses</span>
              </Link>
              <Link to="/mySessions">
                <span className="mt-3 sidebar-item">My Sessions</span>
              </Link>
            </div>
            <div className="footer-terms d-flex flex-column align-items-end">
              <span>
                <Link>Terms,</Link>
                <Link>Privacy Policy</Link>
              </span>
              <span>CoachX Rights Reserved</span>
            </div>
          </div>
          <div className="col-8 rightside-col ">
            {/* /////////////////////date tabs/////////////////// */}

            <div className="row tabs-wrapper">
              <div className="col-md-12 col-sm-6 tabs-inner-wrapper">
                <Box sx={{ color: "#FDFDFD", width: "100%" }}>
                  <Tabs
                    value={"tab-" + 1}
                    sx={{ color: "#063343", marginLeft: "auto"}}
                    // onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    className="tabs"
                  >
                    {[...Array(31).keys()].map((tab, index) => (
                      <Tab
                        onClick={() => SelectedDate(tab)}
                        key={"tab-" + index}
                        className="dateTab"
                        sx={{
                          color: "#BABABA",
                          background: "#FDFDFD",
                          width: "10px",
                          height: "90px",
                          borderRadius: "50%",
                          padding:"5px"
                        }} 
                        label={tab + 1}
                      />
                    ))}
                  </Tabs>
                </Box>
              </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-4 d-flex flex-column justify-content-center text-center">
                    <div>
                    <span>Do join the session today at below timings by clicking the Join Now button. This button will be enabled 10 mins before session time. You being the trainer you are the host, students wont be able to join unless you join.</span>
                    <p>Happy Teaching!</p>
                <button className="btn btn-outline-primary mt-2">
                  <LazyLoadImage src={timer} alt="timer"/> 4pm-5pm
                </button>
                </div>
                </div>
              <div className="col-md-6">
              <div className="card">
            <LazyLoadImage
              src={myCourses}
              className="card-img-top"
              height="300"
              alt="myCourses"
            />
            <div className="card-body myCoursesCardBody">
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="myCoursesCard-title">course name</h5>
                </div>
                <div className="d-flex">
                <button className="btn coursebtn techbtn">Technology</button>
                <button className="btn coursebtn sessionbtn ms-3">Session-1</button>
                </div>
              <div className="d-flex flex-column mt-2">
                <a href="#" className="btn myCoursesBtn mt-2">
                  Join Now
                </a>
              </div>
            </div>
          </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
