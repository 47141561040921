import React from "react";
import footer_logo from "../images/footer_logo.png";
import "../style/coupenCodeNavbar.css";
import { Link } from "react-router-dom";
import coupen1 from "../images/coupen-1.png";
import coupen2 from "../images/coupen-2.png";
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function CoupenCodeNavbar() {
  return (
    <div>
      <div className="navbar-wrapper">
        <nav
          className="navbar navbar-expand-lg d-flex text-dark
             navbar-dark"
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              <LazyLoadImage src={footer_logo} className="nav_logo" alt="footer_logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll">
              <div
                className="collapse navbar navbar-collapse"
                id="navbarScroll"
              >
                <ul className="navbar-nav ms-auto d-flex align-items-center justify-content-center text-align-center ">
                    <li
                      className="nav-item me-5 my-1 text-align-center"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      <LazyLoadImage src={coupen1} className="mb-2 me-1" width="35" alt="coupen1"/>
                      <span className="navText">wecare@coachx.live</span>
                    </li>
                    <li
                      className="nav-item ms-5 my-1"
                      data-bs-toggle="collapse"
                      data-bs-target=".navbar-collapse.show"
                    >
                      <LazyLoadImage src={coupen2} width="20" alt="coupen2" /> <span className="navText">+91 7378757330</span>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}
