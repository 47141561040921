import axios from "axios";
import {
  VIDEO_SLIDER_REQUEST,
  VIDEO_SLIDER_SUCCESS,
  VIDEO_SLIDER_FAIL,
} from "../constant/videoSliderConstant";
export const videoSliderAction = () => async (dispatch) => {
  try {
    dispatch({ type: VIDEO_SLIDER_REQUEST });
    const { data } = await axios.get(`/app/web/home/`);
    console.log(data);
    dispatch({ type: VIDEO_SLIDER_SUCCESS, payload: data.data.media });
  } catch (error) {
    dispatch({ type: VIDEO_SLIDER_FAIL, payload: error });
  }
};
