import axios from "axios"
import {SIGNUP_REQUEST,SIGNUP_SUCCESS,SIGNUP_FAIL} from "../constant/loginConstant"
export const createSignUpAction=(signUpCredentials)=>async(dispatch)=>{
    console.log(signUpCredentials);
    try {
        dispatch({type:SIGNUP_REQUEST})
        axios.defaults.headers.common = {
            'Authorization': '',
            'DEVICE-UDID': ''
          }
    const {data}=await axios.post("users/signup_otp/",signUpCredentials)    
    console.log(data); 
    dispatch({type:SIGNUP_SUCCESS,payload:data})
    } catch (error) {
        dispatch({type:SIGNUP_FAIL, payload:error.response.data})
    }
}