import React from "react";
import clientData from "../Clients/clients.json";
import Clients from "./Clients";

function ClientsList() {
  return (
    <div>
      <div className="container">
        <div className="para">
          <h4>How our trainings helped 1500+ students</h4>
        </div>
        <Clients data={clientData} />
      </div>
    </div>
  );
}

export default ClientsList;
