import React, { useState } from 'react'
import notes from "../../images/notes.png";
import video from "../../images/video.png";
import rightIcon from "../../images/rightIcon.png";
import filter from "../../images/filter.png";
import calender from "../../images/myCoursesCalendar.png";
import EducatorDetails from './EducatorDetails';
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";


export default function AdminEducator() {
  const [comp, setcomp] = useState("")
  const handleAttendence=(para)=>{
    setcomp(para)
  }
  return (
    <div>
      {
    comp ==="educatorDetails"
    ?<EducatorDetails/>
    : <div>
      <h2 className='mb-5'>Educator</h2>
        <div className="admin-status-wrapper">
            <div className="status-box statusVerified">
                <LazyLoadImage src={notes} alt="notes" width={25} />
                <div className='d-flex flex-column'>
                    <span className='adminStatusCount'>65</span>
                    <span>Verified</span>
                </div>
            </div>
            <div className="status-box statusRejected">
                <LazyLoadImage src={notes} alt="notes" width={25} />
                <div className='d-flex flex-column'>
                    <span className='adminStatusCount'>65</span>
                    <span>Rejected</span>
                </div>
            </div>
            <div className="status-box statusPending">
                <LazyLoadImage src={notes} alt="notes" width={25} />
                <div className='d-flex flex-column'>
                    <span className='adminStatusCount'>65</span>
                    <span>Pending</span>
                </div>
            </div>
        </div>

        <div className="w-100 my-5 d-flex justify-content-end">
          <div className='filters me-4'>
            <span className='me-4'>This Months</span> <LazyLoadImage src={calender} alt="calender" />
          </div>
          <div class="dropdown">
            <div className='filters dropdown-toggle' id="dropdownMenuLink" data-bs-toggle="dropdown">
              <span className='me-4'>All</span> <LazyLoadImage src={filter} alt="filter" />
            </div>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li>Rejectd</li>
              <li>Verified</li>
              <li>Pending</li>
            </ul>
          </div>
        </div>
        <div className='table-responsive'>
        <table className='table table-light table-hover'>
          <tbody>
            <tr onClick={()=>handleAttendence("educatorDetails")}>
              <td>1.</td>
              <td><LazyLoadImage src={video} alt="video" width={50} className="adminPanelUser"/></td>
              <td><span>Rishi k.</span></td>
              <td><span>+918888888888</span></td>
              <td><span>Verified</span></td>
              <td><LazyLoadImage src="" alt="" /> <LazyLoadImage src="" alt="" /></td>
            </tr>
            <tr onClick={()=>handleAttendence("educatorDetails")}>
              <td>2.</td>
              <td><LazyLoadImage src={video} alt="video" width={50} className="adminPanelUser"/></td>
              <td><span>Rishi k.</span></td>
              <td><span>+918888888888</span></td>
              <td><span>Pending</span></td>
              <td><LazyLoadImage src={rightIcon} alt="rightIcon" /> <LazyLoadImage src="" alt="" /></td>
            </tr>
            </tbody>
        </table>
        </div>
        </div>
}
    </div>
  )
}
