import React, { useState } from 'react'
import SettingComp2 from './SettingComp2'

export default function SettingComp1() {
    const [comp, setcomp] = useState("")
    const handleAttendence=(para)=>{
      setcomp(para)
    }
  return (
        <div>
            {
        comp ==="settingComp2"
        ?<SettingComp2/>
        :   <div>
      <div className="row">
        <div className="col-6 offset-3 d-flex">
          <div className="stuStatus1 me-1"></div>
          <div className="stuStatus2"></div>
        </div>        
      </div>
       <h4 className="backbtn2">Settings</h4>
      <br />
        <form action="">
      <div className='row'>
        <div className="col-md-6 col-12">
        <div className='mb-2'>
              <label htmlFor="">Name</label>
              <input type="text" placeholder="Enter Course Name" className='form-control' />
        </div>
          <div className='mb-2'>
              <label htmlFor="">Education</label>
              <input type="text" placeholder="Enter Education" className='form-control '/>
          </div>
          <div className='mb-2'>
              <label htmlFor="">Email</label>
              <input type="text" placeholder="Enter Email" className='form-control '/>
          </div>
        </div>
        <div className="col-md-6 col-12">
        <div className='mb-2'>
              <label htmlFor="">Year Of Graduation</label>
              <input type="date" placeholder="Enter Date of birth" className='form-control '/>
        </div>
          <div className='mb-2'>
              <label htmlFor="">Designation</label>
              <input type="text" placeholder="Enter Designation" className='form-control '/>
          </div>
          <div className='mb-2'>
              <label htmlFor="">Current Industry</label>
              <input type="text" placeholder="Enter Industry Name" className='form-control '/>
          </div>
        </div>
        <div className="col-12 col-md-6 offset-md-3 my-3">
          <button className='btn btn-success w-100' onClick={()=>handleAttendence("settingComp2")}>Next</button>
        </div>
      </div>
        </form>
        </div>
        }
        </div>
  )
}
