import {PERSONAL_INFO_REQUEST,PERSONAL_INFO_SUCCESS,PERSONAL_INFO_FAIL} from "../constant/personalInfoConstant"

export const personalInfoReducer=(state={ProfileInfo:{}},{type,payload})=>{
    switch (type) {
        case PERSONAL_INFO_REQUEST:
            return {...state,isLoading:true}
        case PERSONAL_INFO_SUCCESS:
            return {isLoading:false,ProfileInfo:payload}
        case PERSONAL_INFO_FAIL:
            return {isLoading:false,ProfileInfoerror:payload}
        default:return state
    }
    }