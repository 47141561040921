import React from "react";
import "../Home/homeCorp.css";
import Banner from "./Banner/Banner";
import ClientsList from "./Clients/ClientsList";
import ServicesList from "./services/servicesList";
import Footer from "../../../../components/Footer";

function HomeCorporate() {
  return (
    <div>
      <Banner />
      <ClientsList />
      <ServicesList />
      <Footer />
    </div>
  );
}

export default HomeCorporate;
