import React from "react";
import "../../components/Ebooks/ebooks.css";
import jsonData from "../../components/Ebooks/components/data/ebdata.json";
import EbookLayout from "./components/EbookLayout";

function Ebooks() {
  return (
    <div className="ebooks">
      <div className="container">
        <h2>Our Ebooks</h2>
        <p>
          Our E-books have helped thousands of learners as they are portable and accessible
          everywhere.
        </p>
      </div>
      <EbookLayout data={jsonData} />
    </div>
  );
}

export default Ebooks;
