import axios from "axios"
import {ALL_DATA_REQUEST,ALL_DATA_SUCCESS,ALL_DATA_FAIL,COURSE_DETAILS_REQUEST,COURSE_DETAILS_SUCCESS,COURSE_DETAILS_FAIL} from "../constant/allCourses"

export const allCoursesAction=(query)=>async(dispatch)=>{
    try {
        dispatch({type:ALL_DATA_REQUEST})
        // const {data}=await axios.get("/course/filter/?page_size=9&"+query) 
        const {data}=await axios.get("/course/course/list?data_from_old_db=False&"+query) 
        console.log(data.data.course_list.results);
    dispatch({type:ALL_DATA_SUCCESS,payload:data.data.course_list.results})
    } catch (error) {
        dispatch({type:ALL_DATA_FAIL, payload:error})
    }
}
