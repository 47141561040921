import React from "react";
import "../components/ebookLayout.css";
import Footer from "../../Footer";

function EbookLayout(props) {
  return (
    <div className="ebooklayout">
      <div className="layout">
        {props.data.map((items, i) => (
          <div className="container">
            <div className="columns" key={i}>
              <span>
                <img className="ebook-img" src={items.image} alt="img" />
              </span>
              <span>
                <h3 className="title-text">{items.title}</h3>
              </span>
              <span>
                <p>{items.subtitle}</p>
              </span>
            </div>
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
}

export default EbookLayout;
