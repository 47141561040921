import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithCustomToken } from "firebase/auth";

var storageBucket = "";
if (process.env.REACT_APP_ENV_key == "prod") {
  storageBucket = "coachx";
}
if (process.env.REACT_APP_ENV_key == "stage") {
  storageBucket = "coachx-stagging";
}
if (process.env.REACT_APP_ENV_key == "dev") {
  storageBucket = "coachx-dev";
}
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBlS7XaDYDVS0yKHmlRh9Ax2TcPx84GpLE",
  authDomain: "coachx-ae011.firebaseapp.com",
  projectId: "coachx-ae011",
  storageBucket: storageBucket,
  messagingSenderId: "626779934380",
  appId: "1:626779934380:web:21b3ceda14277849a55909",
  measurementId: "G-EMTCLJNM3E",
};
// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const auth = getAuth();
let tempUserData = JSON.parse(localStorage.getItem("UserData"));
signInWithCustomToken(auth, tempUserData?.firebase_token)
  .then((userCredential) => {
    // Signed in
    const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
  });

const analytics = getAnalytics(firebase);
// firebase.analytics();
const storage = getStorage(firebase);

export { storage, firebase as default };
