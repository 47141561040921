import React, { useState } from 'react'
import BatchRecordig from './BatchRecordig';

export default function SessionsRecording() {
    const [sessions, setSessions] = useState(0)
const handleCreateSessions=()=>{
    setSessions((prev=>prev+1))
}
// back btn logic
const [back, setBack] = useState("");
const handleBack = (para) => {
  setBack(para);
};
  return (
    <div>
      {
         back === "ManageBatch"
         ?<BatchRecordig/>
         :<div>
        <h1 className="backbtn2">
            <i
              className="backbtn bi bi-arrow-left-circle-fill"
              onClick={() => handleBack("ManageBatch")}
            ></i>
        </h1>
          <h2>Sessions Recordings</h2>
          <button className='btn btn-success ms-auto' onClick={handleCreateSessions}>Create New Session</button>
           <div className="table-responsive mt-5">
        <table className="table table-success table-striped table-bordered">
          <thead>
          <tr className='table-primary'>
              <th className='tableSrNo'>Sr.No.</th>
              <th>Sessions Name</th>
              <th>Actions</th>
          </tr>
          </thead>
          <tbody>
            {[...Array(sessions)].map((item,i)=>(
          <tr>
              <td>{i+1}.</td>
              <td>Session-{i+1}</td>
              <td><button className='btn btn-success me-2'>Upload Recording</button> <button className='btn btn-danger me-2'>Play</button> <button className='btn btn-danger me-2'>Edit</button><button className='btn btn-danger me-2'>Delete</button></td>
          </tr>
            ))}
          </tbody>
      </table>      
        </div>
        </div>
      }
    </div>
  )
}
