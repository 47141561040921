import {UPCOMING_COURSE_REQUEST,UPCOMING_COURSE_SUCCESS,UPCOMING_COURSE_FAIL} from "../constant/allCourses"

export const upcomingCoursesReducer=(state={upcomingCourses:[]},{type,payload})=>{
    switch (type) {
        case UPCOMING_COURSE_REQUEST:
            return {...state,isLoading:true}
        case UPCOMING_COURSE_SUCCESS:
            return {isLoading:false,upcomingCourses:payload}
        case UPCOMING_COURSE_FAIL:
            return {isLoading:false,upcomingCourseserror:payload}
        default:return state
    }
    }