export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const VERIFYOTP_REQUEST = "VERIFYOTP_REQUEST";
export const VERIFYOTP_SUCCESS = "VERIFYOTP_SUCCESS";
export const VERIFYOTP_FAIL = "VERIFYOTP_FAIL";

export const RESEND_REQUEST = "RESEND_REQUEST";
export const RESEND_SUCCESS = "RESEND_SUCCESS";
export const RESEND_FAIL = "RESEND_FAIL";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

export const SIGNUP_VERIFY_REQUEST = "SIGNUP_VERIFY_REQUEST";
export const SIGNUP_VERIFY_SUCCESS = "SIGNUP_VERIFY_SUCCESS";
export const SIGNUP_VERIFY_FAIL = "SIGNUP_VERIFY_FAIL";

