import {EDUCATOR_PROFILE_REQUEST,EDUCATOR_PROFILE_SUCCESS,EDUCATOR_PROFILE_FAIL} from "../constant/eduProConstant"
import axios from "axios"
import { refreshTokenAction } from "./refreshTokenAction";

export const eduProfileAction=(Info)=>async(dispatch)=>{
    var tokenDetails  = JSON.parse(localStorage.getItem("UserData")); 
    let deviceUDID = localStorage.getItem("DEVICE-UDID"); 
    try {
        dispatch({type:EDUCATOR_PROFILE_REQUEST})
        axios.defaults.headers.common = {
            'Authorization': `Bearer ${tokenDetails.access}`,
            'DEVICE-UDID': deviceUDID
          }
    const {data}=await axios.post("course/edu/profile/",Info)    
    console.log(data);
    dispatch({type:EDUCATOR_PROFILE_SUCCESS,payload:data})
    } catch (error) {
        if(error?.response?.status == 401){
            dispatch(refreshTokenAction())            
        }
        dispatch({type:EDUCATOR_PROFILE_FAIL, payload:error})
    }
}