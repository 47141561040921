import React, { useEffect, useState } from "react";

import TinyFlag from "tiny-flag-react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function FlagSelect(props) {
    const [country, setCountry] = useState("IN");    
    const [flagImg, setflagImg] = React.useState();

    const handleChange = (event) => {
        setCountry(event.target.value.flag);
        props.setCountryCode(event.target.value.code);
    };
    

    let countryName = "INDIA";
    let url =`https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/${country}.svg`
    
    useEffect(() => {
      props.setflagImg(url)
    }, [url])
  return (
    <div className="d-flex align-items-center">
        <TinyFlag
  country={country}
  countryName={countryName}
  fallbackImageURL={url}
    />
        <FormControl variant="standard">
          <Select
          onChange={handleChange}
          sx={{width:"25px",fontSize:0, backgroundColor:"#fff"}}
          className="country-select"
          disableUnderline
        >
          <MenuItem value={{flag:"IN", code:"+91"}}>IN</MenuItem>
          <MenuItem value={{flag:"US", code:"+1"}}>US</MenuItem>
          <MenuItem value={{flag:"SA", code:"+2"}}>SA</MenuItem>
        </Select>
              </FormControl>
    </div>
  );
}
