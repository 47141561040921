import React from "react";
import footer_logo from "../images/footer_logo.png";
import fb from "../images/fb.png";
import insta from "../images/insta.png";
import linkedin from "../images/linkedin.png";
import youtube from "../images/youtube.png";
import "../style/footer.css";
import { Link } from "react-router-dom";
// lazy loading
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Footer() {
  return (
    <div className="footer-wrapper">
      <div className="container">
        <div className="row py-5">
          <div className="col-md-3">
            <a href="#">
              <LazyLoadImage src={footer_logo} className="footer_logo" alt="Logo image" />
            </a>
            <p className="poweredBy">Powered by Classboat Online Services Private Limited </p>
            <p>
              Your online learning partner!
              <br />
              Learn a variety of courses to gain expertise in your preferred field.
            </p>
          </div>
          <div className="col-md-3">
            <ul>
              <h3>Courses in Demand</h3>
              <li>
                <Link to="allCourses">Learn Python From Scratch</Link>
              </li>
              <li>
                <Link to="allCourses">Learn SQL For Beginners</Link>
              </li>
              <li>
                <Link to="allCourses">Graphic Desgining </Link>
              </li>
              <li>
                <Link to="allCourses">UI-UX Program</Link>
              </li>
              <li>
                <Link to="allCourses">Performance Marketing Course</Link>
              </li>
              <li>
                <Link to="allCourses">Web Developent Course</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <ul>
              <h3>Terms</h3>
              <li>
                <Link to="/termsAndConditions">Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/privacyPolicy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/newPlacement">Our Placed Students</Link>
              </li>
              {/* <li>
                <Link to="/corporatetraining">For Corporates</Link>
              </li> */}
              {/* Added the students reviews tab */}
              <li>
                {/* <Link to="/reviews">What Our Students Say?</Link> */}
              </li>
              {/* Reviews tab ends here */}
              <li
                className="becomeEducatorFooter nav-item me-4 mt-2"
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                <Link to="/becomeEducator">
                  <h3>Become an Educator</h3>
                </Link>
              </li>
              {/* <Link to="/testimonialPage"><h3>Testimonials</h3></Link> */}
            </ul>

            <div className="mt-5">
              <ul>
                <h3>Services</h3>
                {/* <li><Link to="/corporateTrainings">Corporate Training</Link></li> */}
                <li>
                  <Link to="/placement">Placements</Link>
                </li>
                <li>
                  <Link to="/becomeEducator">Become an Educator</Link>
                </li>
                <li>
                  <Link to="/aboutUs">About Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <ul>
              <h3>Contact</h3>
              <li>
                C-409, 4th Floor, Teerth Technospace
                Baner  <br />Pune-411045
              </li>
              <li>+91 7378757330</li>
              <li>
                <span className="social-logo pe-4">
                  <a
                    href="https://www.facebook.com/CoachX.Live.Official/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LazyLoadImage src={fb} alt="fb image" />
                  </a>
                </span>
                <span className="social-logo pe-4">
                  <a
                    href="https://www.instagram.com/Coachxliveofficial/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LazyLoadImage src={insta} alt="insta image" />
                  </a>
                </span>
                <span className="social-logo pe-4">
                  <a
                    href="https://www.linkedin.com/company/coachx-live/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LazyLoadImage src={linkedin} alt="linkedin image" />
                  </a>
                </span>
                <span className="social-logo pe-4">
                  <a
                    href="https://www.youtube.com/channel/UCVoBCNl9MiVOChnoxQ1GGVg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LazyLoadImage src={youtube} alt="youtube image" />
                  </a>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
