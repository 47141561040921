import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from './Navbar'
import "../style/courseFee.css"
import EduNavigationButtons from './EduNavigationButtons'

export default function CourseFee({location}) {
  const [course, setcourse] = useState({})
useEffect(() => {
  const localData= localStorage.getItem("eduData")
  setcourse(JSON.parse(localData))
  localStorage.removeItem("eduData")
}, [])
  console.log(course);
  const redirectToAddSessions=()=>{
    window.location.href = window.location.origin + "/addSessions";
  }
  const redirectToBack=()=>{
    window.location.href = window.location.origin + "/additionalReqi";
  }
  return (
    <div>
        <Navbar/>
           <div className="container-fluid">
            <div className="row">
            <div className="col-2 sidebar-col text-light pb-0">
            <div className="d-flex flex-column align-items-end vh-100">
            <Link to="/dashBoardHome"><span className="mt-3 sidebar-item">Dashboard</span></Link>
              <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item">Edit Profile</span></Link>
              <Link to="/addSessions"><span className="mt-3 sidebar-item">Upload Courses</span></Link>
              <Link to="/myCourses"><span className="mt-3 sidebar-item">My Courses</span></Link>
              <Link to="/"><span className="mt-3 sidebar-item">My Sessions</span></Link>
                         </div>
            <div className="footer-terms d-flex flex-column align-items-end">
              <span>
                <Link>Terms,</Link>
                <Link>Privacy Policy</Link>
              </span>
              <span>CoachX Rights Reserved</span>
            </div>
          </div>
          <div className="col-md-8 col-12 rightside-col ">

 {/* /////////////////offcanvas///////////////// */}
 <button className="btn btn-primary sidebar-btn" type="button" data-bs-toggle="offcanvas"
      data-bs-target="#offcanvasWithBothOptions" aria-controls="offcanvasWithBothOptions">sidebar</button>

      <div className="offcanvas offcanvas-start sidebar" data-bs-scroll="true" tabindex="-1" id="offcanvasWithBothOptions" aria-labelledby="offcanvasWithBothOptionsLabel">
        <div className="offcanvas-header">
          <button type="button" className="btn-close ms-auto text-light" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body ">
        <div className="d-flex flex-column align-items-end vh-100">
        <Link to="/dashBoardHome"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Dashboard</span></Link>
              <Link to="/educatorDashboardEditProfile"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Edit Profile</span></Link>
              <Link to="/addSessions" ><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">Upload Courses</span></Link>
              <Link to="/myCourses"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">My Courses</span></Link>
              <Link to="/mySessions"><span className="mt-3 sidebar-item" data-bs-dismiss="offcanvas">My Sessions</span></Link>
        </div>
        </div>
      </div>
            <div className='row'>
            <div className='col-12 d-flex justify-content-center'>
                <div className='statusBar activeStatus'></div>
                <div className='statusBar activeStatus'></div>
                <div className='statusBar activeStatus'></div>
                <div className='statusBar'></div>
                <div className='statusBar'></div>
            </div>
            </div>
            <h1 className='additionalReqTitle'>Course Fees</h1>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <input type="number" placeholder='Course Fees' className='form-control form-control-lg w-md-75 w-100 mt-3'
                 onChange={(e)=>setcourse({...course,courseFee:e.target.value})}
                />
                <input type="number" placeholder='Course Discounted fees (in %)'className='form-control form-control-lg w-md-75 w-100  mt-3'
                onChange={(e)=>setcourse({...course,courseDiscountedFee:e.target.value})}
                />
            <div className='w-md-75 w-100  mt-3'>
              <input type="checkbox" className='form-check-input me-1' />
              <label htmlFor="" className=''> Click here, if this course is free</label>
            </div>
            <EduNavigationButtons/>
            </div>
            </div>
            </div>

           </div>
    </div>
  )
}
