import axios from "axios"
import {USERSIGNIN_REQUEST,USERSIGNIN_SUCCESS,USERSIGNIN_FAIL} from "../constant/userSignInConst"

export const userSignInAction=(signInCredentials)=>async(dispatch)=>{
    console.log(signInCredentials);
    try {
        dispatch({type:USERSIGNIN_REQUEST})
        axios.defaults.headers.common = {
            'Authorization': '',
            'DEVICE-UDID': ''
          }
    const {data}=await axios.post("/users/signin/",signInCredentials)    
    console.log(data);
    dispatch({type:USERSIGNIN_SUCCESS,payload:data})
    } catch (error) {
        dispatch({type:USERSIGNIN_FAIL, payload:error.response.data})
    }
}