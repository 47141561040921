import React, { useEffect, useState } from "react";
import contactUs from "../images/contactShape.png";
import phone from "../images/phone.png";
import whatsapp from "../images/whatsapp.png";
import reqCallImg from "../images/reqCallImg.png";

import { requestCallAction } from "../Action/requestCallAction";
import { useDispatch } from "react-redux";
import "animate.css";
import "../style/requestCallBack.css";

// react bootstrap
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

// mateial ui
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import { Link } from "react-router-dom";
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import Modal from '@mui/material/Modal';

export default function RequestCallBack({ view }) {
  // const [showRequestPage, setshowRequestPage] = useState(false);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobileNo, setmobileNo] = useState("");
  const [GraduationYear, setGraduationYear] = useState("2023-07-07");
  const [enquiry, setenquiry] = useState("");
  const reqCallBack = [];
  const [show, setShow] = useState(false);

  const handleRequestCallBack = () => {
    // setshowRequestPage(true);
    setShow(true);
  };

  useEffect(() => {
    if (localStorage.getItem("reqCallPopUp")) {
      if (localStorage.getItem("reqCallPopUp").length >= 1) {
        return setShow(false);
      }
    }
    setShow(true);
  }, []);

  useEffect(() => {
    view && setShow(true);
  }, [view]);

  const dispatch = useDispatch(); 
  
  const ListOfCourse=[
    {
      course:"Professional Certificate Program in Data Science",
      ID:7
    },
    {
      course:"Complete Data Analytics Course",
      ID:1
    },
    {
      course:"Professional Certificate Program in Business Analytics",
      ID:8
    },
    {
      course:"AWS Certified Cloud Practitioner - Complete Course",
      ID:9
    },
    {
      course:"Complete Tableau Training Program",
      ID:1
    },
    {
      course:"The Advanced SQL Course",
      ID:1
    },
    {
      course:"Python Django Certification Course",
      ID:1
    },
  ]

  const reqCallBackAction = () => {
    const courseId = ListOfCourse.find((item)=>item.course==enquiry && item.ID)

    dispatch(
      requestCallAction({
        name,
        email,
        mobile: mobileNo,
        query: enquiry,
        year_of_graduation: "2023-07-07",
      })
    );

    axios.post(
      "https://thirdpartyapi.extraaedge.com/api/SaveRequest",
      {
        "AuthToken": "COACHX-06-07-2023",
        "Source": "coachx",
        "FirstName":name,
        "Email":email,
        "MobileNumber":mobileNo,
        "LeadSource":22,
        "LeadCampaign":"Website Leads",
        "Course":courseId.ID,
        "Center":"5",
        "State":"",
        "City":""
      }
    );

    // setshowRequestPage(false);

    setShow(false);
    setname("")
    setemail("")
    setmobileNo("")
    setGraduationYear("")
    setenquiry("")

  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (localStorage.getItem("reqCallPopUp")) {
      if (localStorage.getItem("reqCallPopUp").length >= 1) {
        return reqCallBackAction();
      }
    }
    reqCallBack.push("submited");
    localStorage.setItem("reqCallPopUp", JSON.stringify(reqCallBack));
    reqCallBackAction();
    e.target.reset();
  };

  const handleClose = () => {
    if (localStorage.getItem("reqCallPopUp")) {
      if (localStorage.getItem("reqCallPopUp").length >= 1) {
        return setShow(false);
      }
    }
    reqCallBack.push("denied");
    localStorage.setItem("reqCallPopUp", JSON.stringify(reqCallBack));
    setShow(false);
  };

  return (
    <div className="">

      <div className="dropdown contact-btn">
        <button
          className="btn-dropdown animate__animated animate__pulse animate__infinite	infinite"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          onClick={handleRequestCallBack}
          // onClick={handleShow}
        >
          <span className="contactUs-text">Contact Us</span>
        </button>
      </div>
    <div className="reqCallBackModalOuterDiv">
      <Modal
        show={show}
        // show={true}
        onHide={handleClose}
        animation={false}
        className=""
        dialogClassName="modalWidth"
      >
        <Modal.Body className="modalBody">
          <div className="contactUsCrossbtn" onClick={handleClose}>
            <ClearIcon />
          </div>
          <div className=" w-100 d-flex justify-content-center">
            <div className="reqCallImg-wrapper">
              <img src={reqCallImg} alt="reqCallImg" />
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="row mt-4 d-flex justify-content-center">
              <div className="col-md-5 col-12 d-flex justify-content-between order-2 order-md-2">
                <div className="d-flex justify-content-center align-items-center flex-column my-5">
                  <div className="w-75">
                    {/* <span>Call us on</span> */}
                    <h5 className="mobNo"><i className="bi bi-telephone-fill me-1"></i>+91-7378757330</h5>
                  </div>
                  <p className="text-center">Or</p>
                    <div className="wp-wrapper w-75">
                  <a href="https://wa.me/message/YIUPH6AA6FGUF1" target="_black">
                      <span className="wpImg">
                        <img src={whatsapp} alt="whatsapp" width="13%" />
                      </span>
                      <span className="ms-3">WhatsApp Us</span>
                  </a>
                    </div>
                </div>
              </div>

              <div className="col-1 d-none d-md-block order-md-2">
                <div className="d-flex flex-column justify-content-between align-items-center my-5">
                  <div className="upper"></div>
                  <div>Or</div>
                  <div className="lower"></div>
                </div>
              </div>

              <div className="col-md-5 col-12  order-1 order-md-3 pt-4 pt-sm-0">
                <div className=" ">
                  <h4 className="text-center reqCallBackHeading">
                    Request Call Back
                  </h4>

                  <div className="my-3">
                    <input
                      type="Name"
                      className="form-control"
                      placeholder="Name*"
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                      required
                    />
                  </div>
                  <div className="my-3">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email*"
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="my-3">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Contact No.*"
                      value={mobileNo}
                      onChange={(e) => setmobileNo(e.target.value)}
                      required
                    />
                  </div>
                  {/* <div className="my-3">
                    <input
                      type="date"
                      className="form-control"
                      placeholder="Year of graduation"
                      value={GraduationYear}
                      onChange={(e) => setGraduationYear(e.target.value)}
                      required
                    />
                  </div> */}
                  <div className="my-3">
                      <select
                      className="form-select select_course"
                      name="UserRole"
                      onChange={(e) => setenquiry(e.target.value)}
                      value={enquiry}
                      required
                    >
                      <option value="" selected>Select Course</option>
                      <option value="Professional Certificate Program in Data Science">Professional Certificate Program in Data Science</option>
                      <option value="Complete Data Analytics Course">Complete Data Analytics Course</option>
                      <option value="Professional Certificate Program in Business Analytics">Professional Certificate Program in Business Analytics</option>
                      <option value="AWS Certified Cloud Practitioner - Complete Course">AWS Certified Cloud Practitioner - Complete Course</option>
                      <option value="Professional Certificate Program in Business Analytics">Professional Certificate Program in Business Analytics</option>
                      <option value="Complete Tableau Training Program">Complete Tableau Training Program </option>
                      <option value="The Advanced SQL Course">The Advanced SQL Course</option>
                      <option value="Python Django Certification Course">Python Django Certification Course</option>
                    </select>
                  </div>
                 
                  <div className="d-flex justify-content-center">
                    <Button
                      type="submit"
                      variant=""
                      className="w-75 submitBtn"
                      // onClick={handleClose}
                    >
                      SUBMIT
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/* <div className="row">
            <Link to="/"><p>I hereby agree to the terms & Conditions & Privacy policy of Coachx.Live.</p></Link>
          </div> */}
        </Modal.Body>
      </Modal>
    </div>
    </div>
  );
}
