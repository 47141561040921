import React from 'react'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem',color:'white' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  

export default function NavAllCourses1() {

    // const [expanded, setExpanded] = React.useState('panel1');
    const [expanded, setExpanded] = React.useState(false);
    const { exploreCourses } = useSelector((state) => state.exploreCatagory);
    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    const redirectToCourseDetails = (id, title) => {
        window.open(`/live?${title.replaceAll(" ", "-")}=` + id, "_blank");
    };

  return (
    <div>
          {exploreCourses?.data &&
            exploreCourses?.data.map((tech, i) => (
      <Accordion expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)} className="AllCoursesAccordion">
        <AccordionSummary className="AllCoursesAccordion" 
         aria-controls="panel1d-content" id={`panel1d-header${i}`}
         >
          <Typography> {tech?.category}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <ul className='list-unstyled'>
                        {tech?.courses.map((course, i) => (
                          <li
                            onClick={(event) =>{
                              event.stopPropagation()
                              redirectToCourseDetails(course.id, course.title)
                            }}
                            className='AllcourseTitleList ms-4'
                          >
                            <Typography>
                             <p className='AllCourseTitle'> {course?.title}</p>
                            </Typography>
                            <hr />
                          </li>
                        ))}
                      </ul>
        </AccordionDetails>
      </Accordion>
       ))}
    </div>
  )
}

