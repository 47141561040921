import AllCourses from "./pages/AllCourses";
import Home from "./pages/Home";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import CourseDetail from "./pages/CourseDetail";
import ScrollToTop from "./components/ScrollToTop";
import HomeCard from "./components/home/HomeCard";
import AboutUs from "./pages/AboutUs";
import BecomeEducator from "./pages/BecomeEducator";
import ThankYou from "./pages/ThankYou";
import ThankYouEnrolling from "./pages/ThankYouEnrolling";
import OurTerms from "./pages/OurTerms";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import axios from "axios";
import PaymentFail from "./pages/PaymentFail";
import EducatorDashboardEditProfile from "./pages/EducatorDashboardEditProfile";
import MyDashboard from "./pages/MyDashboard";
import EducatorAboutCourse from "./components/EducatorAboutCourse";
import AdditionalReqi from "./components/AdditionalReqi";
import CourseFee from "./components/CourseFee";
import Batches from "./components/Batches";
import SuccessfullPage from "./pages/SuccessfullPage";
import MyCourses from "./components/MyCourses";
import AddSessions from "./components/AddSessions";
import EditSession from "./components/EditSession";
import ProfileApprovel from "./components/ProfileApprovel";
import DashBoardHome from "./pages/DashBoardHome";
import JoinForm from "./components/JoinForm";
import MyCourseCard from "./components/MyCourseCard";
import MySessions from "./components/MySessions";
import EducatorPage from "./pages/EducatorPage";
import CoupenCodeNavbar from "./components/CoupenCodeNavbar";
import CoupenCodeForm from "./pages/CoupenCodeForm";
import LeadForm from "./pages/LeadForm";
import { useEffect } from "react";
import TestimonialPage from "./pages/TestimonialPage";
import CorporateTrainings from "./pages/CorporateTrainings";
import Placement from "./pages/Placement";
import StudentDashboard from "./pages/StudentDashboard";
import EducatorDashboard from "./pages/EducatorDashboard";
import AdminPanel from "./pages/AdminPanel";
// import BlogPage from "./pages/BlogPage";
import ContactUs from "./components/ContactUs";
import RequestCallBack from "./components/RequestCallBack";
import NewPlacement from "./components/NewPlacement/NewPlacement";
import BottomBar from "./components/bottombar/BottomBar";
import HomeCorporate from "./corporate/Templates/Components/Home/HomeCorporate";
// import Gooreviews from "./components/reviews/Gooreviews";
// import Blog from "./components/blogs/Blog";

//Ebooks
import Ebooks from "./components/Ebooks/Ebooks";

// Landing pages
import Datascience from "./components/landingpages/Datascience";
import Userdetails from "./pages/paymentoptions/paymentDetails/Userdetails";
import Successpage from "./pages/paymentoptions/paymentDetails/Successpage";
import Failedpage from "./pages/paymentoptions/paymentDetails/Failedpage";


//offline payment phonepe
// import Userdetails from "./pages/paymentoptions/paymentDetails/Userdetails";
// import Payment from "./pages/paymentoptions/Payment";

function App(props) {
  let deviceUDID = new Date();
  if (!localStorage.getItem("DEVICE-UDID")) {
    localStorage.setItem("DEVICE-UDID", deviceUDID);
  }
  deviceUDID = localStorage.getItem("DEVICE-UDID");

  if (!localStorage.getItem("SessionToken")) {
    localStorage.setItem("SessionToken", " ");
  }
  //  ================= base url setup ================

  if (process.env.REACT_APP_ENV_key == "prod") {
    // axios.defaults.baseURL = "https://api.coachx.live/";
    axios.defaults.baseURL = "https://pro.coachx.live/";
  }
  if (process.env.REACT_APP_ENV_key == "stage") {
    axios.defaults.baseURL = "https://apistage.coachx.live/";
  }
  if (process.env.REACT_APP_ENV_key == "dev2") {
    axios.defaults.baseURL = "https://dev2.coachx.live";
  }
  if (process.env.REACT_APP_ENV_key == "local") {
    axios.defaults.baseURL = "http://127.0.0.1:8000/";
    // axios.defaults.baseURL = "https://dev0.coachx.live";
  }

  return (
    <BrowserRouter>
      <Navbar />
      <BottomBar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/allCourses" component={AllCourses} />
        <Route path="/courseDetail" component={CourseDetail} exact />
        <Route path="/live" component={CourseDetail} exact />
        <Route path="/aboutUs" component={AboutUs} exact />
        <Route path="/becomeEducator" component={BecomeEducator} exact />
        <Route path="/thankYou" component={ThankYou} exact />
        <Route path="/thankYouForEnrollment" component={ThankYouEnrolling} exact />
        <Route path="/paymentFail" component={PaymentFail} exact />
        <Route path="/termsAndConditions" component={OurTerms} exact />
        <Route path="/privacyPolicy" component={PrivacyPolicy} exact />
        <Route path="/refundPolicy" component={RefundPolicy} exact />
        <Route
          path="/educatorDashboardEditProfile"
          component={EducatorDashboardEditProfile}
          exact
        />
        <Route path="/profileApprovel" component={ProfileApprovel} exact />
        <Route path="/dashBoardHome" component={DashBoardHome} exact />
        <Route path="/educatorAboutCourse" component={EducatorAboutCourse} exact />
        <Route path="/additionalReqi" component={AdditionalReqi} exact />
        <Route path="/CourseFee" component={CourseFee} exact />
        <Route path="/addSessions" component={AddSessions} exact />
        <Route path="/batches" component={Batches} exact />
        <Route path="/successfullPage" component={SuccessfullPage} exact />
        <Route path="/myCourses" component={MyCourses} exact />
        <Route path="/mySessions" component={MySessions} exact />
        <Route path="/educatorPage" component={EducatorPage} exact />
        <Route path="/coupenCodeForm" component={CoupenCodeForm} exact />
        <Route path="/leadForm" component={LeadForm} exact />
        <Route path="/testimonialPage" component={TestimonialPage} exact />
        <Route path="/corporateTrainings" component={CorporateTrainings} exact />
        <Route path="/placement" component={Placement} exact />
        <Route path="/studentDashboard" component={StudentDashboard} exact />
        <Route path="/educatorDashboard" component={EducatorDashboard} exact />
        <Route path="/adminPanel" component={AdminPanel} exact />
        {/* <Route path="/blogs" component={BlogPage} exact /> */}
        <Route path="/contactus" component={ContactUs} exact />
        <Route path="/requestCallBack" component={RequestCallBack} exact />
        <Route path="/newPlacement" component={NewPlacement} exact />
        <Route path="/bottomBar" component={BottomBar} exact />
        {/* <Route path="/blogs" component={Blog} exact />*/}
        <Route path="/datascience" component={Datascience} exact />
        <Route path="/ebooks" component={Ebooks} exact />
        <Route path="/corporatetraining" component={HomeCorporate} exact />
        {/* <Route path="/registrationform" component={Userdetails} exact />
        <Route path="/paymentsuccessful" component={Successpage} exact />
        <Route path="/paymentfailed" component={Failedpage} exact /> */}
        {/* <Route path="/userdetails" component={Userdetails} exact />
        <Route path="/paymentphonepe" component={Payment} exact /> */}
        {/* <Route path="/reviews" component={Gooreviews} exact /> */}
      </Switch>
    </BrowserRouter>
  );
}
export default App;
