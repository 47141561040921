import {PRIVACY_POLICY_REQUEST,PRIVACY_POLICY_SUCCESS,PRIVACY_POLICY_FAIL} from "../constant/privacyPolicyConst"
export const privacyPolicyReducer=(state={privacy:{}},{type,payload})=>{
    switch (type) {
        case PRIVACY_POLICY_REQUEST:
            return {...state,isLoading:true}
        case PRIVACY_POLICY_SUCCESS:
            return {isLoading:false,privacy:payload}
        case PRIVACY_POLICY_FAIL:
            return {isLoading:false,privacyerror:payload}
        default:return state
    }
    }