import React from 'react'
import "../style/thankYouEnrolling.css"
import "../style/paymentFail.css"
import paymentFail from "../images/paymentFail.jpg"
import Footer from '../components/Footer'
import { Link, useLocation } from 'react-router-dom'
// lazy loading 
import { LazyLoadImage } from "react-lazy-load-image-component";
export default function PaymentFail(props) {

  const search = useLocation().search;
    const course_info = new URLSearchParams(search).get('course_info');

    const redirectToCourseDetails=()=>{
        window.location.href = window.location.origin + "/courseDetail?course_info=" + course_info;
    }
  return (
    <div>
        <div className="thankYouEnrolling-wrapper">
           <div className="container">
                    <div className="thankYouEnrolling-middle-wrapper">
            <div className="row">
                        <div className="col-md-6">
                        <LazyLoadImage src={paymentFail} className="thankYouImg" width="100%" height="100%" alt="paymentFail" />
                        </div>
                        <div className="col-md-6 thankYouEnrolling-inner-wrapper">
                                <h3 className='thankYouEnrolling-heading '>Sorry for the inconvinience! <br/> It seems your payment is declined. <br/> Please try again.</h3>
                              
                               <button className='done-btn'  onClick={redirectToCourseDetails}>Try Again</button>     
                             
                        </div>
                    </div> 
            </div>
           </div>
        </div>
        <Footer/>
    </div>
  )
}
