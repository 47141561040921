import {EXPLORE_COURSES_REQUEST,EXPLORE_COURSES_SUCCESS,EXPLORE_COURSES_FAIL} from "../constant/exploreCoursesConst"

export const exploreCoursesReducer=(state={exploreCourses:{}},{type,payload})=>{
    switch (type) {
        case EXPLORE_COURSES_REQUEST:
            return {...state,isLoading:true}
        case EXPLORE_COURSES_SUCCESS:
            console.log(payload);
            return {isLoading:false,exploreCourses:payload}
        case EXPLORE_COURSES_FAIL:
            return {isLoading:false,exploreCourseserror:payload}
        default:return state
    }
    }