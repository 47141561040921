import axios from "axios";
import {
  ALL_DATA_REQUEST,
  ALL_DATA_SUCCESS,
  ALL_DATA_FAIL,
  COURSE_DETAILS_REQUEST,
  COURSE_DETAILS_SUCCESS,
  COURSE_DETAILS_FAIL,
} from "../constant/allCourses";

export const courseDetailsAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: COURSE_DETAILS_REQUEST });
    const { data } = await axios.get(`/course/landing_page?id=${id}`);
    console.log(data.data);
    dispatch({ type: COURSE_DETAILS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: COURSE_DETAILS_FAIL, payload: error });
  }
};
