import {FEEDBACK_REQUEST,FEEDBACK_SUCCESS,FEEDBACK_FAIL} from "../constant/feedbackConst"

export const feedbackReducer=(state={Feedback:[]},{type,payload})=>{
    switch (type) {
        case FEEDBACK_REQUEST:
            return {...state,isLoading:true}
        case FEEDBACK_SUCCESS:
            return {isLoading:false,feedback:payload}
        case FEEDBACK_FAIL:
            return {isLoading:false,feedbackError:payload}
        default:return state
    }
    }