import React from 'react'

export default function SessionAttendence() {
  return (
    <div>
    <h4>Course Name</h4>
    <br />
      <table className="table table-success table-striped table-bordered">
          <thead>
          <tr className='table-primary'>
              <th className='tableSrNo'>Sr.No.</th>
              <th>Session Name</th>
              <th>Attendence</th>
          </tr>
          </thead>
          <tbody>
          <tr>
              <td>1.</td>
              <td>Session-1</td>
              <td><button className='btn btn-sm btn-success'>present</button></td>
          </tr>
          <tr>
              <td>2.</td>
              <td>Session-2</td>
              <td><button className='btn btn-sm btn-danger'>Abscent</button></td>
          </tr>
          </tbody>
      </table>      
  </div>
  )
}
