import React, { useState } from 'react'
import RecordedVideo from './RecordedVideo';

export default function CourseRecording() {
  // back btn logic
  const [back, setBack] = useState("");
  const handleBack = (para) => {
    setBack(para);
  };
  return (
    <div>
      {
          back === "RecordedVideo"
          ?<RecordedVideo/>
          :<div>
            <h1>
              <i
                className="backbtn bi bi-arrow-left-circle-fill"
                onClick={() => handleBack("RecordedVideo")}
              ></i>
            </h1>
          <h4>Course Name</h4>
           <br />
           <div className="table-responsive">
              <table className="table table-success table-striped table-bordered">
                  <thead>
                  <tr className='table-primary'>
                      <th className='tableSrNo'>Sr.No.</th>
                      <th>Sessions</th>
                      <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                      <td>1</td>
                      <td>Sessions-1</td>
                      <td><button className='btn btn-sm btn-success'>Start</button></td>
                  </tr>
                  </tbody>
              </table>
            </div>
          </div>
          }
    </div>
  )
}
