import {GET_REQUEST,GET_SUCCESS,GET_FAIL} from "../constant/getTokenConst"
export const getTokenReducer=(state={token:{}},{type,payload})=>{
    switch (type) {
        case GET_REQUEST:
            return {...state,isLoading:true}
        case GET_SUCCESS:
            return {isLoading:false,token:payload}
        case GET_FAIL:
            return {isLoading:false,tokenError:payload}
        default:return state
    }
    }