import axios from "axios" 
import {PRIVACY_POLICY_REQUEST,PRIVACY_POLICY_SUCCESS,PRIVACY_POLICY_FAIL} from "../constant/privacyPolicyConst"

export const privacyPolicyAction=()=>async(dispatch)=>{
    try {
        dispatch({type:PRIVACY_POLICY_REQUEST})

        const {data}=await axios.get("/app/privacy/")    
    dispatch({type:PRIVACY_POLICY_SUCCESS,payload:data.data.privacy})

    } catch (error) {
        dispatch({type:PRIVACY_POLICY_FAIL, payload:error})
    }
}
